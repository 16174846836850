import styled from '@emotion/styled/macro';
import { useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import BaseTag from '../BaseTag';
import { useJourney, useJourneyTag } from '../hooks';
import Tags from '../Tags';

export default function Journey({ tagApi, journeyKey, onSelect, fromPath, children, ...rest }) {

    const { journey, tags, path, currentTag, setCurrentTag } = useJourney(journeyKey)

    const navigate = useNavigate()

    const handleSelectTag = (key, tag) => {
        setCurrentTag(key)

        let url = './journey/' + journeyKey + '/'

        if (tag.Type === 'Article')
            url += 'display/' + tag.Node + '/' + key
        else
            url += tag.Type + '/' + key

         navigate(url, { data: { tagKey: key } })
    }

    return (
        <BaseTag tagApi={tagApi} name={journey.Name["en"] + ' (~' + journey.Duration + ' minutes)'} locateTagKey={"R3"} fromPathTagKey={"R3"}
            description={journey.Description["en"]} fromPath={fromPath}
            initialTabKey={currentTag?.key ? 'displays' : null}
            tabs={{
                'displays': (
                    <Tags tags={tags} focusedTagKey={currentTag?.key} journeyKey={journeyKey} onSelect={handleSelectTag} />
                )
            }}
        >
            {/* <Tags tags={tags} focusedTagKey={currentTag?.key} journeyKey={journeyKey} onSelect={handleSelectTag} /> */}
        </BaseTag>
    )
}
