import { useNavigate, useSearchParams } from 'react-router-dom';
import BaseAltTag from '../BaseAltTag';
import BaseTag from '../BaseTag';

import { useEffect, useRef } from 'react';
import { useArticles, useCalibers, useDisplays, useIntl, useMap, useRooms, useTagsExtension } from '../hooks';
import Tags from '../Tags';

export default function Display({ floating, journeyKey, childKey, filter, altTag, tagApi, displayKey, onSelect, onSelectAudio, fromPath, ...rest }) {
    const { displays } = useDisplays()
    const { rooms } = useRooms()
    const display = displays?.[displayKey]
    const room = rooms?.[display?.Room]

    return altTag ? <Alt onSelect={onSelect} onSelectAudio={onSelectAudio} filter={filter} displayKey={displayKey} display={display} room={room} /> :
        <Main floating={floating} journeyKey={journeyKey} filter={filter} display={display} room={room} displayKey={displayKey} fromPath={fromPath} tagApi={tagApi} onSelect={onSelect} />
}

function Alt({ display, onSelect, displayKey, onSelectAudio, filter }) {
    const { locale, intl } = useIntl()
    return <BaseAltTag onSelect={onSelect} tagKey={displayKey} tagType='Display' onSelectAudio={onSelectAudio} filter={filter} audioUri={display?.AudioUri?.[locale]} name={display.Name[locale]} />
}

function Main({ floating, journeyKey, filter, displayKey, display, room, tagApi, fromPath, onSelect }) {
    const { locale, intl } = useIntl()
    const { tagsFromKeys } = useTagsExtension()
    const { articles } = useArticles()
    const { calibers } = useCalibers()

    const { map } = useMap()
    const floor = Object.values(map?.Floors ?? {})?.[0]
    const [searchParams] = useSearchParams()
    const articleKey = searchParams.get('a')

    const navigate = useNavigate()
    const baseTagRef = useRef()

    const articlesKeys = Object.keys(articles ?? {}).filter(k => display?.Articles?.includes(k))
    const calibersKeys = Object.keys(calibers ?? {}).filter(k => display?.Calibers?.includes(k))

    let imageUri = display?.ImageUri
    
    if (!display?.ImageUri && articlesKeys?.length === 1) {
        const articleKey = display.Articles[0]
        const article = articles?.[articleKey]
        imageUri = article?.Images?.[0]?.["@1x"]
    }

    const { keys: childKeys, tags: childTags } = tagsFromKeys(floor, [...articlesKeys, ...calibersKeys], null, displayKey)

    const tabTagsKey = calibersKeys?.length > 0 ? 'calibers':'articles'

    useEffect(() => {
        if (!display) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [display])
    return (
        <BaseTag
            floating={floating}
            ref={baseTagRef}
            tagType={'Display'}
            tagKey={displayKey}
            journeyKey={journeyKey}
            filter={filter}
            initialTabKey={articleKey ? 'articles' : null}
            tagApi={tagApi}
            title={display?.Name[locale]}
            description={display?.Description[locale]}
            fromPath={fromPath}
            imageUri={imageUri}
            audioUri={display?.AudioUri?.[locale]}
            locateTagKey={displayKey} fromPathTagKey={displayKey}
            tabs={{
                [tabTagsKey]: (
                    <Tags inline scrollRef={baseTagRef} keys={childKeys} tags={childTags} onSelect={onSelect} focusedTagKey={articleKey} />
                )
            }}
        />
    )
}
