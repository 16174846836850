import styled from '@emotion/styled/macro';
import ButtonBase from '@mui/material/ButtonBase';
import { IconButtons } from './components/components';
import Icons from './components/Icons';

import { useFavorite } from './hooks';

/**
 * BaseAltTag: component used to display an (alt) tag in a vertical list (e.g. Tags) 
 */
export default function BaseAltTag({ imageUri, tagKey, displayTagKey, tagType, filter, childType, childKey, name, description, audioUri, onSelect }) {
    const { isFavorite, toggleFavorite } = useFavorite(childType ?? tagType, childKey ?? tagKey)

    const handleRemoveFavoriteClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
        toggleFavorite(false)
    }

    const handleClick = () => {
        onSelect(tagType, tagKey, childType, childKey)
    }

    return (
        <Layout onClick={handleClick}>
            {!imageUri && <TagKey>{displayTagKey??tagKey}</TagKey>}
            {imageUri && <Image src={imageUri} />}
            <ContentLayout withImage={imageUri ? 1 : 0}>
                <Name dangerouslySetInnerHTML={{__html: name}}></Name>
                {description && <Description dangerouslySetInnerHTML={{__html: description}}></Description>}
                {audioUri && <AudioGuideIcon disabled={!audioUri} />}
                {filter === 'favorites' && isFavorite &&
                    <RemoveIconButton onClick={handleRemoveFavoriteClick} />
                }
            </ContentLayout>
        </Layout>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    width: '100%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    overflow: 'visible',
    justifyContent: 'flex-start',
    textAlign: 'left',
    color: 'primary.main',
    fontSize: '1rem',
    padding: '1.25rem 0',
}))

const TagKey = styled('div')(props => props.theme.sx({
    width: '2.95rem',
    height: '2.95rem',
    margin: '0 0.52rem',
    lineHeight: '2.5rem',
    color: 'secondary.main',
    borderRadius: '50%',
    border: 'solid 0.0625rem',
    borderColor: 'secondary.main',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    fontSize: '0.95rem',
    whiteSpace: 'nowrap',
}))

const Image = styled('div')(props => props => props.theme.sx({
    height: '4rem',
    width: '4rem',
    backgroundImage: 'url(' + props.src + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
}))


const ContentLayout = styled('div')(props => props.theme.sx({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '0.2rem 0.525rem',
    position: 'relative',
    paddingRight: '1rem',
    ...(props.withImage && {
        //  paddingLeft: 0
    })
}))

const Name = styled('div')(props => props.theme.sx({
    flexShrink: 1,
    marginBottom: '0.313rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 'bold',
    color: 'secondary.main'
}))

const Description = styled('div')(props => ({
    flexShrink: 1,
    marginBottom: '0.625rem',
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.875rem',
}))

const AudioGuideIcon = styled(Icons.Headphone)(props => props.theme.sx({
    ...(props.disabled && {
        opacity: 0.4
    }),
    opacity: 0.75,
    flexShrink: 0,
    width: '1.45rem'
}))


const RemoveIconButton = styled(IconButtons.Cross)({
    position: 'absolute',
    right: '1.25rem',
    top: 'calc(50% - 0.925rem)',
    width: '2.15rem',
    pointerEvents: 'initial'

})