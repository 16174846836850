import styled from "@emotion/styled/macro"

const styledHelper = {
    absoluteFill: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    }
}

styledHelper.absolutePseudoFill = {
    ...styledHelper.absoluteFill,
    content: '""'
}


styledHelper.rectangle = (height) => {
    return {
        height: 'auto',
        position: 'relative',
        '&:after': {
            content: '""',
            paddingBottom: height + '%',
            display: 'block'
        }
    }
}


styledHelper.rectangle2 = (width) => {
    return {
        width: 'auto',
        position: 'relative',
        '&:after': {
            content: '""',
            paddingRight: width + '%',
            display: 'block'
        }
    }
}

styledHelper.opacityMask = (direction, percent) => {
    return {
        maskImage: 'linear-gradient(' + (direction ?? 'to right') + ', rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ' + (percent ?? 5) + '%, rgba(0, 0, 0, 1) ' + (100 - (percent ?? 5)) + '%, rgba(0, 0, 0, 0) 100%)',
    }
}

styledHelper.bottomOpacityMask = (percent) => {
    return {
        maskImage: 'linear-gradient(to bottom), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) ' + (100 - (percent ?? 5)) + '%, rgba(0, 0, 0, 0) 100%)',
    }
}


styledHelper.htmlContent = (props) => {
    return {
       'p': {
        },
        'h1': {
            fontSize: '18px'
        },
        'sup': {
            fontSize: '0.6rem'
        }
    }
}

export default styledHelper
