import Default from './Default';
import { useIntl } from './hooks';

export default function Infos({ searchActive }) {
    const { intl } = useIntl()
    return (
        <>

            <Default title={intl.get('helmet.title.infos')} header={intl.get('helmet.navigation.infos')} searchActive={searchActive}>
                <div dangerouslySetInnerHTML={{__html: intl.get('Infos.content').d('Infos.content')}} />
            </Default>
        </>
    )
}
