import 'intl/locale-data/jsonp/en.js';
import 'intl/locale-data/jsonp/ja.js';


export const activeLocales = ["en", "ja"]//, "fr", "de"]
export const defaultLocale = "en"

const locales = {
  "en": {
    global: {
      locales: {
        en: 'English',
        ja: '日本語'
      },
      join: "{text} ",
    },
    helmet: {
      title: {
        home: 'Patek Philippe Watch Art Grand Exhibition Tokyo 2023',
        infos: 'Information',
        visit: '{filter} {text}'
      },
      navigation: {
        infos: 'Information',
        visit: 'Watch Art Grand Exhibition',
        qrScanner: 'QR Code Scanner'
      }
    },
    filters: {
      rooms: "Visit",
      search: "Search",
      favorites: "Favorites",      
    },
    Infos: {
      content:
        '<p class="strong primary">DISCOVERING THE WORLD OF PATEK PHILIPPE</p>' +
        '<p>June 10 - June 25, 2023</p>' +
        '<p class="strong primary marginTop2500">SHINJUKU SANKAKU HIROBA</p>' +
        '<p class="marginTop0750">2 Chome-6-1 Nishishinjuku, Shinjuku City, Tokyo</p>' +
        '<p class="marginTop1875"><b>Opening hours:</b> 10 am to 8 pm</p>' +
        '<p class="marginTop0175"><b>Saturday</b> 10 June: 10 am to 5 pm</p>' +
        '<p class="marginTop0175"><b>Sunday</b> 25 June: 10 am to 5 pm</p>' +
        '<p class="weak marginTop0375">Last entry is one hour before the exhibition closes</p>' +
        '<p class="strong primary marginTop2500">WATCH ART GRAND EXHIBITION TOKYO 2023</p>' +
        '<p class="marginTop2500">Patek Philippe requests the pleasure of your company at Watch Art Grand Exhibition - a truly exceptional event that offers the public a rare insight into the world of watchmaking within our family owned company.</p>' +
        '<p>After a long time awaited by many watch lovers and Patek Philippe enthusiasts, the Watch Art Grand Exhibition takes place in Japan for the first time. It demonstrates the importance of Japan to Patek Philippe with a 2,500-square-meter exhibition - the largest ever organized by the brand.</p>' +
        '<p>The exhibition takes visitors on a journey through the world of Patek Philippe, its historic Geneva headquarters, the workshops and the Museum.</p>' +
        '<p>Our new commemorative collection is showcased together with over 400 unique and historic timepieces celebrating the tradition of innovation and independence, art and craftsmanship, aesthetics and passion – values that have defined Patek Philippe since 1839.</p>'
    },
    QRCodeScanner: {
      Camera: 'Camera',
      NoCamera: '<p>No camera is available within this application.<br/>Please use an external QR code scanner.</p>'
    },
    Filter: {
      noFavorite: "No favorites.",
      noResult: "No search results.",
      minSearch: "Please enter at least 3 characters"
    },
    Menu: {
      home: "Home",
      map: "Map",
      visit: "Visit",
      favorites: "Favorites",
      scanQR: "QR Code Scanner",
      informations: "Informations",
      copyright: "@Patek Philippe " + new Date().getFullYear()
    },
    Search: {
      placeHolder: 'Search here'
    },
    BaseTag: {      
      tab: {
        default: 'Informations',
        displays: 'Showcases',
        locate: 'Locate on the map',
        articles: 'Exhibits',
        calibers: 'Calibers',
        favorite: {
          'true': 'Remove from favorites',
          'false': 'Add to favorites',
        }
      }
    },
  },
  "ja": {
    global: {
      locales: {
        en: 'English',
        ja: '日本語'
      },
      join: "{text} ",
    },
    helmet: {
      title: {
        home: 'パテック フィリップ・ウォッチアート・グランド・エキシビション（東京2023）',
        infos: 'インフォメーション',
        visit: '{filter} {text}'
      },
      navigation: {
        infos: 'インフォメーション',
        visit: 'ウォッチアート・グランド・エキシビション',
        qrScanner: 'QRコードリーダー'
      }
    },
    filters: {
      rooms: "展示概要",
      search: "検索",
      favorites: "お気に入り",      
    },
    Infos: {
      content:
        '<p class="strong primary">パテック フィリップの世界を発見する</p>' +
        '<p>2023年6月10日 - 6月25日</p>' +
        '<p class="strong primary marginTop2500">新宿住友ビル 三角広場</p>' +
        '<p class="marginTop0750">東京都新宿区西新宿2丁目6-1</p>' +
        '<p class="marginTop1875"><b>開場時間：</b>午前10時から午後8時</p>' +
        '<p class="marginTop0175"><b>6月10日（土）：</b>午前10時から午後5時</p>' +
        '<p class="marginTop0175"><b>6月25日（日）：</b>午前10時から午後5時</p>' +
        '<p class="weak marginTop0375">最終入場時刻は終了1時間前です。</p>' +
        '<p class="strong primary marginTop2500">ウォッチアート・グランド・エキシビション（東京2023）</p>' +
        '<p class="marginTop2500">パテック フィリップは、《ウォッチアート・グランド・エキシビション》に皆様をお迎えできることを光栄に存じます。この特別なイベントでは、家族経営のマニュファクチュール パテック フィリップによる時計製作の世界を、一般の方に貴重な体験としてご堪能いただけます。</p>' +
        '<p>数多くの時計愛好家やパテック フィリップのファンから待ち望まれた《ウォッチアート・グランド・エキシビション》は、このたび初めて日本で開催されることになりました。過去最大となる2,500㎡の会場を誇る本展示会は、パテック フィリップにとっての日本の重要性を立証するものです。</p>' +
        '<p>ご来場の皆様を、ジュネーブの本店サロン、マニュファクチュール、およびパテック フィリップ・ミュージアムなど、パテック フィリップの世界への旅にお連れします。</p>' +
        '<p>パテック フィリップの記念すべき新しいコレクションは、革新の伝統、独立、品質と精緻な仕上がり、美、思い入れを体現する、400点以上のユニークで歴史的なタイムピースとともに展示されます。これらの価値は、1839年の創業以来、パテック フィリップを形作ってきたものです。</p>'
    },
    QRCodeScanner: {
      Camera: 'カメラ',
      NoCamera: '<p>このアプリケーションでカメラが利用できません。<br/>別のQRコードリーダーをご利用ください。</p>'
    },
    Filter: {
      noFavorite: "お気に入りに登録されているものがありません。",
      noResult: "検索結果がありませんでした。",
      minSearch: "少なくとも 3 文字を入力してください"
    },
    Menu: {
      home: "ホーム",
      map: "会場案内図",
      visit: "展示概要",
      favorites: "お気に入り",
      scanQR: "QRコード<br/>リーダー",
      informations: "インフォメーション",
      copyright: "@Patek Philippe " + new Date().getFullYear()
    },
    Search: {
      placeHolder: '検索'
    },
    BaseTag: {      
      tab: {
        default: 'インフォメーション',
        displays: 'ショーケース',
        locate: '地図で位置を確認',
        articles: '展示',
        calibers: 'キャリバー',
        favorite: {
          'true': 'お気に入りを解除',
          'false': 'お気に入りに追加',
        }
      }
    }
  }
}

export default locales