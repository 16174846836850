// import styled from '@emotion/styled/macro';
import { forwardRef, useEffect, useRef } from 'react'
import { TextField as MuiTextField } from '@mui/material'
import styled from '@emotion/styled/macro'
import IconButtons from './IconButtons';

// const ForwardedTextField = forwardRef(TextFieldContainer)
// export default ForwardedTextField

// HOC hot reload issue
export default function TextFieldContainer(props) {
    const { className, ..._props } = props

    const handleFocus = () => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
    }

    return (
        <Layout className={className}>
            <TextField placeholder={props.placeholder} onFocus={() => handleFocus} inputRef={props.inputRef} autoComplete='off'
                icon={props.icon ? true : false} clearable={_props.onClear && _props.value && _props.value.length > 0} {..._props} />
            {_props.onClear &&
                <ClearIconButton accent={props.accent } icon={props.icon && (!_props.value || _props.value.length < 1) ? true : false} active={_props.value && _props.value.length > 0} onClick={_props.onClear} />
            }
            {props.icon && <IconLayout active={!_props.value || _props.value.length < 1}>{props.icon}</IconLayout>}
            {props.children}
        </Layout>
    )
}


const Layout = styled('div')(props => props.theme.sx({
    position: 'relative',
    width: '100%',
    overflow: 'visible',
    borderRadius: '1.5rem',
    padding: '0.5625rem 1.5rem',
    boxShadow: '0px 0.188rem 0.375rem #00000029',
    backgroundColor: 'primary.surface'
}))

const ClearIconButton = styled(IconButtons.Cross, {
    shouldForwardProp: (prop) => prop !== "active" && prop !== 'icon'
})(props => ({
    position: 'absolute',

    right: '1.125rem',
    top: 'calc(50% - 1.125rem)',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.2s ease-in',
    visibility: 'hidden',
    ...(props.active && {
        opacity: 1,
        visibility: 'visible',
        pointerEvents: 'initial',
    }),
    ' > svg': {
        width: '1.125rem',
        height: '1.125rem',
    }
}))

ClearIconButton.defaultProps = { variant: 'normal', size: 'medium' }

const IconLayout = styled('div')(props => ({
    position: 'absolute',
    right: '10px',
    top: '9px',
    '> svg': {
        width: '20px'
    },
    transition: 'opacity 0.2s ease-in',
    opacity: 0,
    visibility: 'hidden',
    ...(props.active && {
        opacity: 0.7,
        visibility: 'visible'
    }),
}))

const TextField = styled(MuiTextField, {
    shouldForwardProp: (prop) => prop !== "variant" && prop !== "accent" && prop !== "clearable" && prop !== "onClear" && prop !== "clearable" && prop !== "icon"
})(props => props.theme.sx({
    margin: 0,
    width: '100%',
    padding: 0, // '14px ' + (props.clearable ? '28px' : '14px') + ' 18px 14px',

    'input.MuiInputBase-input': {
        padding: '0',
        margin: 0,
        fontSize: '1rem',
        backgroundColor: 'primary.surface',
        color: (theme) => (props.accent ? theme.palette.secondary.main : theme.palette.primary.main),
    },
    '.MuiOutlinedInput-notchedOutline': {
        display: 'none',
        borderRadius: 0,

    },
    '.MuiInputLabel-root': {
        display: 'none',
        fontSize: '20px',
        fontStyle: 'italic',
        left: '50%',
        padding: '0 3px',
        maxWidth: 'unset',
        transform: 'translate(-50%, 10px) scale(1)',
        transitionProperty: 'all',
        '&.MuiInputLabel-shrink': {
            left: 0,
            transform: 'translate(14px, 1px) scale(0.5)',
        },
        color: 'input.color.default',
        '&.Mui-error': {
            color: 'input.color.error'
        }
    },
    '.MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        bottom: 0,
        left: '14px',
        marginLeft: 0,
        color: 'input.color.error'
    },
    ...(props.variant === 'search' && {
        'input.MuiInputBase-input': {
            padding: '10px ' + (props.clearable ? (props.icon ? '70px' : '28px') : '10px') + ' 10px 10px',
            fontStyle: 'italic'
        },
    })
}))
TextField.defaultProps = { margin: 'none' }
