import styled from '@emotion/styled/macro';
import { Portal } from '@mui/material';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { createSearchParams, NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import Breadcrumb, { BreadcrumbItem } from './Breadcrumb';
import IconButtons from './components/IconButtons';
import Helmet from './Helmet';
import { useArticles, useCalibers, useDisplays, useFloorContext, useIntl, useJourney, useMediaQuery, useNavigationType, useRooms, useTagLayout, useTagsExtension } from './hooks';
import Tag from './Tag';


function NavigationBreadcrumb({ className, filter, tagKey, tagType, childKey, previousNavigationLayoutRef, opaque }) {
    const { locale, intl } = useIntl()
    const navigate = useNavigate()
    const { displays } = useDisplays()
    const { rooms } = useRooms()
    const { articles } = useArticles()
    const { calibers } = useCalibers()

    const breadcrumbItems = []
    const navigateOptions = { replace: filter === 'search' }
    const [searchParams] = useSearchParams()

    const baseNavigation = './' + filter + (filter ? '/' : '')

    const getSearchParams = (r, d, a) => {
        let s = searchParams.get('s')
        let p = createSearchParams({ ...(s ? { s: s } : {}), ...(r ? { r: r } : {}), ...(d ? { d: d } : {}), ...(a ? { a: a } : {}) })
        return p ? ('?' + p) : ''
    }

    if (filter) {
        breadcrumbItems.push({
            name: intl.get("filters." + filter).d(intl.get("filters." + filter)),
            handleNavigate: tagKey ? () => {
                navigate(baseNavigation + getSearchParams(tagKey), navigateOptions)
            } : null
        })
    }

    if (childKey || tagType?.toLowerCase() === 'display') {
        const display = displays?.[tagKey]
        const room = rooms?.[display?.Room]
        breadcrumbItems.push({
            name: room?.Name[locale],
            handleNavigate: () => {
                navigate(baseNavigation + 'room/' + display?.Room + getSearchParams(display?.Room, tagKey, null), navigateOptions)
            }
        })
        breadcrumbItems.push({
            name: display?.Name[locale],
            handleNavigate: childKey ? () => {
                navigate(baseNavigation + 'display/' + tagKey + getSearchParams(display?.Room, tagKey, childKey), navigateOptions)
            } : null
        })
        if (childKey) {
            const articlesKeys = Object.keys(articles ?? {}).filter(k => display.Articles?.includes(k))
            const _childKey = articlesKeys.find(k => k === decodeURIComponent(childKey))

            if (_childKey) {
                breadcrumbItems.push({ name: _childKey })
            } else {
                const calibersKeys = Object.keys(calibers ?? {}).filter(k => display.Calibers?.includes(k))
                const _childKey = calibersKeys.find(k => k === decodeURIComponent(childKey))
                if (_childKey) {
                    breadcrumbItems.push({ name: _childKey })
                }
            }
        }
    } else if (tagType?.toLowerCase() === 'room') {
        const room = rooms[tagKey]
        breadcrumbItems.push({ name: room?.Name[locale] })
    }

    const prevNavigateBreadcrumb = [...breadcrumbItems]?.reverse().find(bi => bi.handleNavigate) // find last :(

    return (
        <>
            <Breadcrumb opaque={opaque} className={className}>
                <BreadcrumbItem>
                    <NavLink to="/">{intl.get('Menu.home')}</NavLink>
                </BreadcrumbItem>
                {breadcrumbItems.map((breadcrumbItem, index) => {
                    if (breadcrumbItem.handleNavigate)
                        return (
                            <BreadcrumbItem key={index} onClick={breadcrumbItem.handleNavigate}>
                                <span dangerouslySetInnerHTML={{ __html: breadcrumbItem.name }} />
                            </BreadcrumbItem>
                        )
                    else return (
                        <BreadcrumbItem key={index} disabled>
                            <span dangerouslySetInnerHTML={{ __html: breadcrumbItem.name }} />
                        </BreadcrumbItem>
                    )
                })}
            </Breadcrumb>
            {prevNavigateBreadcrumb && previousNavigationLayoutRef?.current &&
                <Portal container={previousNavigationLayoutRef.current}>
                    <PreviousNavigationIconButton onClick={prevNavigateBreadcrumb.handleNavigate} />
                </Portal>
            }
        </>
    )
}

export default function Visit({ searchActive }) {
    const { tagKey, tagType, journeyKey, childKey, childType, ...rest } = useParams()
    const { parentTag, tagNamesTree } = useTagsExtension()
    const { parentTagKey, parentTagType } = parentTag(tagKey, tagType, childKey, childType)
    const navigationType = useNavigationType()
    const navigate = useNavigate()

    const { is: isMediaQuery } = useMediaQuery()
    const isMobile = isMediaQuery.only('mobile')
    const isIos = navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('ipad') > -1


    const previousNavigationLayoutRef = useRef()
    const [searchParams] = useSearchParams()
    const [tagItemOpen, toggleTagItemOpen] = useToggle(true)

    const { path: journeyPath, currentTag: journeyTag } = useJourney(journeyKey)
    const { intl } = useIntl()

    let filter = rest?.['*']?.toLowerCase()
    let isMap = false

    if (filter?.startsWith('visit/rooms'))
        filter = 'rooms'
    else if (filter?.startsWith('visit/search'))
        filter = 'search'
    else if (filter?.startsWith('visit/favorites'))
        filter = 'favorites'
    else if (filter?.startsWith('visit/audioguides'))
        filter = 'audioguides'
    else {
        filter = 'rooms'
        isMap = true
    }

    const dragTagRef = useRef()
    const dragTagLimitRef = useRef()
    const [dragState, setDragState] = useState(-1) // in %
    const freeDragState = useRef(-1)


    const { isVertical, layoutStyle, setDragPercent, updateToLimit } = useTagLayout(dragTagRef, dragTagLimitRef,
        (percent) => {
            setDragState(percent)
            if (percent > 10 && percent < 90)
                freeDragState.current = percent
        }, (dir) => {
            setDragPercent(dir ? 100 : 0)
        })

    //?
    useEffect(() => {
        updateToLimit(dragState > 97)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchActive])

    const isBack = navigationType === 'POP' || navigationType === 'REPLACE'

    const mainTagItem = {
        journeyKey: journeyKey,
        filter: filter,
        tagType: tagType,
        tagKey: tagKey,
        childType: childType,
        childKey: childKey
    }

    let tagItem = undefined
    // set parent as main
    if (!isMobile) {
        tagItem = tagItemOpen && tagKey ? { ...mainTagItem } : undefined
        mainTagItem.tagType = parentTagType ? parentTagType : undefined
        mainTagItem.tagKey = parentTagKey ? parentTagKey : undefined
        mainTagItem.childType = undefined
        mainTagItem.childKey = undefined
    }

    const mainTagTransition = useTransition(mainTagItem, {
        key: item => isMap + "#" + mainTagItem.journeyKey + "#" + mainTagItem.tagKey + "#" + mainTagItem.childKey + "#" + JSON.stringify(filter),
        from: { opacity: 0.6, translateY: isMobile ? isBack ? '-100%' : '100%' : '0%', translateX: !isMobile ? isBack ? '-100%' : '100%' : '0%' },
        enter: { opacity: 1, translateY: '0%', translateX: '0%' },
        leave: { opacity: 1, translateY: isMobile ? isBack ? '100%' : '-100%' : '0%', translateX: !isMobile ? isBack ? '100%' : '-100%' : '0%' },
        initial: { opacity: 1, translateY: '0%', translateX: '0%' },
        config: navigationType === 'POP' && isIos ? { duration: 0 } : config.default
    })

    const tagTransition = useTransition(tagItem, {
        key: item => tagItem.tagKey + "#" + tagItem.childKey,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        // initial: { opacity: 1 },
        config: navigationType === 'POP' && isIos ? { duration: 0 } : { duration: 400 } //config.default
    })

    const _navigateToTag = (filter, type, key, childType, childKey) => {
        let url = '/visit/' + (filter ? filter + '/' : '') + (journeyKey ? 'journey/' + journeyKey + '/' : '') + type?.toLowerCase() + '/' + key

        if (childKey)
            url += '/' + childType?.toLowerCase() + '/' + encodeURIComponent(childKey)
        let r = searchParams.get('r')
        let d, a = null
        let s = searchParams.get('s')

        if (type === 'Room') {
            r = key
            d = null
            a = null
        } else if (type === 'Display') {
            d = key
            a = null
            if (childKey)
                a = childKey
        }

        let p = createSearchParams({ ...(s ? { s: s } : {}), ...(r ? { r: r } : {}), ...(d ? { d: d } : {}), ...(a ? { a: a } : {}) })

        url += '?' + p

        toggleTagItemOpen(true)
        navigate(url) //, { state: { visitState: { tagKey: key, tagType: type, childKey: childKey } } })
    }

    const navigateToTag = (type, key, childType, childKey) => {
        _navigateToTag(filter, type, key, childType, childKey)
    }

    const navigateToAltTag = (type, key) => {
        return _navigateToTag('rooms', type, key)
    }

    // get floor reference api
    const { floorRef } = useFloorContext({
        onSelectTag: (type, key, e) => {
            e.stopPropagation()

            if (dragState > -1) // && key !== tagKey)
                navigateToAltTag(type, key)
            else if (dragState < 50 && key === tagKey)
                setDragPercent(100)
            else if (key !== tagKey)
                navigateToAltTag(type, key)
        },
        onSelectAltTag: (type, key) => {
            navigateToTag(type, key)
        },
        onClick: () => {
        },
        onClearPath: () => {
            if (tagKey) {
                setDragPercent(100)
                floorRef.current?.focus(tagKey)
            }
        }
    })


    // tag visit api
    const tagApi = {
        ensureTagOpen: () => {
            if (dragState < 60)
                setDragPercent(80)
        },
        locate: (tagKey) => (e) => {
            if (tagItem)
                setDragPercent(0)
            else if (isMobile)
                setDragPercent(40)
            floorRef.current?.locate(tagKey)
        },
        selectFromPath: (tagKey) => (e) => {
            setDragPercent(20)
            floorRef.current?.locate(tagKey)
            floorRef.current?.selectFromPath(tagKey)
        },
        setJourneyPath: (path, node) => {
            floorRef.current?.setJourneyPath(path, node)
        }
    }

    // // clear any path when tag key changed
    // useEffect(() => {
    //     floorRef.current?.clearFromPath()
    // }, [tagKey])


    // // set the journey path for tag key if any
    // useEffect(() => {
    //     floorRef.current?.setJourneyPath(journeyPath, journeyTag?.key)
    // }, [journeyPath, journeyTag?.key])


    // set initial drag state according to filter and tag 
    // additionaly focus current tag
    useEffect(() => {
        if (dragTagLimitRef.current) {

            if (dragState <= 20) {
                if (isMap)
                    setDragPercent(0)
                else {
                    setDragPercent(100)
                }
            } 
            // else
            //     setDragPercent(dragState)
        }

        if (tagKey) {
            setTimeout(() => {
                const focus = searchParams.get('l') === '1'
                if (focus)
                    floorRef.current?.focus(tagKey)
                floorRef.current?.setActiveTag(tagKey)
            }, 100)
        }
        if (!tagKey)
            floorRef.current?.setActiveTag(null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tagKey, dragTagLimitRef.current])

    /// 
    // const defaultTag = Object.keys(floor?.Tags??{}).find(k => floor?.Tags?.[k].IsDefault)

    useEffect(() => {
        setTimeout(() => {
            if (isMap)
                floorRef.current?.recenter()
            if (!isMap && dragState < 60) {
                setDragPercent(isVertical ? 100 : 80)
            }
        }, 0)

    }, [filter, isMap])

    const handleToggleOpen = () => {
        if (dragState < 2)
            setDragPercent(100)
        else if (dragState >= 98)
            setDragPercent(freeDragState.current)
        else
            setDragPercent(0)
    }

    const pathes = [
        "M 1 5 L 5 5 L 9 5",
        "M 2 3.5 L 5 6.5 L 8 3.5",
        "M 2 6.5 L 5 3.5 L 8 6.5",
    ]
    const [svgDragStyles, svgDragStylesAnimate] = useSpring((dragState) => ({
        path: pathes[dragState > 98 ? 1 : (dragState === null || dragState >= 2 ? 0 : 2)],
        config: { duration: 200 }
    }))

    useEffect(() => {
        svgDragStylesAnimate({
            path: pathes[dragState > 98 ? 1 : (dragState < 2 ? 2 : 0)],
        })
    }, [dragState])

    const titleSuffix = tagNamesTree(tagType, tagKey, childKey)?.join(": ")
    return (
        <>
            <Helmet>
                <title>{intl.get("helmet.title.visit", { filter: intl.get('filters.' + filter), text: (titleSuffix ? ': ' : '') + titleSuffix })}</title>
                <header>{filter === "rooms" ? intl.get("helmet.navigation.visit") : intl.get("filters." + filter)}</header>
            </Helmet>
            <HeaderBackground opaque={isVertical && dragState > 98} />
            <Portal>
                {/* Layout limit of dragging vertical = mobile horizontal otherwise */}
                <LimitLayout vertical={isVertical ? 1 : 0} ref={dragTagLimitRef} searchActive={searchActive}></LimitLayout>
            </Portal>

            <Layout map={isMap ? 1 : 0} vertical={isVertical ? 1 : 0} style={layoutStyle}>
                <DragTag vertical={isVertical ? 1 : 0} fullyOpen={dragState > 98 ? 1 : 0} fullyClosed={dragState < 2 ? 1 : 0} onClick={handleToggleOpen} ref={dragTagRef}>
                    <SvgDragTag fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                        <g>
                            <animated.path fill="none" d={svgDragStyles.path}></animated.path>
                        </g>
                    </SvgDragTag>
                </DragTag>

                <MainTagContainer vertical={isVertical ? 1 : 0} searchActive={searchActive ? 1 : 0}>
                    <MainTagOverflowContainer>
                        {mainTagTransition((style, mainTagItem) => {
                            return mainTagItem &&
                                <MainTagLayout vertical={isVertical ? 1 : 0} style={style} key={mainTagItem.tagKey + mainTagItem.childKey}>
                                    <Tag tagApi={tagApi} tagType={mainTagItem.tagType} filter={mainTagItem.filter} journeyKey={mainTagItem.journeyKey} tagKey={mainTagItem.tagKey} childKey={mainTagItem.childKey} childType={mainTagItem.childType} onSelect={navigateToTag} />
                                </MainTagLayout>
                        })}
                        {mainTagItem?.tagKey && <PreviousNavigationLayout ref={previousNavigationLayoutRef} />}
                    </MainTagOverflowContainer>

                    <NavigationBreadcrumbContainer opaque={isVertical && dragState > 98} filter={filter} tagKey={tagKey} tagType={tagType} childKey={childKey} previousNavigationLayoutRef={previousNavigationLayoutRef} />

                </MainTagContainer>
                {tagTransition((style, tagItem) => {
                    return tagItem && <TagLayout style={style}>
                        <CloseTagIconButton onClick={() => toggleTagItemOpen()} />
                        <Tag floating={1} tagApi={tagApi} tagType={tagItem.tagType} filter={tagItem.filter} journeyKey={tagItem.journeyKey} tagKey={tagItem.tagKey} childKey={tagItem.childKey} childType={tagItem.childType} onSelect={navigateToTag} />
                    </TagLayout>
                })}
            </Layout>

        </>
    )
}

const HeaderBackground = styled('div')(props => props.theme.sx({
    position: 'fixed',
    width: '100vw',
    top: '0',
    left: '0',
    height: '3.75rem',
    zIndex: 4,
    backgroundColor: 'primary.surfaceSemiLight',
    ...(props.opaque && {
        backgroundColor: 'primary.surface',
    }),
    transition: 'background 0.4s',
}))

const Layout = styled(animated.div, {
    shouldForwardProp: (prop) => prop !== "fullyOpen"
})(props => props.theme.sx({
    position: 'absolute',
    bottom: '0',
    left: '0',
    top: '0',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 2,
    ...(!props.vertical && {
        marginTop: '3.75rem',
    }),
    ...(props.map && {
        display: 'none'
    }),
    // backgroundColor: 'primary.surface'
}))


const LimitLayout = styled('div')(props => ({
    ...(!props.vertical && {
        bottom: '0',
        top: '3.75rem',
        right: '0',
        left: '0',
        maxWidth: 'min(40rem, calc(100vw - 6rem - 40vw))'
    }),
    ...(props.vertical && {
        width: '100%',
        top: '5.75rem',
        bottom: '0rem',
        ...(props.searchActive && {
            top: '8.85rem'
        })
    }),
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 20,
}))


const PreviousNavigationLayout = styled('div')({
    position: 'absolute',
    left: '0',
    top: '0.4rem',
    width: '3rem',
    height: '3rem',
    zIndex: 10,
})

const PreviousNavigationIconButton = styled(IconButtons.Prev)({
    '> svg': {
        width: '1.6rem',
        padding: '0.2rem'
    }
})

const DragTag = styled('div')(props => props.theme.sx({
    ...(!props.vertical && {
        // left: 'calc(100% - 0.15rem)',
        right: '0px',
        top: '0',
        width: '3rem',
        bottom: '0',
        backgroundColor: 'primary.surface',
        borderLeft: 'solid 1px',
        borderLeftColor: 'secondary.surfaceContrastLight',
        [SvgDragTag]: {
            transform: 'rotate(90deg)',
            top: 'calc(50% - 1.5rem)'
        },
        ...(!props.fullyClosed && {
            // borderTopRightRadius: '0.9375rem',
            // borderBottomRightRadius: '0.9375rem',
        }),
        boxShadow: (theme) => '7px 0px 1px -4px #00000029',// + theme.palette.primary.main,
    }),
    ...(props.vertical && {
        backgroundColor: 'primary.surface',
        top: '0',
        width: '100%',
        height: '3rem',
        ...(!props.fullyOpen && {
            boxShadow: (theme) => '0 -3px 7px #00000029',// + theme.palette.primary.main,
            borderTopLeftRadius: '0.9375rem',
            borderTopRightRadius: '0.9375rem',
        })
    }),

    touchAction: 'none',
    color: 'black',
    position: 'absolute',

    zIndex: 2,
    transition: 'all 0.4s',
    cursor: 'grab'
}))


const SvgDragTag = styled(animated.svg)(props => props.theme.sx({
    width: '2.25rem',
    height: 'auto',
    zIndex: 100,
    position: 'absolute',
    top: '0.5rem',
    left: 'calc(50% - 1.125rem)',
    stroke: (theme) => theme.palette.primary.main,
    strokeWidth: 0.65
}))

const MainTagContainer = styled('div')(props => props.theme.sx({
    zIndex: 1,
    position: 'absolute',
    backgroundColor: 'primary.surface',
    ...(!props.vertical && {
        bottom: '0',
        top: '0',
        left: 0,
        right: '3rem',
        ...(props.searchActive && {
            [MainTagOverflowContainer]: {
                top: '4rem',
            }
        }),

    }),
    ...(props.vertical && {
        width: '100%',
        top: '2.75rem',
        bottom: 0,
        [NavigationBreadcrumbContainer]: {
            width: '100%',
        }
    }),

    ...(!props.vertical && {
        [NavigationBreadcrumbContainer]: {
            position: 'absolute',
            top: 0,
            left: 'calc(100% + 3rem)',
            width: 'calc(100vw - 100% - 3rem)',
        }
    })
}))

const MainTagOverflowContainer = styled('div')({
    transition: 'top 0.6s',
    position: 'absolute',
    top: '0',
    bottom: '0',
    height: 'auto',
    left: '0',
    right: '0',
    overflow: 'hidden',
})

const NavigationBreadcrumbContainer = styled(NavigationBreadcrumb)({
})

const MainTagLayout = styled(animated.div)(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0'
}))

const TagLayout = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    left: 'calc(100% + 5vw)',
    top: '3.25rem',
    width: '40vw', //'calc(100vw - 100% - 10vw)',
    minWidth: '21.875rem',
    maxWidth: '40rem',
    height: 'calc(100vh - 9rem)',
    maxHeight: 'calc(100vh - 9rem)',
    borderRadius: '0.9375rem',
    overflow: 'hidden'
}))

const CloseTagIconButton = styled(IconButtons.Cross)({
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '2.15rem',
    zIndex: 10
})