import styled from '@emotion/styled/macro';
import { animated, useSpring } from '@react-spring/web';
import { useIntl, useRooms } from '../hooks';


export function useRoomStyle(scale, scaleThreshold) {
    // const [nameStyle, nameStyleApi] = useSpring(() => ({
    //     opacity: scale > scaleThreshold ? 1 : 0,
    //     fontSize: scale > scaleThreshold ? '2vw' : '3vw',
    //     marginTop: scale > scaleThreshold ? '0' : '1vw',
    //     // color: theme?.palette.secondary.main,  //scale > scaleThreshold ? theme?.palette.secondary.main : theme?.palette.primary.contrastText,
    //     config: { duration: 200 }
    // }))

    const [svgTagStyle, svgTagStyleApi] = useSpring(() => ({
        //  opacity: scale <= tagScaleThreshold ? 1 : 0,
        scale: scale <= scaleThreshold ? 1 : 0.65,
        opacity: scale <= scaleThreshold ? 1 : 0.8,
        config: { duration: 200 }
    }))


    const setScale = (scale) => {
        // nameStyleApi.start({
        //     opacity: scale > scaleThreshold ? 1 : 0,
        //     fontSize: scale > scaleThreshold ? '2vw' : '3vw',
        //     marginTop: scale > scaleThreshold ? '0' : '1vw',
        //     // color: theme?.palette.secondary.main  //scale > scaleThreshold ? theme?.palette.secondary.main : theme?.palette.primary.contrastText,
        // })
        setTimeout(() => {
            svgTagStyleApi.start({
                // opacity: scale <= tagScaleThreshold ? 1 : 0,
                scale: scale <= scaleThreshold ? 1 : 0.65,
                opacity: scale <= scaleThreshold ? 1 : 0.8
            })
        }, 0)

    }

    // return { styles: { name: nameStyle, svgTag: svgTagStyle }, setScale }
    return { styles: { svgTag: svgTagStyle }, setScale }

}

export default function RoomTag({ active, tagKey, tagStyles }) {
    const { rooms } = useRooms()
    const room = rooms?.[tagKey]
    return (

        <Layout active={active ? 1 : 0} style={tagStyles.svgTag} >
            <SvgTag xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <g>
                    <circle cx="50" cy="50" r="47" strokeWidth={3} />
                </g>
            </SvgTag>
            <TagKey>{room?.DisplayTagKey??tagKey}</TagKey>
        </Layout>
    )
}

const Layout = styled(animated.div)(props => props.theme.sx({
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: 0,
    position: 'relative',
    '&:hover': {
        cursor: 'pointer',
    },
    padding: '0rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [TagKey]: {
        color: 'white'
    },
    ...(props.active && {
        [SvgTag]: {
            fill: (theme) => theme.palette.secondary.contrastText,
            stroke: (theme) => theme.palette.secondary.main,
            pointerEvents: 'initial',
            '&:hover': {
                cursor: 'pointer',
            }
        },
        [TagKey]: {
            color: 'secondary.main'
        }
    }),
    transformOrigin: 'center calc(50%)',
    transform: 'translate(0, 0) scale(1) rotateZ(0)',
    backfaceVisibility: 'hidden'
}))

const TagKey = styled('span')(props => props.theme.sx({
    position: 'absolute',
    top: '3vw',
    width: '100%',
    textAlign: 'center',
    fontSize: '6vw',
    zIndex: 10,
    [props.theme.breakpoints.up('tablet')]: {
        top: '2.75vw'
    },
    [props.theme.breakpoints.up('laptop')]: {
        top: '2.25vw'
    },
    [props.theme.breakpoints.up('desktop')]: {
        top: '1.75vw'
    },
    [props.theme.breakpoints.up('largeDesktop')]: {
        top: '1vw'
   }
}))

const SvgTag = styled('svg')(props => props.theme.sx({
    width: '15vw',
    height: '15vw',
    zIndex: 3,
    fill: (theme) => theme.palette.secondary.main,
    stroke: (theme) => theme.palette.secondary.contrastText,
    pointerEvents: 'initial',
    transition: 'all 0.1s',
    '&:hover': {
        cursor: 'pointer',
    },
    [props.theme.breakpoints.up('tablet')]: {
        width: '14vw',
        height: '14vw',
    },
    [props.theme.breakpoints.up('laptop')]: {
        width: '13.5vw',
        height: '13.5vw',
    },
    [props.theme.breakpoints.up('desktop')]: {
         width: '12.5vw',
         height: '12.5vw',
    },
    [props.theme.breakpoints.up('largeDesktop')]: {
        width: '11vw',
        height: '11vw',
   }
}))