
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import BaseTag from '../BaseTag';
import { useIntl, useMap, useTagsExtension } from '../hooks';
import Tags from '../Tags';

export default function Filter({ tagApi, filter, onSelect, children, ...rest }) {
    const baseTagRef = useRef()
    const {locale, intl} = useIntl()
    const { map } = useMap()
    const [searchParams] = useSearchParams()
    const tagKey = searchParams.get('r')
    const { tagsForType, filterTags, favoritesTags, audioguidesTags } = useTagsExtension()

    const floor = Object.values(map?.Floors ?? {})?.[0]

    const  {keys, tags } = filter === 'rooms' ? tagsForType(floor, 'Room') //(k, t, room) => { return room.AudioUri?.[locale] })
        : filter === 'favorites' ? favoritesTags(floor)
            : filter === 'audioguides' ? audioguidesTags(floor)
                : filterTags(floor, searchParams.get('s'), ['Display']) ?? {}


    const [audioUri, setAudioUri] = useState(null)

    const handleSelectAudio = (audioUri) => {
        setAudioUri(audioUri)
        toggleAudio(true)
    }

    const [audio, toggleAudio] = useToggle(false)
    let description = undefined
    
    if (keys?.length < 1 && filter === 'favorites')
        description = intl.get('Filter.noFavorite')
    else if (keys?.length < 1 && filter === 'search') {
        const s = searchParams.get('s') 
        if (s?.length >= 3 )
            description = intl.get('Filter.noResult')
        else
            description = intl.get('Filter.minSearch')         
    }

    return (
        <BaseTag
            ref={baseTagRef}
            tagApi={tagApi}
            description={description}>   
                <Tags centered={filter === 'favorites'} scrollRef={baseTagRef} keys={keys} tags={tags} onSelect={onSelect} focusedTagKey={tagKey} filter={filter} onSelectAudio={filter === 'audioguides' ? handleSelectAudio : undefined} />             
        </BaseTag>
    )
}
