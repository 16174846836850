import styled from '@emotion/styled/macro';
import { IconButton as MuiIconButton } from '@mui/material';
import Icons from './Icons';


export const DefaultIconButton = styled(MuiIconButton, {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "size" && prop !== "nofill"
})(props => props.theme.sx({
    ...(props.accent && {
        'svg': {
            fill: (theme) => props.fill ?? (props.accent ? theme.palette.secondary.main : theme.palette.primary.main),
            'path': {
                stroke: (theme) => props.fill ?? (props.accent ? theme.palette.secondary.main : theme.palette.primary.main)
            }
        }
    }),
    cursor: 'pointer',
    '&.Mui-disabled': {
        opacity: 0.4
    },
    // ...(props.active && {
    //     '& > svg': {
    //         fill: (theme) => theme.palette.primary.contrastText,
    //     }
    // }),
    '&.MuiButtonBase-root': {
        visibility: 'inherit'
    },
    // '&:hover': {
    //     backgroundColor: 'iconButton.background.default',        
    // }
    '> span': {
        paddingRight: '1rem'
    }
}))

DefaultIconButton.defaultProps = { variant: 'outlined' }


const MenuIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Menu /></DefaultIconButton>)
const SearchIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Search /></DefaultIconButton>)
const CrossIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Cross /></DefaultIconButton>)
const DotsIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Dots /></DefaultIconButton>)
const NextIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Next /></DefaultIconButton>)
const PrevIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Prev /></DefaultIconButton>)
const UpIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Up /></DefaultIconButton>)
const FavoriteIconButton = (props) => (<DefaultIconButton nofill {...props}>{props.children}<Icons.Favorite /></DefaultIconButton>)
const ShareIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Share /></DefaultIconButton>)
const SaveIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Save /></DefaultIconButton>)
const SquaredIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Squared /></DefaultIconButton>)
const PlusIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Plus circled /></DefaultIconButton>)
const MinusIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Minus circled /></DefaultIconButton>)
const FilterIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Filter /></DefaultIconButton>)
const CalatravaIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Calatrava /></DefaultIconButton>)
const ScanIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Scan /></DefaultIconButton>)
const InfosIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Infos /></DefaultIconButton>)
const MapIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Map /></DefaultIconButton>)
const ItineraryIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Itinerary /></DefaultIconButton>)
const HeadphoneIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Headphone /></DefaultIconButton>)
const ArrowIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Arrow /></DefaultIconButton>)
const SearchLocateIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.SearchLocate /></DefaultIconButton>)
const SonnerieIconButton = (props) => (<DefaultIconButton {...props}>{props.children}<Icons.Sonnerie /></DefaultIconButton>)

const IconButtons = {
    Default: DefaultIconButton, SvgIcon: Icons.SvgIcon, Menu: MenuIconButton, Search: SearchIconButton, Cross: CrossIconButton, Dots: DotsIconButton, Filter: FilterIconButton,
    Next: NextIconButton, Prev: PrevIconButton, Up: UpIconButton, Favorite: FavoriteIconButton, Share: ShareIconButton, Save: SaveIconButton, Squared: SquaredIconButton, Plus: PlusIconButton, Minus: MinusIconButton,
    Calatrava: CalatravaIconButton, Scan: ScanIconButton, Infos: InfosIconButton, Map: MapIconButton, Itinerary: ItineraryIconButton, Headphone: HeadphoneIconButton, Arrow: ArrowIconButton, SearchLocate: SearchLocateIconButton, Sonnerie: SonnerieIconButton
}
export default IconButtons
