
import styled from '@emotion/styled/macro';
import { animated, config, useTransition } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { IconButtons, Icons, TextField } from '../components/components';
import { useIntl } from '../hooks';

/**
 * Search component
 * TODO: ---- 
 */
export default function Search({ active, onUpdateSearchActive }) {
    const navigate = useNavigate()
    const location = useLocation()
    const { intl } = useIntl()

    const [searchParams] = useSearchParams()

    const [search, setSearch] = useState(searchParams.get('s') ?? '')
    const inputRef = useRef()

    const isSearchLocation = location.pathname.endsWith('/search') || location.pathname.endsWith('/search/')
    const minSearchLength = 3

  
    const transition = useTransition(active, {
        from: { translateY: '-50%', opacity: 0 },
        enter: { translateY: '0', opacity: 1 },
        leave: { translateY: '-70%', delay: 0, opacity: 0 },
        config: config.default //{ mass: 0.2, tension: 350, friction: 16 } //{ duration: 600 }// config.molasses,
    })

    useEffect(() => {
        if (active)
            inputRef.current.focus()
    }, [active])

    useEffect(() => {
        if (isSearchLocation)
            setSearch(searchParams.get('s') ?? '')
    }, [searchParams])


    useEffect(() => {
        if (isSearchLocation)
            updateSearchLocation()
    }, [search])

    const updateSearchLocation = () => {
        let url = '/visit/search'
        url += '?' + createSearchParams({ s: search ?? '' })
        navigate(url, { replace: true })
    }

    const navigateToSearch = () => {
        let url = '/visit/search'
        url += '?' + createSearchParams({ s: search ?? '', r: searchParams.get('a') })
        navigate(url)
    }

    const handleChange = (e) => {
        setSearch(e.target.value)
    }

    const handleClear = () => {
        setSearch('')
        inputRef?.current.focus()
    }

    const handleGoClick = () => {
        navigateToSearch(search)
    }

    return (
        <>
            {transition((style, item) => item &&
                <Layout style={style}>
                    <SearchTextField placeholder={intl.get("Search.placeHolder")} inputRef={inputRef} accent={1} onChange={handleChange} value={search} onClear={handleClear}>
                        {!isSearchLocation && search?.length >= minSearchLength &&
                            <GoIconButton onClick={handleGoClick} />}
                        {(isSearchLocation || (search?.length < minSearchLength)) && <SearchIcon />}
                    </SearchTextField>
                </Layout>
            )}
        </>
    )
}

const Layout = styled(animated.div)(props => props.theme.sx({
    position: 'fixed',
    zIndex: 3,
    top: '3.65rem',
    height: '6.25rem',
    backgroundColor: 'primary.surface',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
    [props.theme.breakpoints.up('tablet')]: {
        backgroundColor: 'transparent',
        width: '22.75rem',
        padding: '0',
        left: '1rem',
        top: '4.65rem',
        height: 'auto'
    },
}))


const SearchTextField = styled(TextField)(props => props.theme.sx({
    'input': {
        backgroundColor: 'primary.surface',
        color: 'secondary.main',
    },
    transition: 'all 0.4s',
    paddingLeft: '3.25rem',
    flexGrow: 1,
    position: 'relative',
}))


const GoIconButton = styled(IconButtons.Arrow)(props => props.theme.sx({
    position: 'absolute',
    left: '1rem',
    top: '0.37rem',
    padding: '0',
    '> svg': {
        fill: props.theme.palette.secondary.main,
    }
}))

const SearchIcon = styled(Icons.SearchLocate)(props => props.theme.sx({
    position: 'absolute',
    left: '1rem',
    top: '0.37rem',
    padding: '0',
    fill: props.theme.palette.secondary.main,
}))