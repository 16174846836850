import styled from '@emotion/styled/macro';
import { animated } from '@react-spring/web';
import { useEffect, useRef, useState } from 'react';
import { QrReader as ReactQrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';
import { Slider, SliderSlide } from './components/components';
import { ButtonBase } from '@mui/material';
import { useToggle } from 'react-use';
import { useIntl } from './hooks';

export default function QrReader({ open, onClose }) {
    const [hasInitiatedDevices, setHasInitiatedDevices] = useState(false)
    const navigate = useNavigate()
    const { intl } = useIntl()

    const _allowedHashPrefix = "WAGE"
    const handleScanResult = (result) => {
        onClose()
        if (result?.text) {
            var a = document.createElement('a');
            a.href = result.text;
            var hash = a.hash
            if (hash && hash.toUpperCase().startsWith(("#" + _allowedHashPrefix).toUpperCase())) {
                var path = hash.substring(("#" + _allowedHashPrefix).length)
                navigate(path, { replace: true })
            }
        }
    }

    const [devices, setDevices] = useState([])
    const deviceIdLocalStorageKey = "qrScannerdDeviceIdLocalStorageKey"
    const [deviceId, setDeviceId] = useState(localStorage.getItem(deviceIdLocalStorageKey))
    const [scan, toggleScan] = useToggle(true)

    useEffect(() => {


        if (navigator
            && navigator.mediaDevices
            && typeof navigator.mediaDevices.enumerateDevices === 'function') {

            try {
                navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((stream) => { 
                    navigator.mediaDevices.enumerateDevices().then((devices) => {
                        let _devices = []
                        
                        devices.forEach((device) => {
                            if (device.kind === 'videoinput') {
                                _devices.push(device)
                            }
                        })
                        
                        let deviceIndex = devices?.findIndex(d => d.deviceId === deviceId) 
                        
                        if (deviceId &&  deviceIndex < -1)
                        {
                            setDeviceId(null)
                            localStorage.removeItem(deviceIdLocalStorageKey)
                        }
                        
                        if (!deviceId && _devices.length > 0) {
                            let _device = _devices[0]
                            localStorage.setItem(deviceIdLocalStorageKey, _device.deviceId)
                            setDeviceId(_device.deviceId)
                        }
                        setDevices(_devices)
                    })
                }).catch(error => {
                    if (false && process.env.NODE_ENV === 'development') {
                        setDevices([
                            { deviceId: '1', label: 'camera 1 facing back' },
                            { deviceId: '2', label: 'camera 2 facing back' },
                            { deviceId: '3', label: 'camera 3 facing back' },
                            { deviceId: '4', label: 'camera 4 facing back' },
                            { deviceId: '5', label: 'camera 5 facing back' },
                        ]) 
                    }
                    else
                        setDevices([])
                }) 
            } catch (error) { 
                setDevices([])
            }
        }
        setTimeout(() => {
            setHasInitiatedDevices(true)
        }, 1500)
    }, [])


    const handleSelectDevice = (device) => (e) => {
        localStorage.setItem(deviceIdLocalStorageKey, device.deviceId)
        toggleScan(false)
        setDeviceId(device.deviceId)
        setTimeout(() => {
            toggleScan(true)
        }, 10)
    }
    const deviceIndex = devices?.findIndex(d => d.deviceId === deviceId)??0
    return (
        <Layout>
            {/* <Infos># camears: {devices?.length ?? -1}</Infos> */}
            {devices && devices?.length > 1 &&
                <Devices slidesPerView={'auto'} centeredSlides={false} speed={150} disableNavigation={true} disablePagination={true} index={deviceIndex}>
                    {devices.map((device, index) => {
                        return (
                            <Device key={index} onClick={handleSelectDevice(device)}>
                                <DeviceContent active={(deviceId === device.deviceId)} >
                                    {intl.get('QRCodeScanner.Camera')} #{index + 1}
                                </DeviceContent>
                            </Device>
                        )
                    })}
                </Devices>
            }
            {hasInitiatedDevices && devices?.length < 1 &&
                <NoDeviceError dangerouslySetInnerHTML={{ __html: intl.get('QRCodeScanner.NoCamera') }}></NoDeviceError>
            }

                <QrReaderContainer>
            { scan && devices && devices.length > 0 &&
                <ReactQrReaderLayout constraints={{ deviceId: deviceId }} scanDelay={200} onResult={(result, error) => {

                    if (!!result) {
                        handleScanResult(result)
                    }
                    if (!!error) {
                    }
                }} />
            }
            </QrReaderContainer>
        </Layout>
    )
}                                                                                                                                                   


const Infos = styled('div')({
    color: 'white',
    position: 'absolute',
    top: '10px',
    left: '10px'
})

const Layout = styled(animated.div)(props => ({
    marginTop: '4rem',
    width: '100%',
    height: 'calc(100% - 4rem)',
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    maxHeight: '100%',
    overflow:  'hidden'
}))
                               
const NoDeviceError = styled('div')({
    color: 'white',
    padding: '0 2rem',
    textAlign: 'center',
    fontSize: '1.15rem',    
    marginTop: '35%'
})

const Devices = styled(Slider)(props => props.theme.sx({
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    padding: '1.25rem 0.5rem',
    zIndex: 4,
    backgroundColor: 'transparent',
    flexShrink: 0,
    flexGrow: 0,
    order: 2,
    marginBottom: '2rem',
    marginTop: 'auto',
}))


const Device = styled(SliderSlide)(props => props.theme.sx({
    width: 'auto',
    height: '2.325rem',
    margin: '0 0.15625rem',
    overflowY: 'overlay'
}))
Device.displayName = SliderSlide.displayName


const DeviceContent = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== "active"
})(props => props.theme.sx({
    width: 'auto',
    display: 'flex',
    backgroundColor: 'primary.surface',
    alignItems: 'center',
    height: '2.125rem',
    transition: 'all 1s',
    fontSize: '0.8125rem',
    color: 'secondary.main',
    borderRadius: '1.25rem',
    '> span': {
        paddingLeft: '0.4375rem'
    },
    'svg': {
        width: '1.125rem',
        fill: (theme) => theme.palette.secondary.main
    },
    border: 'solid 1px',
    borderColor: 'primary.main',
    ...(props.active && {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        'svg': {
            width: '1.125rem',
            fill: (theme) => theme.palette.primary.surface
        }
    }),
    ...(props.audio && {
        flexGrow: 0,
        padding: '0 1.5rem',
        ...(props.active && {
            'svg': {
                fill: 'white'
            },
            backgroundColor: '#C1A3FF'

        })
    }),
    padding: '0 0.75rem'
}))

const QrReaderContainer = styled('div')({
    order: 1,
    width: 'min(100vw, calc(100vh - 15rem))',
    overflow: 'visible',
    position: 'relative',
    '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%'
    }
})

const ReactQrReaderLayout = styled(ReactQrReader)({
    width: '100%',
    height: '100%',
    position: 'absolute',
})