import styled from '@emotion/styled/macro';
import { animated, useSpring } from '@react-spring/web';


export function useDisplayStyle(scale, scaleThreshold) {

    const [tagStyle, tagStyleApi] = useSpring(() => ({
        opacity: scale > scaleThreshold ? 1 : 0,
        config: { duration: 400 }
    }))


    const setScale = (scale) => {
        tagStyleApi.start({
            opacity: scale > scaleThreshold ? 1 : 0,
        })
    }

    return { styles: { svgTag: tagStyle }, setScale }
}

export default function DisplayTag({ active, tag, tagKey, tagStyles }) {
    return (
        <Layout active={active ? 1 : 0} rotate={-1 * (tag.Rotate ?? 0)} style={tagStyles.svgTag} >
            <SvgTag fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.7 37.9">
                <g>
                    <path d="M18.8,0C12.2,0,6.9,5.3,6.9,11.9c0,1.5,0.2,3,0.8,4.5L8.5,18v0.1l4.2,8.1l6.1,11.7l5.9-11.6l4.2-8.2 c0.8-1.5,1.8-3.1,1.8-6.1C30.7,5.3,25.4,0,18.8,0z" />
                    <path className="st0" d="M18.9,22c-5.6,0-10.1-4.5-10.1-10.1S13.3,1.8,18.9,1.8S29,6.3,29,11.9S24.4,22,18.9,22z M18.9,3 C14,3,10,7,10,11.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S23.8,3,18.9,3z" />
                </g>
            </SvgTag>
            <TagKey>{tagKey}</TagKey>
        </Layout>
    )
}

const Layout = styled(animated.div)(props => props.theme.sx({
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: 0,
    position: 'relative',
    '&:hover': {
        cursor: 'pointer',
    },
    padding: '1vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [TagKey]: {
        transition: 'all 0.1s',
        transform: 'rotate(' + props.rotate + 'deg)',
        color: 'secondary.contrastText'
    },
    ...(props.active && {
        [SvgTag]: {
            transition: 'all 0.4s',
            fill: (theme) => theme.palette.secondary.contrastText,
            '.st0': {
                fill: (theme) => theme.palette.secondary.main
            }
        },
        [TagKey]: {
            transform: 'rotate(' + props.rotate + 'deg)',
            transition: 'all 0.1s',
            color: 'secondary.main'
        }
    }),
    transform: 'translateY(calc(-50% + 1vw))',
}))

const TagKey = styled('span')(props => props.theme.sx({
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    top: '3.2vw',
    fontSize: '1.7vw',
    zIndex: 10,
    transition: 'all 0.1s',
    [props.theme.breakpoints.up('tablet')]: {
        fontSize: '1.6vw',
        top: '3.1vw',        
    },
    [props.theme.breakpoints.up('laptop')]: {
        fontSize: '1.55vw',
        top: '2.98vw',
    },
    [props.theme.breakpoints.up('desktop')]: {
        fontSize: '1.5vw',
        top: '2.85vw',
    },
    [props.theme.breakpoints.up('largeDesktop')]: {
        fontSize: '1.3vw',
        top: '2.65vw',
   }
}))

const SvgTag = styled('svg')(props => props.theme.sx({
    width: '11vw',
    height: '11vw',
    transition: 'all 0.4s',
    zIndex: 10,
    fill: (theme) => theme.palette.secondary.main,
    '.st0': {
        fill: 'white'
    },
    pointerEvents: 'initial',
    '&:hover': {
        cursor: 'pointer',
    },
    [props.theme.breakpoints.up('tablet')]: {
        width: '10.5vw',
        height: '10.5vw',
    },
    [props.theme.breakpoints.up('laptop')]: {
        width: '10vw',
        height: '10vw',
    },
    [props.theme.breakpoints.up('desktop')]: {
         width: '9.4vw',
         height: '9.4vw',
    },
    [props.theme.breakpoints.up('largeDesktop')]: {
        width: '8.2vw',
        height: '8.2vw',
   }
}))