import styled from '@emotion/styled/macro';
import { Portal } from '@mui/material';
import { animated, config, useTransition } from '@react-spring/web';
import IconButtons from './IconButtons';

export default function Modal({ open, onClose, children, transition, className }) {

    const transitionStyles = transition === "left" ? {
        from: { opacity: 0, translateX: '100%' },
        enter: { opacity: 1, translateX: '0%' },
        leave: { opacity: 0, translateX: '100%' },
    } : {
        from: { opacity: 0.2, scale: 0.4, translateY: '100vh' },
        enter: { opacity: 1, scale: 1, translateY: '0%' },
        leave: { opacity: 0, scale: 0.8, translateY: '100vh' },
        delay: 0,
    }

    const layoutTransition = useTransition(open, {
        ...transitionStyles,
        delay: 0,
        //config: {duration: 400}
        config: config.default,
    })
    const closeIconTransition = useTransition(open, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        delay: 0,
        config: {duration: 100}
    })

    return (
        <Portal>
            {layoutTransition((style, item) => item &&
                <Layout className={className} style={style}>
                    {closeIconTransition((style, item) => item &&
                        <CloseIconButton  onClick={() => onClose()} />
                    )}
                    {children}
                </Layout>
            )}
        </Portal>

    )
}

const Layout = styled(animated.div)({
    top: 0,
    left: 0,
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.85)'
})

const CloseIconButton = styled(IconButtons.Cross)(props => props.theme.sx({
    position: 'absolute',
    zIndex: 21,
    top: '1rem',
    right: '1rem',
    width: '2.37rem',
    '> svg': {
        fill: (theme) => theme.palette.primary.contrastText
    }
}))