import styled from '@emotion/styled/macro';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import { animated, config, useTransition } from '@react-spring/web';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import BaseFloor from './BaseFloor';
import IconButtons from './components/IconButtons';
import { useFloorPathes } from './hooks';
import Tag from './Tag';

const ForwardedFloor = forwardRef(Floor)
export default ForwardedFloor

function Floor({ floor }, ref) {

    const baseFloorRef = useRef(null)
    const focusEntityRef = useRef()

    const [altTag, setAltTag] = useState(null)
    const [altTagOpen, setAltTagOpen] = useState(false)
    const [altTagAnchor, setAltTagAnchor] = useState(null);

    const { findPathes } = useFloorPathes(floor)
    const [selectFromPath, setSelectFromPath] = useState(null)
    const [fromPath, setFromPath] = useState(null)

    const focusTimoutRef = useRef()
    const [journeyPath, setJourneyPath] = useState(null)

    const handlersRef = useRef()

    const toggleAltTag = (type, key, e) => {
        if (type && key && e) {
            if (altTag?.key !== key || !altTagOpen) {
                setAltTagAnchor(e.currentTarget)
                setAltTag({ key: key, type: type })
                setAltTagOpen(true)
            } else
                setAltTagOpen(false)
        }
        else
            setAltTagOpen(false)
    }

    useImperativeHandle(ref, () => ({
        setAltTag: (type, key, e) => {
            toggleAltTag(type, key, e)
        },
        locate: (tagKey) => {
            baseFloorRef.current?.focusTag(tagKey, { target: focusEntityRef.current })
        },
        focus: (tagKey) => {
            baseFloorRef.current?.focusTag(tagKey, { target: focusEntityRef.current })
        },
        selectFromPath: (tagKey) => {
            baseFloorRef.current?.focusTag(tagKey, { center: true  })
            setSelectFromPath(tagKey)
        },
        setJourneyPath: (path, node) => {
            setJourneyPath(path)
            baseFloorRef.current?.setJourneyStepNode(node)
        },
        setHandlers: (handlers) => {
            handlersRef.current = handlers
        },
        setActiveTag: (key) => {
            baseFloorRef.current?.setActiveTag(key)
        },
        clearFromPath: () => {
            baseFloorRef.current?.setPath(true, null)
            setSelectFromPath(false)
            setFromPath(null)
        }, 
        recenter: () => {
            baseFloorRef.current.center()
        }
    }))

    const onInteractionStart = (i) => {
        toggleAltTag()
    }

    const onLayersClick = (e) => {
        if (selectFromPath) {
            const fromTag = baseFloorRef.current?.findTagAt(e.clientX, e.clientY)
            const fromNode = floor.Tags[fromTag]?.Node
            const toNode = floor.Tags[selectFromPath]?.Node

            const path = findPathes(fromNode, toNode)?.[0]

            baseFloorRef.current?.setPath(true, path)
            setTimeout(() => {
                baseFloorRef.current?.centerPath(path, 4)
            }, 10)
            if (focusTimoutRef.current)
                clearTimeout(focusTimoutRef.current)


            setFromPath(path)
        }
        else if (altTagOpen) {
            toggleAltTag()
        }
        else
            handlersRef.current?.onClick && handlersRef.current?.onClick()
    }

    const onTagClick = (type, key, e) => {
        if (selectFromPath)
            return false

        if (altTagOpen)
            toggleAltTag()
        else if (handlersRef.current?.onSelectTag) {
                handlersRef.current?.onSelectTag(type, key, e)
            }

    }

    const handleAltTagClick = (e) => {
        handlersRef.current?.onSelectAltTag && handlersRef.current?.onSelectAltTag(altTag.type, altTag.key, e)
        toggleAltTag()
    }

    const handleClearPath = (e) => {
        baseFloorRef.current?.setPath(true, null)
        setSelectFromPath(false)
        setFromPath(null)
        handlersRef.current?.onClearPath()
    }

    const contextLayoutTransition = useTransition(selectFromPath, {
        from: { opacity: 0, translateY: '100%' },
        enter: { opacity: 1, translateY: '0%' },
        leave: { opacity: 0, translateY: '100%' },
        delay: 0,
        config: config.stiff,
    })

    return (
        <Layout>
            {floor &&
                <>
                    <BaseFloor
                        cursor={selectFromPath ? 'selection' : null} ref={baseFloorRef} floor={floor}
                        journeyPath={journeyPath}
                        handlers={{
                            onInteractionStart: onInteractionStart,
                            onTagClick: onTagClick,
                            onLayersClick: onLayersClick
                        }} />

                    <FocusEntity ref={focusEntityRef} />

                    <AltTag
                        id={altTagOpen && altTagAnchor ? 'transition-popper' : undefined}
                        open={altTagOpen}
                        anchorEl={altTagAnchor}
                        placement="top"
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={600}>
                                <AltTagLayout tagType={altTag?.type} >
                                    <Tag onSelect={handleAltTagClick} altTag tagType={altTag?.type} tagKey={altTag?.key} />
                                </AltTagLayout>
                            </Fade>
                        )}
                    </AltTag>

                    {contextLayoutTransition((style, item) => item &&
                        <ContextLayout style={style}>

                            {selectFromPath && !fromPath &&
                                <Context>
                                    Click on the map to select your current location<IconButtons.Cross size="medium" onClick={handleClearPath}></IconButtons.Cross>
                                </Context>
                            }
                            {selectFromPath && fromPath &&
                                <Context>
                                    Clear itinerary<IconButtons.Cross size="medium" onClick={handleClearPath}></IconButtons.Cross>
                                </Context>
                            }
                        </ContextLayout>
                    )}
                </>
            }
        </Layout>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'relative',
}))

const FocusEntity = styled('div')(props => props.theme.sx({
    //visibility: 'hidden',
    position: 'absolute',
    pointerEvents: 'none',
    top: '35%',
    left: '50%',
    width: '3px',
    height: '2px',
    // border: 'solid 4px green',
    [props.theme.breakpoints.up('tablet')]: {
        top: '50%',
        left: '75%',
    },
    [props.theme.breakpoints.up('desktop')]: {
        top: '50%',
        left: '70%',
    }
}))


const AltTag = styled(Popper)(props => props.theme.sx({
    zIndex: 1,
}))

const AltTagLayout = styled(animated.div)(props => props.theme.sx({
    width: '80vw',
    maxWidth: '1200px',
    // height: '200px',
    backgroundColor: 'rgba(54, 31, 99, 0.7)',
    maxHeight: '200px',
    backfaceVisibility: 'hidden',
    transform: props.tagType === 'display' ? 'translateY(10%)' : 'translateY(-10%)',

}))

const ContextLayout = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    bottom: 0,
    width: '100%',
    // maxWidth: '700px',
    height: '10vh',
    backgroundColor: 'rgba(14, 8, 25, 0.5)',
    pointerEvents: 'none',
    zIndex: 1,
    left: 0,
    right: 0,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem'
}))

const Context = styled(animated.div)(props => props.theme.sx({
    color: 'white',
    pointerEvents: 'initial'
}))