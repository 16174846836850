import styled from '@emotion/styled/macro';
import { animated, useSpring } from '@react-spring/web';
import { useIntl } from '../hooks';

const pathes = [
    "M 50 0 C -16 0 -16 100 52 100 M 48 100 C 116 100 116 0 50 0",
    "M 50 0 C 0 0 -31 22 52 100 M 48 100 C 131 22 100 0 50 0",
]
const fills = ["rgba(255, 255, 255, 0.3)", "rgba(80, 44, 133, 0.7)", "rgba(80, 44, 133, 0.2)"]
const textYs = [60, 55]

export function usePOIStyle(scale) {
    const [svgStyles, svgStylesApi] = useSpring(() => ({
        path: pathes[scale > 1 ? 1 : 0],
        textY: textYs[scale > 1 ? 1 : 0],
        config: { duration: 1500 }
    }))


    const [layoutStyles, defaultLayoutStylesApi] = useSpring(() => ({
        scale: scale,
        config: { duration: 500 }
    }))

    const setScale = (scale) => {
        svgStylesApi.start({
            path: pathes[scale > 1 ? 1 : 0],
            textY: textYs[scale > 1 ? 1 : 0],
        })

        let _scale = scale
        _scale = scale > 1 ? 1.2 / Math.pow(scale, 0.1) : 1.1 * scale

        defaultLayoutStylesApi.start({ scale: _scale })
    }

    return { styles: { svg: svgStyles, layout: layoutStyles }, setScale }
}

export default
    function POITag({ active, tag, scale, tagStyles }) {
    const { locale } = useIntl()


    return (
        <Layout>
            <SvgTag fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-5 -5 110 110"
                style={tagStyles.layout}>
                <g>
                    <animated.path stroke="rgba(45, 19, 84, 0.2)" fill={fills[active ? 1 : 0]} strokeWidth="3" d={tagStyles.svg.path}></animated.path>
                </g>
                {/* <g>
                    <animated.text x="50" y={tagStyles.svg.textY} fontSize='30px' fill="white" textAnchor="middle">{tag?.ShortName[locale]}</animated.text>
                </g> */}
            </SvgTag>
        </Layout>
    )
}

const Layout = styled('div')(props => ({
    zIndex: 2,
    width: 'auto',
    height: 'auto',
    transform: 'translate(-50%, -50%)',

}))

const SvgTag = styled(animated.svg)(props => ({
    width: '6vw',
    height: '6vw',
    transformOrigin: '50% 100%',
    '&:hover': {
        cursor: 'pointer',
        path: {
            fill: fills[2]
        }
    },
    path: { transition: 'fill 1s ease' },
    zIndex: 10
}))
