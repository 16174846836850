import { useTheme } from '@mui/material/styles';
import useMuiMediaQuery from '@mui/material/useMediaQuery';
import { config, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import FlexSearch from 'flexsearch';
import { encode } from "flexsearch/dist/module/lang/latin/simple.js";
import { useCallback, useEffect, useRef, useState } from 'react';
import intl from 'react-intl-universal';
import { useNavigationType as useRouterNavigationType, useOutletContext } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { atom, atomFamily, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useApi } from '../core/api';
import locales, { activeLocales, defaultLocale } from './locales';

export function useDisableIosSwipe(ref) {
    const EDGE_THRESHOLD = 10

    const handleTouchStart = (event) => {
        if (event.pageX === undefined || event.pageX === null
            || (event.pageX > EDGE_THRESHOLD
                && event.pageX < window.innerWidth - EDGE_THRESHOLD)
        ) {
            return true;
        }
        event.preventDefault(); // This only sometimes works, and the event never appears as if the default has been prevented after calling this. (Internal property is never updated.)
        return false // I don't know if this does anything or not.
    }

    useEffect(() => {
        ref.current?.addEventListener('touchstart', handleTouchStart, {passive: false})
        return () => {
            ref.current?.addEventListener('touchstart', handleTouchStart)
        }
    }, [])
}

/* #region router */
export function useNavigationType() {

    const routerNavigationType = useRouterNavigationType()
    /*
    const currentStateIdxRef = useRef()

    const [navigationType, setNavigationType] = useState()

    const onPopStateHandler = (event) => {
        console.info(routerNavigationType)
       
        if (currentStateIdxRef.current < event.state.idx) {
            setNavigationType('PUSH')
        }
        else // if (navigationType !== 'POP')
            setNavigationType(routerNavigationType)

        currentStateIdxRef.current = event.state.idx
    }

    useEffect(() => {
        window.onpopstate = onPopStateHandler
        return () => {
            window.onpopstate = null
        }
    }, [])
*/
    return routerNavigationType // navigationType === 'PUSH' && routerNavigationType === 'POP' ? 'PUSH' : routerNavigationType
}

/* #endregion */

/* #region helmet */
const _currentHelmetAtomFamily = atomFamily({
    key: "_currentHelmetAtomFamily",
    default: null
})

export function useHelmet(meta) {
    const [values, setValues] = useRecoilState(_currentHelmetAtomFamily(meta))

    const updateValues = () => {
        var v = Array.from(document.querySelectorAll("meta[name=" + meta + "]") ?? []).map(m => m.getAttribute('value'))
        setValues(v)
    }

    useEffect(() => {
        updateValues()
        const observer = new MutationObserver(() => {
            // set a timeout let all rendering happen before
            setTimeout(() => {
                updateValues()
            }, 10)
        })
        observer.observe(document.head, { childList: true })
        return () => observer.disconnect()
    }, [])

    return { values }
}

/* #endregion */

/* #region media query theme */

const _currentMediaQueryKeyAtom = atom({
    key: "_mediaQueryCurrentKeyAtom",
    default: null
})

export function useMediaQuery() {
    const theme = useTheme()
    const currentMediaQueryKey = useRecoilValue(_currentMediaQueryKeyAtom)

    const matchKey = (value) => {
        let result = null

        if (value[currentMediaQueryKey])
            result = value[currentMediaQueryKey]
        else {
            const currentKeyIndex = theme.breakpoints.keys.indexOf(currentMediaQueryKey)
            for (let i = currentKeyIndex; i >= 0 && !result; i--)
                result = value[theme.breakpoints.keys[i]]
            if (!result) {
                for (let i = currentKeyIndex; i < theme.breakpoints.keys.length && !result; i++)
                    result = value[theme.breakpoints.keys[i]]
            }
        }
        return result
    }

    const is = {
        up: (key) => {
            const keyIndex = theme.breakpoints.keys.indexOf(key)
            const currentKeyIndex = theme.breakpoints.keys.indexOf(currentMediaQueryKey)
            return currentKeyIndex >= keyIndex
        },
        down: (key) => {
            const keyIndex = theme.breakpoints.keys.indexOf(key)
            const currentKeyIndex = theme.breakpoints.keys.indexOf(currentMediaQueryKey)
            return currentKeyIndex < keyIndex
        },
        only: (key) => {
            const keyIndex = theme.breakpoints.keys.indexOf(key)
            const currentKeyIndex = theme.breakpoints.keys.indexOf(currentMediaQueryKey)
            return keyIndex === currentKeyIndex
        }
    }

    return { keys: theme.breakpoints.keys, is, currentKey: currentMediaQueryKey, matchKey: matchKey, mediaQuery: useMuiMediaQuery }
}

export function useMediaQueryObserver() {
    const setCurrentMediaQueryKey = useSetRecoilState(_currentMediaQueryKeyAtom)
    const { width } = useWindowSize()
    const theme = useTheme()

    useEffect(() => {
        const keys = [...theme.breakpoints.keys]
        keys.reverse()
        const currentKey = keys.find(k => {
            return theme.breakpoints.values[k] <= width
        })
        setCurrentMediaQueryKey(currentKey)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width])
}

/* #endregion */

/* #region configuration */
const _defaultConfig = () => {
    const configKey = process.env.NODE_ENV
    const defaultKey = "default"

    return {
        backgroundsUri: config[configKey]?.backgroundsUri ?? config[defaultKey]?.backgroundsUri
    }
}

const configAtom = atom({
    key: "configAtom",
    default: _defaultConfig()
})

/* #endregion */

/* #region locales */
const _localeLocalStorageKey = '_localeLocalStorageKey'
const localesCodes = activeLocales

const _initLocale = (locale, callback) => {
    intl.init({
        currentLocale: locale,
        fallbackLocale: "en",
        locales,
    }).then(() => {
        localStorage.setItem(_localeLocalStorageKey, locale)
        if (callback)
            callback()
    })
}

const _defaultLocale = () => {
    let locale = localStorage.getItem(_localeLocalStorageKey);

    if (!locale)
        locale = navigator.language.slice(0, 2)
    if (localesCodes.length > 0 && !localesCodes.includes(locale))
        locale = localesCodes[0]

    _initLocale(locale)
    return locale
}

export const localeAtom = atom({
    key: 'localeAtom',
    default: _defaultLocale()
})

export function useIntl() {
    const [locale, _setLocale] = useRecoilState(localeAtom)

    const setLocale = (locale) => {
        let _locale = (locale && localesCodes.includes(locale)) ? locale : _defaultLocale()

        _initLocale(_locale, () => _setLocale(_locale))
    }

    return { locale, locales: localesCodes, defaultLocale: defaultLocale, setLocale, intl: intl }
}

/* #endregion */

/* #region use *data* (map, rooms...) */
const _jsonDataStateAtomFamily = atomFamily({
    key: '_jsonDataStateAtomFamily',
    default: {
        value: null,
        loading: false,
        error: null,
        success: false,
        progress: 0,
    }
})

const _loadingJsonDataStateAtomFamily = atomFamily({
    key: '_loadingJsonDataStateAtomFamily',
    default: false
})

const loadingJson = {}
// export function useJsonDataDev(key, apiUrl, load, reduceData) {
//     const [jsonDataState, setJsonDataState] = useRecoilState(_jsonDataStateAtomFamily(key))

//     const onData = useCallback((e) => {
//         setJsonDataState({
//             value: reduceData ? reduceData(e) : e,
//             loading: false,
//             error: null,
//             success: true,
//             progress: 100
//         })
//         loadingJson[key] = false
//     }, [key, setJsonDataState])

//     const call = async () => {
//         const data = await import('../data/' + apiUrl.replace('/data/', ''))
//         onData(data.default)
//     }


//     useEffect(() => {
//   //      if (load && !jsonDataState.success && !loadingJson[key])
//             call()
//     }, [load, jsonDataState.success])

//     return { [key]: jsonDataState.value, ...jsonDataState, call: call }
// }

export function useJsonData(key, apiUrl, load, reduceData) {
    const dev = process.env.NODE_ENV === 'development'

    const [jsonDataState, setJsonDataState] = useRecoilState(_jsonDataStateAtomFamily(key))
    const onData = useCallback((e) => {
        setJsonDataState({
            value: reduceData ? reduceData(e) : e,
            loading: false,
            error: null,
            success: true,
            progress: 100
        })
        loadingJson[key] = false
    }, [key, setJsonDataState])

    const onError = (error) => {

        setJsonDataState({
            value: null,
            loading: false,
            error: error,
            success: false,
            progress: 100
        })
        loadingJson[key] = false
    }
    const { call } = useApi(apiUrl, 'get', onData, onError);

    const callDev = async () => {
        const data = await import('../data/' + apiUrl.replace('/data/', ''))
        onData(data.default)
    }
    useEffect(() => {
        if (true || dev)
            callDev()
        else
            if (load && !jsonDataState.success && !loadingJson[key])
                call()
    }, [load, jsonDataState.success])

    return { [key]: jsonDataState.value, ...jsonDataState, call }
}

/* #endregion */

/* #region map */
export function useMap(load) {
    const reduceData = (map) => {
        return map
    }

    return useJsonData('map', '/data/map.json', load, reduceData)
}

/* #endregion */

/* #region rooms */
export function useRooms(load) {
    return useJsonData('rooms', '/data/rooms.json', load)
}
/* #endregion */

/* #region displays */
export function useDisplays(load) {
    return useJsonData('displays', '/data/displays.json', load)
}
/* #endregion */

/* #region articles */
export function useArticles(load) {
    return useJsonData('articles', '/data/articles.json', load)
}


/* #endregion */


/* #region calibers */
export function useCalibers(load) {
    return useJsonData('calibers', '/data/calibers.json', load)
}


/* #endregion */

/* #region poi */
export function usePOIs(load) {
    return useJsonData('POIs', '/data/pois.json', load)
}
/* #endregion */


/* #region search */

export const _searchDataIndexAtom = atom({
    key: '_searchDataIndexAtom',
    default: {}
})

export function useSearchData() {
    const { locales } = useIntl()
    const setSearchDataIndex = useSetRecoilState(_searchDataIndexAtom)

    const buildIndex = (rooms, displays, articles, calibers) => {
        const searchDataIndex = {}
        locales.forEach(locale => {
            const searchDocument = new FlexSearch.Document({
                encode: encode, // str => locale === 'ja' ? str.replace(/[\x00-\x7F]/g, "").split("") : encode,
                document: {
                    id: "key",
                    tag: "tag",
                    index: [
                        {
                            field: "full",
                            tokenize: "forward",
                            optimize: true,
                            resolution: 3,
                            minlength: 3,
                        }]
                }
            })

            // add rooms
            let entries = Object.keys(rooms).map((k) => {
                let full = [k, rooms[k].Name[locale], rooms[k].Description[locale]]
                return { key: k, tag: 'Room', full: full }
            })
            entries.forEach((entry) => {
                searchDocument?.add(entry)
            })

            // add displays
            entries = Object.keys(displays).map((k) => {
                let full = [k, displays[k].Name[locale], displays[k].Description[locale]]
                return { key: k, tag: 'Display', full: full }
            })
            entries.forEach((entry) => {
                searchDocument.add(entry)
            })

            // add articles
            entries = Object.keys(articles).map((k) => {
                let full = [k, ...articles[k].Title?.[locale], ...articles[k].Collection?.[locale], ...articles[k].Main?.[locale]]

                return { key: k, tag: 'Article', full: full }
            })
            entries.forEach((entry) => {
                searchDocument.add(entry)
            })

            // add calibers
            entries = Object.keys(calibers).map((k) => {
                let full = [k, ...calibers[k].Main?.[locale]]
                return { key: k, tag: 'Caliber', full: full }
            })
            entries.forEach((entry) => {
                searchDocument.add(entry)
            })

            searchDataIndex[locale] = searchDocument
        })
        setSearchDataIndex(searchDataIndex)
    }

    return { buildIndex }
}

/* #endregion */

/* #region preload */
export function usePreloadData() {
    const datas = {}
    datas["map"] = useMap(true)
    datas["rooms"] = useRooms(true)
    datas["displays"] = useDisplays(true)
    datas["articles"] = useArticles(true)
    datas["calibers"] = useCalibers(true)
    datas["journeys"] = useJourneys(true)
    datas["pois"] = usePOIs(true)

    const result = Object.keys(datas).reduce((acc, k) => {
        acc.loading ||= datas[k].loading
        acc.errors[k] = datas[k].error
        acc.success &&= datas[k].success
        acc.progress += datas[k].progress / 4

        return acc
    }, { loading: false, errors: {}, success: true, progress: 0 })
    result.loading &&= (!result.success || !result.errors)

    return result
}

export function usePreloadSearch() {
    const { rooms } = useRooms()
    const { displays } = useDisplays()
    const { articles } = useArticles()
    const { calibers } = useCalibers()

    const { buildIndex } = useSearchData()
    const [preloaded, setPreloaded] = useState(false)

    useEffect(() => {
        if (rooms && articles && displays && calibers) {
            buildIndex(rooms, displays, articles, calibers)
            setPreloaded(true)
        }
    }, [rooms, displays, articles, calibers])

    return preloaded
}

/* #endregion */

/* #region journeys */
export function useJourneys(load) {
    return useJsonData('journeys', '/data/journeys.json', load)
}

const _journeysTagsLocalStorageKey = '_journeysTagsLocalStorageKey'
const _defaultJourneysTags = () => {
    const e = localStorage.getItem(_journeysTagsLocalStorageKey);
    if (e)
        return JSON.parse(e)
    return {}
}

export const _journeysTagsAtom = atom({
    key: "journeysTagsAtom",
    default: _defaultJourneysTags()
})


export function useJourney(journeyKey) {
    const { map } = useMap()
    const { journeys } = useJourneys()
    const { tagsFromKeys } = useTagsExtension()

    const journey = journeys?.[journeyKey]
    const floor = map?.Floors[journey?.Floor]
    const { findPathes } = useFloorPathes(floor)

    const tags = journey ? tagsFromKeys(floor, journey.Steps) : {}

    const journeyNodes = Object.values(tags ?? {}).filter(m => m.Node).map(m => m.Node)

    const pathes = journeyNodes?.length > 1 ?
        findPathes(journeyNodes[0], journeyNodes[journeyNodes.length - 1], journeyNodes.slice(1, journeyNodes.length - 2)) : []

    const [journeysTags, setJourneysTags] = useRecoilState(_journeysTagsAtom)
    const currentTag = journeysTags?.[journeyKey]

    const setCurrentTag = (key, type) => {
        const tagIndex = journey.Steps.indexOf(key)
        if (tagIndex > -1) {
            const _journeysTags = { ...journeysTags, [journeyKey]: { key: key, type: type } }
            localStorage.setItem(_journeysTagsLocalStorageKey, JSON.stringify(_journeysTags))
            setJourneysTags(_journeysTags)
        }
    }

    return { journey, tags, currentTag, setCurrentTag, floor, path: pathes.length > 0 ? pathes[0] : [] }
}


/* #endregion */

/* #region tags extensions */

export function useTagsExtension() {
    const { articles } = useArticles()
    const { calibers } = useCalibers()

    const { displays } = useDisplays()
    const { rooms } = useRooms()

    const searchDataIndex = useRecoilValue(_searchDataIndexAtom)
    const favorites = useRecoilValue(_favoritesAtom)

    const { locale } = useIntl()

    const _keyComparator = (k1, k2) => {
        const s1 = parseInt(k1.replace(/[^0-9]+/g, ''))
        const s2 = parseInt(k2.replace(/[^0-9]+/g, ''))

        return s1 - s2
    }

    const _tagsFromTree = (floor, rooms, excludedTagTypes) => {
        // convert rooms > displays > articles in tags
        const tagKeys =
            Object.keys(rooms).sort(_keyComparator).reduce((acc, rk) => {
                acc.push(rk)
                Object.keys(rooms[rk].displays).sort(_keyComparator).forEach(dk => {
                    acc.push(dk)
                    Object.keys(rooms[rk].displays[dk].articles).sort().forEach(ak => {
                        acc.push(ak)
                    })
                    Object.keys(rooms[rk].displays[dk].calibers).sort().forEach(ak => {
                        acc.push(ak)
                    })
                })
                return acc
            }, []).filter(k => k)

        let result = tagsFromKeys(floor, tagKeys, excludedTagTypes)

        return result
    }

    const treeTagsFromKeys = (floor, keys, excludedTagTypes) => {
        const rooms = floor && keys.reduce((rooms, k) => {
            let roomKey = null
            let displayKey = null
            let childKey = null
            let childType = null

            let tag = floor.Tags[k]

            if (tag != null && tag.Type === 'Room') {
                roomKey = k
            } else if (tag != null && tag.Type === 'Display') {
                displayKey = k
                roomKey = displays[k].Room
            }
            else if (tag == null) {

                let article = articles?.[k] ?? null

                if (article != null) {
                    displayKey = Object.keys(displays).find(dk => displays[dk]?.Articles && displays[dk].Articles.includes(k))
                    childKey = k
                    childType = 'Article'
                    if (displayKey)
                        roomKey = displays[displayKey].Room
                } else {
                    let caliber = calibers?.[k]
                    childKey = k
                    childType = 'Caliber'
                    if (caliber !== null) {
                        displayKey = Object.keys(displays).find(dk => displays[dk]?.Calibers && displays[dk]?.Calibers.includes(k))
                        roomKey = displays[displayKey].Room
                    }
                }
            }
            if (roomKey) {
                if (!rooms[roomKey])
                    rooms[roomKey] = { displays: {} }
                if (displayKey) {
                    if (!rooms[roomKey].displays[displayKey])
                        rooms[roomKey].displays[displayKey] = { articles: {}, calibers: {} }

                    if (childKey && childType === 'Article') {
                        rooms[roomKey].displays[displayKey].articles[childKey] = {}
                    }
                    if (childKey && childType === 'Caliber') {
                        rooms[roomKey].displays[displayKey].calibers[childKey] = {}
                    }
                }
            }
            return rooms
        }, {})

        return _tagsFromTree(floor, rooms, excludedTagTypes)
    }

    const tagsFromKeys = (floor, keys, excludedTagTypes, parentKey) => {

        const tags = floor && keys.reduce((acc, k) => {

            let tag = floor.Tags[k]

            if (tag == null) {

                let article = articles?.[k] ?? null

                if (article != null) {
                    const displayKey = parentKey ?? Object.keys(displays).find(dk => displays[dk]?.Articles && displays[dk].Articles.includes(k))
                    tag = { Type: 'Display', Key: displayKey, ChildType: 'Article', ChildKey: k }

                    if (displayKey) {
                        let parentTag = floor.Tags[displayKey]
                        if (parentTag == null)
                            parentTag = floor.Tags[displays[displayKey].Room]

                        if (parentTag)
                            tag.Node = parentTag.Node
                    }
                } else {
                    let caliber = calibers?.[k]
                    if (caliber !== null) {
                        const displayKey = parentKey ?? Object.keys(displays).find(dk => displays[dk]?.Calibers && displays[dk]?.Calibers.includes(k))
                        tag = { Type: 'Display', Key: displayKey, ChildType: 'Caliber', ChildKey: k }

                        if (displayKey) {
                            let parentTag = floor.Tags[displayKey]
                            if (parentTag == null)
                                parentTag = floor.Tags[displays[displayKey].Room]

                            if (parentTag)
                                tag.Node = parentTag.Node
                        }
                    }
                }
            } else
                tag = { ...tag, Key: k }

            if (tag)
                acc[k] = tag

            return acc
        }, {})

        return { keys: keys, tags: tags }
    }

    const tagsForType = (floor, type, filterTagsKeys) => {
        let tagsKeys = floor && Object.keys(floor.Tags).filter(k => floor.Tags[k].Type === type).sort(_keyComparator)

        let { keys, tags } = tagsFromKeys(floor, tagsKeys)
        if (filterTagsKeys) {
            let filteredResults = keys.reduce((acc, k) => {
                const value = tags[k]?.Type === 'Room' ? rooms[k] : null

                if (filterTagsKeys(k, tags[k], value)) {
                    acc.keys.push(k)
                    acc.tags[k] = tags[k]
                }
                return acc
            }, { keys: [], tags: {} })
            keys = filteredResults.keys
            tags = filteredResults.tags
        }
        return { keys, tags }
    }

    const filterTags = (floor, filter, excludedTagTypes) => {
        const tags = ["Room", "Display", "Article", "Caliber"]
        const rooms = {}

        if (searchDataIndex[locale] && filter?.length >= 3) {
            tags.forEach(tag => {
                const results = searchDataIndex[locale].search(filter, { limit: 1000, tag: tag, index: ['full'] })
                results.forEach(r => {
                    r.result.forEach(k => {
                        let roomKey = null
                        let displayKey = null
                        let childKey = null
                        let childType = null

                        if (tag === 'Room' && rooms[k])
                            roomKey = k
                        else if (tag === 'Display' && displays[k]) {
                            roomKey = displays[k].Room
                            displayKey = k
                        } else if (tag === 'Article' && articles[k]) {
                            childType = 'Article'
                            displayKey = Object.keys(displays).find(dk => displays[dk]?.Articles && displays[dk]?.Articles.includes(k))
                            if (displayKey) {
                                roomKey = displays[displayKey].Room
                                childKey = k
                            }
                        } else if (tag === 'Caliber' && calibers[k]) {
                            childType = 'Caliber'
                            displayKey = Object.keys(displays).find(dk => displays[dk]?.Calibers && displays[dk]?.Calibers.includes(k))
                            if (displayKey) {
                                roomKey = displays[displayKey].Room
                                childKey = k
                            }
                        }

                        if (roomKey) {
                            if (!rooms[roomKey])
                                rooms[roomKey] = { displays: {} }
                            if (displayKey) {
                                if (!rooms[roomKey].displays[displayKey])
                                    rooms[roomKey].displays[displayKey] = { articles: {}, calibers: {} }

                                if (childKey && childType === 'Article') {
                                    rooms[roomKey].displays[displayKey].articles[childKey] = {}
                                }
                                if (childKey && childType === 'Caliber') {
                                    rooms[roomKey].displays[displayKey].calibers[childKey] = {}
                                }
                            }
                        }
                    })
                });
            })
        }

        return _tagsFromTree(floor, rooms, excludedTagTypes)
    }

    const favoritesTags = (floor) => {
        const tagKeys = favorites.map(f => f.key)
        return treeTagsFromKeys(floor, tagKeys)
        // return tagsFromKeys(floor, tagKeys)
    }

    const audioguidesTags = (floor) => {
        const tagKeys = Object.keys(rooms).reduce((acc, rk) => {
            if (rooms[rk].AudioUri?.[locale])
                acc.push(rk)

            const displayKeys = Object.keys(displays).filter(dk => displays[dk].Room === rk && displays[dk].AudioUri?.[locale])
            acc = [...acc, ...displayKeys]

            return acc
        }, [])
        return tagsFromKeys(floor, tagKeys)
    }

    const parentTag = (tagKey, tagType, childKey) => {
        if (tagType?.toLowerCase() === 'display') {
            if (childKey)
                return { parentTagKey: tagKey, parentTagType: 'Display' }
            const display = displays[tagKey]
            return { parentTagKey: display?.Room, parentTagType: 'Room' }
        }

        return { parentTagKey: null, parentTagType: null }
    }

    const tagNamesTree = (tagType, tagKey, childKey) => {
        const result = []
        if (tagType?.toLowerCase() === 'room') {
            result.push(rooms[tagKey]?.Name[locale]?.replaceAll("<br/>", " "))
        } else if (tagType?.toLowerCase() === 'display') {
            const display = displays?.[tagKey]
            const room = rooms?.[display?.Room]
            result.push(room?.Name[locale]?.replaceAll("<br/>", " "))
            result.push(display?.Name[locale]?.replaceAll("<br/>", " "))
            if (childKey)
                result.push(childKey)
        }
        return result
    }

    return { tagsFromKeys, tagsForType, filterTags, favoritesTags, audioguidesTags, parentTag, tagNamesTree }
}

/* #endregion */

/* #region floor */

export function useFloorPathes(floor) {
    const getPathes = (from, to) => {
        const _getPathes = (from, to, visited) => {

            if (visited.includes(from) || !floor.Graph.Nodes[from])
                return [[]]

            if (from === to || !floor.Graph.Vertices[from] || floor.Graph.Vertices[from].length < 1)
                return [[from]]

            var pathes = []

            floor.Graph.Vertices[from].forEach(v => {

                _getPathes(v, to, [from, ...visited]).forEach(sp => {
                    pathes.push([from, ...sp])
                })
            })
            return pathes
        }

        let p = _getPathes(from, to, [])
        return p.filter(p => to === null || p[p.length - 1] === to)
    }

    const _findPathes = (from, to) => {

        const pathes = getPathes(from, to)
        let currentW = null

        const result = pathes.reduce((bp, p) => {
            let w = 0

            for (let i = 0; i < p.length - 1; i++) {
                w += Math.sqrt(Math.pow(floor.Graph.Nodes[p[i + 1]][0] - floor.Graph.Nodes[p[i]][0], 2) + Math.pow(floor.Graph.Nodes[p[i + 1]][1] - floor.Graph.Nodes[p[i]][1], 2))
            }

            if (currentW == null || w <= currentW) {
                currentW = w
                bp = p
            }
            return bp
        }, null)

        return result ? [result] : []
    }

    const findPathes = (from, to, intermediaries) => {
        let targets = []
        if (intermediaries?.length > 0) {

            for (let i = 0; i < intermediaries.length; i++) {
                targets.push([i < 1 ? from : intermediaries[i - 1], intermediaries[i]])
            }

            targets.push([intermediaries[intermediaries.length - 1], to])
        } else
            targets = [[from, to]]


        const result = targets.reduce((acc, t) => {
            const p = _findPathes(t[0], t[1])

            if (p.length > 0)
                acc = [...acc, ...(acc.length > 0 ? p[0].slice(1) : p[0])]

            return acc
        }, [])
        return result.length > 0 ? [result] : []
    }

    const pathCenter = (path) => {
        if (path?.length > 0) {
            let pathCenter = path.reduce((acc, k) => {
                acc[0] += floor.Graph.Nodes[k][0]
                acc[1] += floor.Graph.Nodes[k][1]
                return acc
            }, [0, 0])

            pathCenter[0] /= path.length
            pathCenter[1] /= path.length
            return pathCenter
        }
        return null
    }

    return { findPathes, pathCenter }
}


export function useJourneyPathes(floor, journey) {

    const { articles } = useArticles()
    const { calibers } = useCalibers()
    const { displays } = useDisplays()

    const { findPathes } = useFloorPathes(floor)

    if (journey == null)
        return []

    const tags = floor && articles && displays && journey && journey.Steps.reduce((acc, s) => {
        let tag = floor.Tags[s]
        if (tag == null) {
            let article = articles[s]
            if (article !== null) {
                tag = { Type: 'Article' }
                const displayKey = Object.keys(displays).find(k => displays[k].Articles.includes(s))
                if (displayKey) {
                    let parentTag = floor.Tags[displayKey]
                    if (parentTag == null)
                        parentTag = floor.Tags[displays[displayKey].Room]
                    if (parentTag)
                        tag.Node = parentTag.Node
                }
            } else {
                let caliber = calibers[s]
                if (caliber !== null) {
                    tag = { Type: 'Caliber' }
                    const displayKey = Object.keys(displays).find(k => displays[k].Articles.includes(s))
                    if (displayKey) {
                        let parentTag = floor.Tags[displayKey]
                        if (parentTag == null)
                            parentTag = floor.Tags[displays[displayKey].Room]
                        if (parentTag)
                            tag.Node = parentTag.Node
                    }
                }
            }
        }
        if (tag)
            acc[s] = tag
        return acc
    }, {})

    const journeyNodes = Object.values(tags ?? {}).filter(m => m.Node).map(m => m.Node)
    const pathes = journeyNodes?.length > 1 ?
        findPathes(journeyNodes[0], journeyNodes[journeyNodes.length - 1], journeyNodes.slice(1, journeyNodes.length - 2)) : []

    return pathes
}


/* #endregion */

export function useZoomPan(ref, initialScale, maxScale) {
    const springConfig = { mass: 0.2, tension: 100, friction: 20 } // 
    const [style, api] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: initialScale,
        config: springConfig
    }))

    useGesture({
        onDrag: ({ tap, cancel, pinching, offset: [x, y], event, ...rest }) => {
            if (pinching)
                cancel()

            api.start({ x: x, y: y })
        },
        onPinch: ({ cancel, origin: [ox, oy], first, da: [d], initial: [id], offset: [s, a], memo, event }) => {
            if (first) {
                const { width, height, x, y } = ref.current.getBoundingClientRect()
                const initialScale = style.scale.get()
                const tx = ox - (x + width / 2)
                const ty = oy - (y + height / 2)
                memo = [style.x.get(), style.y.get(), tx, ty, initialScale]
            }
            let ms = id === 0 ? (event.deltaY < 0 ? 1.8 : 1 / 2) : d / id

            let x = memo[0] - (ms - 1) * memo[2]
            let y = memo[1] - (ms - 1) * memo[3]

            let scale = memo[4] * ms
            if (scale > maxScale) // || scale < 0.4) 
            {
                ms = maxScale / memo[4]
                x = memo[0] - (ms - 1) * memo[2]
                y = memo[1] - (ms - 1) * memo[3]

                scale = memo[4] * ms
            } else if (scale < 1) {
                ms = 1 / memo[4]
                x = memo[0] - (ms - 1) * memo[2]
                y = memo[1] - (ms - 1) * memo[3]

                scale = memo[4] * ms
            }

            api.start({ scale: scale, x: x, y: y })

            return memo
        },
        onPinch_: ({ cancel, origin: [ox, oy], first, da: [d], initial: [id], offset: [s, a], memo, event }) => {
            if (first) {
                const { width, height, x, y } = ref.current.getBoundingClientRect()
                const initialScale = style.scale.get()
                const tx = ox - (x + width / 2)
                const ty = oy - (y + height / 2)
                memo = [style.x.get(), style.y.get(), tx, ty, initialScale]
            }

            let ms = id === 0 ? (event.deltaY < 0 ? 1.8 : 1 / 2) : d / id

            const x = memo[0] - (ms - 1) * memo[2]
            const y = memo[1] - (ms - 1) * memo[3]

            const scale = memo[4] * ms
            const clampedScale = Math.min(maxScale, Math.max(1, scale))

            api.start({ scale: clampedScale, x: x, y: y })

            return memo
        }
    },
        {
            target: ref,
            drag: {
                rubberband: true,
                filterTaps: true,
                from: () => [style.x.get(), style.y.get()]
            },
            pinch: {
                modifierKey: null,
            }
        }
    )

    const reset = () => {
        api.start({ scale: 1, x: 0, y: 0, immediate: true })
    }
    return { style, reset }
}

/* #region interactive floor */

export function useInteractiveFloor(layersRef, initialScale, minScale, maxScale, onStart, onChange, onEnd) {
    // console.info(initialScale, minScale, maxScale)
    //const springConfig = { mass: 0.2, tension: 100, friction: 20 } // 
    const springConfig = { mass: 0.05, tension: 100, friction: 20 } //{ tension: 100, friction: 36  } //config.default // { mass: 0.01, tension: 6, friction: 0  }
    //{duration: 0 } // 
    const [layersStyle, layersApi] = useSpring(() => ({
        x: 0,
        y: 0,
        scale: initialScale,
        rotateZ: 0,
        rotateX: 0,
        config: springConfig,
        onChange: () => {
            if (onChange)
                onChange({ x: layersStyle.x.get(), y: layersStyle.y.get(), scale: layersStyle.scale.get() }, layersApi)
        },
        onResume: () => {
        },
        onStart: () => {
            if (onStart)
                onStart({ x: layersStyle.x.get(), y: layersStyle.y.get(), scale: layersStyle.scale.get() })
        },
        onResolve: () => {
        },
        onDelayEnd: () => {
        },
        onRest: () => {
            if (onEnd)
                onEnd({ x: layersStyle.x.get(), y: layersStyle.y.get(), scale: layersStyle.scale.get() })

        },
        onProps: () => {
        },
    }))

    useEffect(() => {
        if (onChange)
            onChange({ x: layersStyle.x.get(), y: layersStyle.y.get(), scale: layersStyle.scale.get() })
    }, [])

    const baseRotateX = 20
    const [layerStyle, layerApi] = useSpring(() => ({
        rotateX: 0, //20
        scale: 1,
        rotateZ: 0,
        config: springConfig
    }))

    useEffect(() => {
        layerApi.start({ immediate: true })
        layersApi.start({ immediate: true })
    })


    useGesture({
        onDrag: ({ tap, cancel, pinching, offset: [x, y], event, ...rest }) => {
            if (pinching)
                return cancel()
            layersApi.start({ x: x, y: y })
        },
        onPinch: ({ cancel, origin: [ox, oy], first, da: [d], initial: [id], offset: [s, a], memo, event }) => {
            if (first) {
                const { width, height, x, y } = layersRef.current.getBoundingClientRect()
                const initialScale = layersStyle.scale.get()
                const tx = ox - (x + width / 2)
                const ty = oy - (y + height / 2)
                memo = [layersStyle.x.get(), layersStyle.y.get(), tx, ty, initialScale]
            }
            let ms = id === 0 ? (event.deltaY < 0 ? 1.8 : 1 / 2) : d / id

            let x = memo[0] - (ms - 1) * memo[2]
            let y = memo[1] - (ms - 1) * memo[3]

            let scale = memo[4] * ms
            if (scale > maxScale) {
                ms = maxScale / memo[4]
                x = memo[0] - (ms - 1) * memo[2]
                y = memo[1] - (ms - 1) * memo[3]

                scale = memo[4] * ms
            } else if (scale < minScale) {
                ms = minScale / memo[4]
                x = memo[0] - (ms - 1) * memo[2]
                y = memo[1] - (ms - 1) * memo[3]

                scale = memo[4] * ms
            }

            // if (scale > maxScale || scale < minScale) 
            // {
            //     ms = 1
            //     x = memo[0] - (ms - 1) * memo[2]
            //     y = memo[1] - (ms - 1) * memo[3]

            //     scale = memo[4] * ms 
            // }                       

            if (onStart)
                onStart()

            layersApi.start({ scale: scale, x: x, y: y })

            return memo
        }
    },
        {
            target: layersRef,
            eventOptions: { passive: false },
            drag: {
                filterTaps: true,
                from: () => [layersStyle.x.get(), layersStyle.y.get()],
                bounds: () => {
                    const rect = layersRef.current.getBoundingClientRect()
                    const result = { left: -rect.width * 3, right: rect.width * 3, top: -rect.height * 3, bottom: rect.height * 3 }

                    return result
                },
                rubberband: true,
            },
            pinch: {
                scaleBounds: { min: minScale, max: maxScale },
                rubberband: true,
                modifierKey: null,
            }
        }
    )


    // move from a, b to x, y with scale
    const _moveTo = (a, b, x, y, relative, scale, duration) => {
        const backTo = () => {
            const scale = layersStyle.scale.get()
            const x = layersStyle.x.get()
            const y = layersStyle.y.get()

            return () => {
                layersApi.start({ scale: scale, x: x, y: y })
            }
        }
        scale = scale ?? layersStyle.scale.get()// && scale > layersStyle.scale.get() ? scale : layersStyle.scale.get()
        const scaleCoefficient = scale / layersStyle.scale.get()

        const { width: layersWidth, height: layersHeight, x: layersX, y: layersY } = layersRef.current.getBoundingClientRect()

        const deltaX = relative ? 1 * (layersStyle.x.get() + x) : x - a
        const deltaY = relative ? 1 * (layersStyle.y.get() + y) : y - b

        const nx = deltaX - (scaleCoefficient - 1) * (a - (layersX + layersWidth / 2))
        const ny = deltaY - (scaleCoefficient - 1) * (b - (layersY + layersHeight / 2))

        layersApi.start({ scale: scale, x: nx, y: ny }) //, config: { duration: duration > 0 ? duration: undefined } })

        return backTo()
    }

    const centerAt = (a, b, scale, duration) => {
        const { width, height, x, y } = layersRef.current.getBoundingClientRect()
        return _moveTo(a, b, x + width / 2, y + height / 2, false, scale, duration)
    }

    const center = (scale, duration) => {
        const { width, height, x, y } = layersRef.current.getBoundingClientRect()
        return _moveTo(x + width / 2, y + height / 2, x + width / 2, y + height / 2, false, scale, duration)
    }

    const scaleAt = (a, b, scale, duration) => {
        return _moveTo(a, b, 0, 0, true, scale, duration)
    }

    const centerElement = (element, target, scale, duration) => {
        if (target) {
            const rect = element.getBoundingClientRect()
            const { width, height, x, y } = target.getBoundingClientRect()

            const targetX = (x + width / 2 - rect.x)
            const targetY = (y + height / 2 - rect.y)

            return _moveTo(rect.x, rect.y, targetX, targetY, true, scale, duration)
        } else {
            const { width, height, x, y } = layersRef.current.getBoundingClientRect()
            const rect = element.getBoundingClientRect()
            return _moveTo(rect.x + rect.width / 2, rect.y + rect.height / 2, x + width / 2, y + height / 2, false, scale, duration)
        }
    }

    const scaleElement = (element, scale, duration) => {
        const rect = element.getBoundingClientRect()
        return _moveTo(rect.x + rect.width / 2, rect.y + rect.height / 2, 0, 0, true, scale, duration)
    }

    const ensureVisibilityElement = (element, visibleElement, scale, duration) => {
        const rect = element.getBoundingClientRect()
        const { x: visibleleft } = visibleElement.getBoundingClientRect()

        const targetX = visibleleft - rect.width - rect.x - 70
        if (targetX < 0)
            return _moveTo(rect.x + rect.width / 2, rect.y + rect.height / 2, targetX, 0, true, scale, duration)
        else
            return scaleElement(element, scale, duration)
    }

    const ensureVisiblityAt = (a, b, visibleElement, scale, duration) => {
        const { x: visibleLeft, y: visibleTop } = visibleElement.getBoundingClientRect()

        const targetX = visibleLeft - 50 - a
        const targetY = visibleTop - 0 - b

        if (targetX < 0 || targetY < 0)
            return _moveTo(a, b, targetX, targetY, true, scale, duration)
        else
            return scaleAt(a, b, scale, duration)
    }

    const api = { center, centerAt, centerElement, scaleAt, scaleElement, ensureVisibilityElement, ensureVisiblityAt }

    return { layersStyle, layerStyle, scale: layerStyle.scale.get(), api }
}

/* #endregion */

/* #region floor context */
export function useFloorContext(handlers) {
    const floorContext = useOutletContext()

    useEffect(() => {
        floorContext?.floorRef?.current?.setHandlers(handlers)
    }, [floorContext?.floorRef, handlers])

    useEffect(() => {
        return () => {
            floorContext?.floorRef?.current?.setHandlers(null)
        }
    }, [floorContext?.floorRef])

    return floorContext
}
/* #endregion */

/* #region favorites */

const _favoritesLocalStorageKey = '_favoritesLocalStorageKey'

const _defaultFavorites = () => {
    const e = localStorage.getItem(_favoritesLocalStorageKey);
    if (e)
        return JSON.parse(e)
    return []
}

export const _favoritesAtom = atom({
    key: "favoritesAtom",
    default: _defaultFavorites()
})

export function useFavorite(type, key) {
    const [favorites, setFavorites] = useRecoilState(_favoritesAtom)

    let isFavorite = type && key && favorites.find(f => f.type === type && f.key === key) != null

    const toggleFavorite = () => {
        if (!type || !key)
            return

        const _favorites = [...favorites]
        let favoriteIndex = _favorites.findIndex(f => f.type === type && f.key === key)
        if (favoriteIndex > -1)
            _favorites.splice(favoriteIndex, 1)
        else
            _favorites.push({ type: type, key: key })

        // store favorites
        localStorage.setItem(_favoritesLocalStorageKey, JSON.stringify(_favorites))
        setFavorites(_favorites)
    }

    return { isFavorite, toggleFavorite }
}

/* #endregion */

export function useTagLayout(dragTagRef, dragTagLimitRef, onDrag, onSwipe) {
    const { width, height } = useWindowSize()
    const dragPercentRef = useRef(-1)
    const resizeTimeoutRef = useRef()

    const springConfig = { mass: 0.1, tension: 250, friction: 20 } // 
    const { is: isMediaQuery } = useMediaQuery()
    const isMobile = isMediaQuery.only('mobile')
    const isVertical = isMobile

    const baseStyles = isVertical ? {
        top: window.innerHeight,
        right: 0,
        config: { mass: 0.2, tension: 350, friction: 16 }
    } : {
        right: -1, //dragTagRef?.current?.right,
        top: 0,
        config: { mass: 0.1, tension: 250, friction: 20 }
    }

    const [layoutStyle, layoutApi] = useSpring(() => {
        return {
            ...baseStyles,
            onStart: (e) => {
            },
            onChange: () => {
            },
            onResolve: () => {
            },
            onRest: () => {
                if (onDrag) {
                    const lr = dragTagLimitRef.current?.getBoundingClientRect()
                    const rr = dragTagRef.current?.getBoundingClientRect()

                    let _dragPercent = 0
                    if (isVertical)
                        _dragPercent = 100 * (1 - (layoutStyle.top.get() - lr.top) / (lr.height - rr.height))
                    else
                        _dragPercent = 100 * (rr.right - lr.left - rr.width) / (lr.width - rr.width)

                    // eslint-disable-next-line no-self-compare
                    if (_dragPercent !== _dragPercent)
                        _dragPercent = 50

                    _dragPercent = Math.min(100, Math.max(0, _dragPercent))
                    dragPercentRef.current = _dragPercent
                    onDrag(_dragPercent)
                }
            },
        }
    }, [isVertical])

    useEffect(() => {
        if (resizeTimeoutRef.current) {
            clearTimeout(resizeTimeoutRef.current)
            resizeTimeoutRef.current = null
        }
        resizeTimeoutRef.current = setTimeout(() => {
            if (dragPercentRef.current > -1)
                setDragPercent(dragPercentRef.current)
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, isVertical])

    const setDragPercent = (dragPercent, delay) => {
        if (!dragTagLimitRef.current || dragPercent === null)
            return

        const lr = dragTagLimitRef.current?.getBoundingClientRect()
        const rr = dragTagRef.current?.getBoundingClientRect()

        if (isVertical) {
            const y = lr.top + (100 - dragPercent) * 0.01 * (lr.height - rr.height)
            if (y > -1 && y < window.innerHeight)
                layoutApi.start({ top: y, right: 0, delay: delay ?? 0 })
        } else {
            const x = (window.innerWidth - lr.right) + (100 - dragPercent) * 0.01 * (lr.width - rr.width)
            if (x > -1 && x < window.innerWidth)
                layoutApi.start({ right: x, top: 0, delay: delay ?? 0 })
        }
    }

    const updateToLimit = (force) => {
        // test if layout in limit, otherwise set 100%
        const lr = dragTagLimitRef.current?.getBoundingClientRect()
        const rr = dragTagRef.current?.getBoundingClientRect()
        if (!lr || !rr)
            return

        if (force || (isVertical && lr.top > rr.top)) {
            setDragPercent(100, lr.top < rr.top ? 0 : 100)
        }
    }

    useGesture({
        onDrag: ({ last, swipe: [sx, sy], tap, cancel, pinching, offset: [x, y], event, ...rest }) => {
            if (isVertical) {
                if (sy > 0) {
                    onSwipe(false)
                } else if (sy < 0) {
                    onSwipe(true)
                } else {
                    layoutApi.start({ top: y })
                }
            } else {
                if (sx > 0) {
                    onSwipe(true)
                } else if (sx < 0) {
                    onSwipe(false)
                } else {
                    layoutApi.start({ right: -x })

                }
            }

        }
    },
        {
            target: dragTagRef,
            drag: {
                rubberband: true,
                filterTaps: true,
                from: () => [-layoutStyle.right?.get(), layoutStyle.top?.get()],
                bounds: dragTagLimitRef
            }
        }
    )

    // return { isVertical:true, layoutStyle:{}, setDragPercent: () => {}, updateToLimit: () => {}}

    return { isVertical, layoutStyle, setDragPercent, updateToLimit }
}