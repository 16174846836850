import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom";
import Header from './Header';
import Home from './Home';
import { usePreloadData, usePreloadSearch } from './hooks';
import Infos from './Infos';
import Layout from './Layout';
import Main from './Main';
import Visit from './Visit';

import React, { useEffect, useMemo, useRef, useState } from 'react';

const PreloadedRoutedAppMemo = React.memo(PreloadedRoutedApp)

export default function RoutedApp() {

  const preloadedData = usePreloadData()
  const preloadedSearch = usePreloadSearch()
  const preloadingRef = useRef(false)
  const [preloaded, setPreloaded] = useState(false)

  useEffect(() => {
    if (preloadedData.success && preloadedSearch && !preloaded && !preloadingRef.current) {
      preloadingRef.current = true
      setTimeout(() => {
        setPreloaded(true)
      }, 0)
    }
  }, [preloaded, preloadedData.success, preloadedSearch])

  const preloadedRoutedApp = useMemo(() => preloaded, [preloaded])
  return <PreloadedRoutedAppMemo preloaded={preloadedRoutedApp} />
}

function PreloadedRoutedApp({ loading, preloaded }) {
  const [headerPreloaded, setHeaderPreloaded] = useState(false)
  const [searchActive, setSearchActive] = useState(false)

  useEffect(() => {
    if (preloaded) {
      setTimeout(() => setHeaderPreloaded(true), 100)
    }
  }, [preloaded])


  const handleUpdateSearchActive = (v) => {
    setSearchActive(v)
  }

  const routeElements = <Route path="/*" element={
    <Layout
      header={
        <Routes>
          <Route path="/" element={<Header preloaded={headerPreloaded} reduced={false} searchActive={searchActive} onUpdateSearchActive={handleUpdateSearchActive} />}></Route>
          <Route path="*" element={<Header preloaded={headerPreloaded} reduced={true} searchActive={searchActive} onUpdateSearchActive={handleUpdateSearchActive} />}></Route>
        </Routes>
      }
      main={
        <>
          {preloaded &&
            <Routes>
              <Route path="/*" element={<Main />}>
                <Route path="visit" element={<Visit searchActive={searchActive} />}>
                  <Route path=":tagType/:tagKey" />
                  <Route path=":tagType/:tagKey/:childKey" />
                  <Route path="favorites" />
                  <Route path="favorites/:tagType/:tagKey" />
                  <Route path="favorites/:tagType/:tagKey/:childType/:childKey" />
                  <Route path="search" />
                  <Route path="search/:tagType/:tagKey" />
                  <Route path="search/:tagType/:tagKey/:childType/:childKey" />
                  <Route path="audioguides" />
                  <Route path="audioguides/:tagType/:tagKey" />
                  <Route path="audioguides/:tagType/:tagKey/:childType/:childKey" />
                  <Route path="rooms" />
                  <Route path="rooms/:tagType/:tagKey" />
                  <Route path="rooms/:tagType/:tagKey/:childType/:childKey" />
                  <Route path="journey/:journeyKey" />
                  <Route path="journey/:journeyKey/:tagType/:tagKey" />
                  <Route path="journey/:journeyKey/:tagType/:tagKey/:childType/:childKey" />
                  <Route path="map" />
                </Route>
                <Route path="infos" element={<Infos searchActive={searchActive} />} />
                <Route path="" element={<Home />} />
                {/* <Route path="*" element={<Navigate to="/" />} />     */}
              </Route>
            </Routes>
          }
        </>
      }
    />
  }></Route>

  const router = createBrowserRouter(createRoutesFromElements(routeElements))
  
  return <RouterProvider router={router} />
}

