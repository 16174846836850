import styled from '@emotion/styled/macro';
import { Slider } from '@mui/material';
import mime from 'mime-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import RP from 'react-player';
import { useFullscreen, useToggle } from "react-use";
import { IconButtons, Icons } from './components';


const ForwardedPlayer = forwardRef(Player)
export default ForwardedPlayer

/**
 * Audio/Video player 
 */
function Player({ className, uri, onEnded }, ref) {
    const playerRef = useRef()
    const layoutRef = useRef()

    const [fullScreen, toggleFullScreen] = useToggle(false)
    const isIos = navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || navigator.userAgent.toLowerCase().indexOf('ipad') > -1

    useFullscreen(ref, fullScreen, { onClose: () => toggleFullScreen(false) });

    const [playing, togglePlay] = useToggle(false)
    const [ready, setReady] = useState(false)
    const [controlsActive, setControlsActive] = useState(true)

    const [showSliderThumb, toggleSliderThumb] = useToggle(false)
    const [volume, setVolume] = useState(100)
    const [showVolume, setShowVolume] = useState(false)

    const showVolumeTimeoutRef = useRef(null)
    const showControlsTimeoutRef = useRef(null)

    const [duration, setDuration] = useState(0)
    const [progress, setProgress] = useState({ played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 })
    const [seek, setSeek] = useState(0)

    const lookup = mime.lookup(uri)
    const isAudio = lookup && lookup?.includes('audio')


    useEffect(() => {
        if (ready) {
            // setTimeout(() => {
            //     //if (!play)
            //         togglePlay(play)

            // }, play ? 1000 : 0)

            playerRef.current.seekTo(0);
            setControlsActive(true)
            clearTimeout(showControlsTimeoutRef.current)
            showControlsTimeoutRef.current = setTimeout(() => {
                setControlsActive(false)
            }, 3000)
        }
    }, [ready])


    const handleReady = (e) => {
        setReady(true)
    }

    const handleDuration = (d) => {
        setDuration(d)
    }
    const handleProgress = (p) => {
        setProgress(p)
    }

    const handleSeek = (event, newValue) => {
        setSeek(newValue)
        if (playerRef.current)
            playerRef.current.seekTo(newValue / 100);
    }

    useEffect(() => {
        setSeek(progress.played * 100)
    }, [progress.played])

    const formatDuration = (total) => {
        var sec_num = parseInt(total, 10)
        var hours = Math.floor(sec_num / 3600)
        var minutes = Math.floor(sec_num / 60) % 60
        var seconds = sec_num % 60

        return [hours, minutes, seconds]
            .map(v => v < 10 ? "0" + v : v)
            .filter((v, i) => v !== "00" || i > 0)
            .join(":")
    }

    const handleEnded = () => {
        setTimeout(() => {
            togglePlay(false)
            if (playerRef.current)
                playerRef.current.seekTo(0)
        }, 100)
        if (onEnded)
            onEnded()
    }

    const handleToggleVolume = () => {
        if (volume > 50)
            setVolume(0)
        else if (volume < 1)
            setVolume(50)
        else setVolume(100)
    }

    const handleTogglePlay = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!showVolume)
            togglePlay()
    }

    const handlePlayerClick = (e) => {
        if (window.ontouchstart)
            handleActiveControls()
        else {
            togglePlay()
        }
    }

    const handleActiveControls = () => {
        setControlsActive(true)
        clearTimeout(showControlsTimeoutRef.current)

        showControlsTimeoutRef.current = setTimeout(() => {
            if (playing)
                setControlsActive(false)
        }, 2000)
    }

    useImperativeHandle(ref, () => ({
        togglePlay(play) {
            togglePlay(play)
        },
        seekTo(to) {
            playerRef.current?.seekTo(to)
        },
        inner: layoutRef.current
    }))

    return (
        <Layout className={className} hideMouse={playing && !controlsActive} ref={layoutRef} onMouseEnter={handleActiveControls} onMouseMove={handleActiveControls}>
            <PlayerWrapper fullScreen={fullScreen} onClick={handlePlayerClick}>
                <ReactPlayer
                    playsInline={true}
                    preload={1}
                    progressInterval={100}
                    ref={playerRef}
                    onReady={handleReady}
                    onDuration={handleDuration}
                    onProgress={handleProgress}
                    playing={playing}
                    url={uri}
                    volume={volume / 100}
                    onEnded={handleEnded}
                >
                </ReactPlayer>
            </PlayerWrapper>
            <PlayerControls fullScreen={fullScreen} active={(isAudio || controlsActive || (fullScreen === true)) ? 1 : 0}>
                <PlayPauseIconButton onClick={handleTogglePlay}>
                    {!playing && <Icons.Play />}
                    {playing && <Icons.Pause />}
                </PlayPauseIconButton>
                <Duration>
                    {formatDuration(progress.playedSeconds)}
                </Duration>
                <SeekControls onMouseEnter={toggleSliderThumb} onMouseLeave={toggleSliderThumb} >
                    <ProgressSlider active={showSliderThumb ? 1 : 0} value={seek} onChange={handleSeek} />
                </SeekControls>
                <Duration>
                    {formatDuration(duration)}
                </Duration>
                {!isIos &&
                    <VolumeControls>
                        <VolumeIconButton onClick={handleToggleVolume}>
                            {volume < 1 && <Icons.VolumeOff></Icons.VolumeOff>}
                            {volume > 1 && volume <= 50 && <Icons.VolumeDown></Icons.VolumeDown>}
                            {volume > 50 && <Icons.VolumeUp></Icons.VolumeUp>}
                        </VolumeIconButton>
                    </VolumeControls>
                }
                {!isAudio && <FullScreenIconButton size="medium" onClick={toggleFullScreen}>
                    {!fullScreen && <Icons.EnterFullscreen />}
                    {fullScreen && <Icons.ExitFullscreen />}
                </FullScreenIconButton>}
            </PlayerControls>
        </Layout>
    )
}

const Layout = styled('div')(props => props.theme.unstable_sx({
    width: '100%',
    minHeight: '2.875rem',
    height: 'auto',
    position: 'relative',
    maxHeight: 'inherit',
    ...(props.hideMouse && {
        [PlayerWrapper]: {
            cursor: 'none !important',
        },
        [ReactPlayer]: {
            cursor: 'none !important',
            'video': {
                cursor: 'none !important'
            }
        },
    })
}))

const PlayerWrapper = styled('div')(props => ({
    height: 'auto',
    maxHeight: 'inherit',
    overflow: 'hidden',
    width: '100%',
    ...(props.fullScreen && {
        height: '100%'
    }),
}))

const ReactPlayer = styled(RP)({
    lineHeight: 0,
})
ReactPlayer.defaultProps = { width: '100%', height: '100%', cursor: 'none' }


const PlayerControls = styled('div')(props => props.theme.sx({
    position: 'absolute',
    bottom: '0px',
    left: 0,
    right: 0,
    width: '100%',
    margin: '0',
    backgroundColor: 'primary.surfaceDark',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    opacity: 0,
    transition: 'opacity 0.4s ease',
    ...(props.active && {
        opacity: 1,
    }),
    ...(props.fullScreen && {
        backgroundColor: 'transparent',
        padding: '0.75rem',
        maxWidth: '100vw',
        'svg': {
            fill: (theme) => theme.palette.primary.contrastText
        },
        [Duration]: {
            color: 'primary.contrastText'
        },
        [ProgressSlider]: {
            color: 'primary.contrastText'
        }
    }),
    paddingRight: '1rem'
}))

const Duration = styled('span')(props => props.theme.sx({
    whiteSpace: 'nowrap',
    color: 'primary.contrastText',
    fontSize: '0.813rem'
}))

const SeekControls = styled('div')({
    flexGrow: 1,
    flexShrink: 1,
    position: 'relative',
    margin: '0 0.625rem',
    height: '1.0625rem !important',
    '&:hover .MuiSlider-thumb':
    {
        opacity: 1
    },
    overflow: 'visible'
})

const ProgressSlider = styled(Slider)(props => props.theme.sx({
    position: 'absolute',
    left: 0,
    top: '0px',
    width: '100%',
    zIndex: 100,
    height: '100%',
    padding: '0 !important',
    '& .MuiSlider-track, & .MuiSlider-rail': {
        height: '0.125rem',
        zIndex: 0,
    },
    '& .MuiSlider-thumb': {
        transition: 'opacity 0.5s ease-in',
        opacity: 0,
        zIndex: 10,
        width: '0.8125rem',
        height: '0.8125rem',


        // display: 'none'
    },
    color: 'rgba(255, 255, 255, 1)' //white', //'player.controls.slider.color',

}))


const VolumeControls = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'flex-end',
    marginLeft: '0.325rem'
})


const PlayPauseIconButton = styled(IconButtons.Default)(props => props.theme.sx({
    '> svg': {
        height: '1.85rem',
        fill: (theme) => theme.palette.primary.contrastText,
        'path': {
            stroke: (theme) => theme.palette.primary.contrastText,
        }
    },
}))

const VolumeIconButton = styled(IconButtons.Default)(props => props.theme.sx({
    '> svg': {
        height: '1.85rem',
        fill: (theme) => theme.palette.primary.contrastText,
        'path': {
            stroke: (theme) => theme.palette.primary.contrastText,
        }
    }
}))

const FullScreenIconButton = styled(IconButtons.Default)({
    marginLeft: '0.625rem'
})