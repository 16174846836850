import styled from '@emotion/styled/macro';

import Article from './article/Article';
import Caliber from './caliber/Caliber';
import Display from './display/Display';
import Filter from './filter/Filter';
import Journey from './journey/Journey';
import POI from './poi/POI';
import Room from './room/Room';



export default function Tag({ floating, filter, tagApi, tagType, tagKey,  altTag, childType, childKey, journeyKey, fromPath, focused, onSelect, onSelectAudio, ...rest }) {
    return (
        <>
            <Layout focused={focused?1:0} altTag={altTag?1:0}>
                {filter && !tagType && !altTag &&
                    <Filter filter={filter} tagApi={tagApi} onSelect={onSelect} />
                }
                {journeyKey && !tagType && !altTag &&
                    <Journey tagApi={tagApi} onSelect={onSelect} journeyKey={journeyKey} fromPath={fromPath} />
                }
                {{
                    'article': <Article floating={floating} tagApi={tagApi} onSelect={onSelect} focused={focused} altTag={altTag} displayKey={tagKey} articleKey={childKey} journeyKey={journeyKey} filter={filter} fromPath={fromPath} />,
                    'caliber': <Caliber floating={floating} tagApi={tagApi} onSelect={onSelect} focused={focused} altTag={altTag} displayKey={tagKey} caliberKey={childKey} journeyKey={journeyKey} filter={filter} fromPath={fromPath} />,
                    'display': <Display floating={floating} tagApi={tagApi} onSelect={onSelect} onSelectAudio={onSelectAudio} focused={focused} altTag={altTag} displayKey={tagKey} journeyKey={journeyKey} filter={filter} fromPath={fromPath} />,
                    'room': <Room floating={floating} tagApi={tagApi} onSelect={onSelect} onSelectAudio={onSelectAudio} focused={focused} altTag={altTag} roomKey={tagKey} journeyKey={journeyKey} filter={filter} fromPath={fromPath} />,
                    'poi': <POI floating={floating} tagApi={tagApi} onSelect={onSelect} onSelectAudio={onSelectAudio} focused={focused} altTag={altTag} POIKey={tagKey} journeyKey={journeyKey} filter={filter} fromPath={fromPath} />,
                }[(childType??tagType)?.toLowerCase()]}
            </Layout>
        </>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    width: '100%',
    height: '100%',
    maxHeight: 'inherit',
    position: 'relative',
    overflow: 'hidden',
    ...(props.focused && {
        // border: 'solid 2px red'
    })
    // ...(props.altTag && props.focused && {
    //     '&:after': {
    //         position: 'absolute',
    //         top: 0,
    //         left: 0,
    //         height: '100%',
    //         width: '0.25rem',
    //         content: '""',
    //         opacity: 1
    //     }
    // })
}))
