import styled from '@emotion/styled/macro';
import { ButtonBase, cardClasses } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { NavLink, unstable_useBlocker as useBlocker, useLocation } from 'react-router-dom';
import { IconButtons, Icons, Modal } from './components/components';
import Helmet from './Helmet';
import { useIntl, useMap } from './hooks';
import QRCodeScanner from './QRCodeScanner';

const ForwardedMenu = forwardRef(Menu)
export default ForwardedMenu

/**
 * Menu component
 * TODO: --- 
 */
function Menu({ home, onClose }, ref) {

    const { locale, locales, setLocale, intl } = useIntl()
    const [scan, setScan] = useState({ open: false, generate: false })
    const [nextLocale, setNextLocale] = useState(locale)
    const location = useLocation()

    useEffect(() => {
        if (locales?.length > 1) {
            let nextLocaleIndex = locales.indexOf(locale)
            if (nextLocaleIndex >= locales.length - 1)
                nextLocaleIndex = 0
            else
                nextLocaleIndex += 1
            setNextLocale(locales[nextLocaleIndex])
        }
    }, [locale, locales, setNextLocale])

    const handleNextLocaleClick = () => {
        !home && onClose && onClose()
        setLocale(nextLocale)
    }

    const handleOpenQRCodeScanner = () => {
        !home && onClose && onClose()
        setScan({ open: true, generate: false })
    }

    useEffect(() => {
        if (scan.open)
            setScan({ open: false, generate: false })
    }, [location.pathname])

    // close scan modal on pop action - prevent routing 
    useBlocker((e) => {
        if (scan.open && e.historyAction === 'POP') {
            setScan({ open: false, generate: false })
            return true
        }
        return false
    })

    const {map} = useMap()
    const floor = Object.values(map?.Floors ?? {})?.[0]
    const defaultTag = Object.keys(floor?.Tags??{}).find(k => floor?.Tags?.[k].IsDefault)
    
    const mapUri = "/visit/map"
    //defaultTag ? ("/visit/rooms/" + floor?.Tags?.[defaultTag].Type + "/" + defaultTag + "?l=1") : "/visit/map"
    
    return (
        <Layout ref={ref} home={home ? 1 : 0}>
            <MenuItems>
                <MenuItemLink home={home ? 1 : 0} to={mapUri}><Icons.Map />{intl.getHTML('Menu.map').d('Menu.map')}</MenuItemLink>
                <MenuItemLink home={home ? 1 : 0} to="/visit/rooms" ><Icons.Visit />{intl.getHTML('Menu.visit').d('Menu.visit')}</MenuItemLink>
                <ScanQRItem home={home ? 1 : 0} onClick={handleOpenQRCodeScanner}><Icons.Scan />{intl.getHTML('Menu.scanQR').d('Menu.scanQR')}</ScanQRItem>
                <MenuItemLink home={home ? 1 : 0} to="/infos"><Icons.Infos />{intl.getHTML('Menu.informations').d('Menu.informations')}</MenuItemLink>
                <MenuItemLink home={home ? 1 : 0} to="/visit/favorites" ><Icons.Favorite />{intl.getHTML('Menu.favorites').d('Menu.favorites')}</MenuItemLink>
                {locales && locales.length > 0 && <ToggleLocaleItem home={home ? 1 : 0} onClick={handleNextLocaleClick}>
                    <Icons.Language />
                    {intl.get('global.locales.' + nextLocale).d('global.locales.' + nextLocale)}
                </ToggleLocaleItem>}
            </MenuItems>
            {!home && <CloseIconButton onClick={onClose} />}

            {/* Qr code scanner */}
            <ScanModal home={home} open={scan.open} onClose={() => setScan({ open: false, generate: false })}>
                <QRCodeScanner generate={scan.generate} onClose={() => setScan({ open: false, generate: false })} />
            </ScanModal>
            { scan.open && <Helmet>
                <header>{intl.get("helmet.navigation.qrScanner")}</header>
            </Helmet> }

            {/* Copyright ?! */}
            <Copyright>{intl.get('Menu.copyright').d('Menu.copyright')} v1.01</Copyright>

        </Layout>
    )
}


const Layout = styled('div')(props => props.theme.sx({
    pointerEvents: 'initial',
    height: '100%',
    ...(!props.home && {
        backgroundColor: 'primary.surface',
        width: '22.5rem',
        height: '100%',
        overflow: 'visible',
        position: 'absolute',
        left: 0,
        boxShadow: (theme) => '-1px 1px 6px 2px rgba(1,1,1,0.76)',
        [MenuItems]: {
            margin: '2.75rem 0 0 0',
            flexDirection: 'column',
            alignItems: 'stretch',
            maxWidth: 'unset',
        }
    }),
    ...(props.home && {
        [MenuItems]: {
            position: 'absolute',
            top: '2rem',
            left: '0',
            right: '0',
            // transform: 'translateY(46vh)',
            margin: '0 auto'
        }
    })
}))


const ScanModal = styled(Modal)(props => props.theme.sx({
    top: '3.75rem',
    height: 'calc(100% - 3.75rem)',
    ...(props.home && {
        top: 0,
        height: '100%'
    }),
}))


const MenuItems = styled('div')(props => props.theme.sx({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '&:after': {
        content: '""',
        flexGrow: 1,
        flexShrink: 1,
        margin: '0.375rem',
    },
    maxWidth: '19rem',
    [props.theme.breakpoints.up('tablet')]: {
        maxWidth: '28.7rem'
    },
}))


const menuItem = (props) => ({
    ...(props.home && {
        backgroundColor: 'secondary.surfaceLight',
        color: 'secondary.contrastText',
        width: '8.625rem',
        height: '6.625rem',
        maxHeight: '15vh',
        fontSize: '1rem',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        transition: 'opacity 0.4s',
        padding: '0.375rem',
        margin: '0.375rem',
        overflow: 'hidden',
        '&.active, &:hover': {
            opacity: 0.8,
        },
        textAlign: 'center',
        lineHeight: '1.1rem',
        '> svg ': {
            marginBottom: '0.65625rem',
            width: '2.25rem',
            fill: (theme) => theme.palette.secondary.contrastText,
            path: {
                stroke: (theme) => theme.palette.secondary.contrastText,
            },
            maxHeight: '2.3625rem',
        },
        flexGrow: 0,
        flexShrink: 0
    }),
    ...(!props.home && {
        fontSize: '1.125rem',
        color: (theme) => theme.palette.primary.main,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        width: 'auto',
        height: 'auto',
        padding: '1.125rem 2rem',
        margin: 0,
        '> svg ': {
            marginBottom: 0,
            fill: (theme) => theme.palette.primary.main,
            width: '1.563rem',
            marginRight: '1.375rem'
        },
        '&.active, &:hover': {
            opacity: 1,
            color: (theme) => theme.palette.secondary.main,
            '> svg ': {
                fill: (theme) => theme.palette.secondary.main,
            },
        },
    }),
})


const MenuItemLink = styled(ButtonBase)(props => props.theme.sx({
    ...menuItem(props)
}))
MenuItemLink.defaultProps = { component: NavLink }

const ToggleLocaleItem = styled(ButtonBase)(props => props.theme.sx({
    ...menuItem(props),
}))
ToggleLocaleItem.defaultProps = { component: "div" }

const ScanQRItem = styled(ButtonBase)(props => props.theme.sx({
    ...menuItem(props),
}))

ScanQRItem.defaultProps = { component: "div" }

const Copyright = styled('span')(props => props.theme.sx({
    fontSize: '0.7rem',
    opacity: 0.4,
    position: 'absolute',
    bottom: '0.7rem',
    right: '0.7rem',    
    color: 'primary.contrastLight',
    zIndex: 10
}))

const CloseIconButton = styled(IconButtons.Cross)({
    position: 'absolute',
    right: '1.25rem',
    top: '1.438rem',
    width: '2.15rem'
})