import styled from '@emotion/styled/macro';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Autoplay, EffectFade, FreeMode, Mousewheel, Navigation, Pagination, Virtual } from 'swiper';
import 'swiper/css/bundle';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import IconButtons from './IconButtons';

export const SliderSlide = SwiperSlide
SliderSlide.displayName = 'SwiperSlide'

const ForwardedSlider = forwardRef(Slider)
export default ForwardedSlider

function Slider(props, ref) {
    const swiperRef = useRef()
    const [swiperKey, setSwiperKey] = useState(1)
    const navigationLeftRef = useRef()
    const navigationRightRef = useRef()

    // if (props.autoplay)
    //     swiperProps.autoplay = {
    //         delay: props.autoplay > 0 ? props.autoplay : 2000,
    //         disableOnInteraction: false,
    //     }

    // if (props.fade)
    //     swiperProps.effect = 'fade'

    useImperativeHandle(ref, () => ({
        slideTo(index, speed) {
            if (swiperRef.current)
                swiperRef.current.slideTo(index, speed ?? 400)
        },
        setTouchEnable(enabled) {
            if (!swiperRef.current)
                return
            swiperRef.current.allowTouchMove = enabled
            swiperRef.current.update()
        }
    }), [])

    const handleOnSwiper = (swiper) => {
        swiperRef.current = swiper

        setTimeout(() => {
            if (!swiperRef.current) 
                return
            swiperRef.current.params.navigation.prevEl = navigationLeftRef.current
            swiperRef.current.params.navigation.nextEl = navigationRightRef.current

            // Re-init navigation
            swiperRef.current.navigation.destroy()
            swiperRef.current.navigation.init()
            swiperRef.current.navigation.update()
        })
    }

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            const customClassName = props.bulletClassName && props.bulletClassName(index)
            return '<span class="' + className + (customClassName ? ' ' + customClassName : '') + '"></span>';
        }
    }

    useEffect(() => {
        if (swiperRef.current)
            swiperRef.current.update()
            
    }, [props.children])

    useEffect(() => {
        if (swiperRef.current && swiperRef.current && props.index !== swiperRef.current.realIndex) {
            swiperRef.current.slideTo(props.index)
        }
    }, [props.index])

    useEffect(() => {
        if (!swiperRef.current)
            return
        swiperRef.current.allowTouchMove = props.allowTouchMove === false ? false : true
        swiperRef.current.update()
    }, [props.allowTouchMove])

    return (
        <Layout ref={ref} className={props.className}>
            <SliderLayout mask={!props.nomask}>
                <Swiper
                    key={swiperKey}
                    onSwiper={handleOnSwiper}
                    onDestroy={() => swiperRef.current = null}
                    onSlideChange={(e) => {
                        if (props.onIndexChange)
                            props.onIndexChange(e.realIndex)
                    }}

                    watchOverflow={true}
                    effect={props.effect}
                    direction={props.direction ?? 'horizontal'}
                    modules={[Virtual, FreeMode, Mousewheel, EffectFade, Navigation, Pagination, Autoplay]}
                    initialSlide={props.index ?? 0}
                    centeredSlides={props.centeredSlides ?? false}
                    centerInsufficientSlides={true}
                    speed={props.speed > 0 ? props.speed : 1000}
                    loop={props.virtual !== true && props.loop === true && props.children.length > 1}
                    grabCursor={true}
                    spaceBetween={props.spaceBetween ?? 0}
                    simulateTouch={true}
                    resistance={props.resistance === false ? false : true}
                    allowTouchMove={props.allowTouchMove === false ? false : true}
                    slidesPerView={props.slidesPerView ?? 'auto'}
                    pagination={false}
                    // navigation={navigation}
                    navigationfor={props.navigation}
                    virtual={props.virtual === true ? true : false}
                    mousewheel={props.mousewheel === true ? true : false}
                    freeMode={props.freeMode === true ? true : false}>
                    
                    {props.children}
                </Swiper>
            </SliderLayout>

            {props.children.length > 1 && !props.disableNavigation &&
                <>
                    <NavigationController ref={navigationLeftRef}>
                        <NavigateLeft className={"slider-navigate slider-navigation-left"} navigationfor={props.navigation}></NavigateLeft>
                    </NavigationController>
                    <NavigationController ref={navigationRightRef}>
                        <NavigateRight className={"slider-navigate slider-navigation-right"} navigationfor={props.navigation}></NavigateRight>
                    </NavigationController>
                </>
            }
        </Layout>
    )
}

const Layout = styled('div')({
    width: '100%',
    height: '100%',
    position: 'relative',
})

const SliderLayout = styled('div', {
    shouldForwardProp: (prop) => prop !== "mask"
})(props => ({
    width: '100%',
    height: '100%',
    ...(props.mask && {
        //...styledHelper.opacityMask('to right', 6)
    }),
    // '.swiper-button-prev, .swiper-button-next': {
    //     display: 'none'
    // }
}))

const cellSwiperNavigation = (props, dir) => {
    return {
        top: 'calc(50% - 1.85rem)',
        right: dir === 'left' ? 'unset' : '0.5rem',
        left: dir === 'right' ? 'unset' : '0.5rem',
        opacity: 0.5
    }
}

const defaultSwiperNavigation = (props, dir) => {
    return {
        top: 'calc(50% - 1.4rem)',
        right: dir === 'left' ? 'unset' : '-3.75rem',
        left: dir === 'right' ? 'unset' : '-3.75rem'
    }
}

const swiperNavigationStyle = (props, dir) => {
    switch (props.navigationfor) {
        case 'cell':
            return cellSwiperNavigation(props, dir)
        case 'main':
            return defaultSwiperNavigation(props, dir)
        default:
            return defaultSwiperNavigation(props, dir)
    }
}

const NavigationController = styled('div')({
    transition: 'opacity 400ms',
    '&.swiper-button-disabled': {
        opacity: 0

    },
})

const NavigateRight = styled(IconButtons.Next)(props => props.theme.sx({
    position: 'absolute',
    zIndex: 3,    
    '&.Mui-disabled': {
        opacity: 0
    },
    '&.swiper-button-disabled': {
        opacity: 0

    },
    ...swiperNavigationStyle(props, 'right')
}))


const NavigateLeft = styled(IconButtons.Prev)(props => props.theme.sx({
    position: 'absolute',
    zIndex: 3,
    '&.Mui-disabled': {
        opacity: 0
    },
    '&.swiper-button-disabled': {
        opacity: 0
    },
    ...swiperNavigationStyle(props, 'left')
}))


const Swiper = styled(ReactSwiper)(props =>
    props.theme.sx({
        width: '100%',
        height: '100%',
        '.swiper-pagination': {
            zIndex: 2
        }
    })
)

