import styled from '@emotion/styled/macro';

export default function DefaultLayout(props) {
    return (
        <Layout>
            {props.header && <Header>{props.header}</Header>}
            {props.main && <Main>{props.main}</Main>}
            {props.footer && <Footer>{props.footer}</Footer>}
        </Layout>
    )
}

const Layout = styled('div')({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    alignItems: 'stretch',
    backgroundColor: 'transparent'
})


const Header = styled('div')({
    flexGrow: 0,
    position: 'relative',
})


const Footer = styled('div')({
    flexGrow: 0
})


const Main = styled('div')(props => props.theme.sx({
    flexGrow: 1,
    position: 'relative',
}))
