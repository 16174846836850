import { Portal } from '@mui/material';
import Helmet from './Helmet';
import { useIntl } from './hooks';
import Menu from './Menu';

export default
    function Home() {
    const { intl } = useIntl()

    return (
        <>
            <Helmet>
                <title>{intl.get('helmet.title.home')}</title>
            </Helmet>
            <Portal container={() => document.getElementById("homeMenuLayout")}>
                <Menu home></Menu>
            </Portal>
        </>
    )
}
