import styled from '@emotion/styled/macro';
import { ButtonBase } from '@mui/material';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
// import { useIsAvailable } from '../core/hooks';
import { Slider, SliderSlide } from './components/components';
import IconButtons from './components/IconButtons';
import Icons from './components/Icons';
import Player from './components/Player';
import styledHelper from './components/styled';
import { useMeasure } from 'react-use';

import { useFavorite, useIntl, useMediaQuery, useZoomPan } from './hooks';

const ForwardedBaseTag = forwardRef(BaseTag)
export default ForwardedBaseTag

function Gallery({ images, onZoomClick }) {
    const [galleryIndex, setGalleryIndex] = useState(0)
    const { is: isMediaQuery } = useMediaQuery()
    const isMobile = isMediaQuery.only('mobile')

    return (
        <GalleryLayout>
            <GallerySlider navigation="cell" slidesPerView="1" centeredSlides={true} index={galleryIndex} onIndexChange={(i) => setGalleryIndex(i)} disablePagination={true}>
                {images.map((image, index) =>
                    <GallerySlide src={image["@1x"]} key={index}>
                    </GallerySlide>
                )}
            </GallerySlider>
            <ZoomIconButton onClick={() => onZoomClick(galleryIndex)} />

        </GalleryLayout>
    )
}

const GalleryLayout = styled('div')(props => props.theme.sx({
    position: 'relative'
}))

const GallerySlider = styled(Slider)(props => props.theme.sx({
}))

const GallerySlide = styled(SliderSlide)(props => props.theme.sx({
    backgroundImage: 'url(' + props.src + ')',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    height: '18.4375rem',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    backgroundColor: 'primary.surface',
}))
GallerySlide.displayName = SliderSlide.displayName

const ZoomIconButton = styled(IconButtons.Plus)({
    position: 'absolute',
    opacity: 0.5,
    '> svg': {
        width: '2.65rem',
        height: '2.65rem',
    },
    right: '3.75rem',
    top: '1.438rem',
    zIndex: 10,
})

/**
 * 
 * Base Tag: base component for display a room, a display, an article, a filter...
 * Contains a title, an image, and tabs
 */
function BaseTag({ floating, tagApi, tagType, tagKey, initialTabKey, filter, locateTagKey, title, subTitle, description, imageUri, images, audioUri, sonnerieTime, videoUri, fromPath, tabs, children, ...rest }, ref) {
    const { intl } = useIntl()
    const { isFavorite, toggleFavorite } = useFavorite(tagType, tagKey)

    const tabContentKeys = [...(description?.length > 0 || children ? ['default'] : []), ...Object.keys(tabs ?? {})]
    const tabKeys = [...tabContentKeys, ...tagApi?.locate && locateTagKey ? ['locate'] : [], ...tagType ? ['favorite'] : []]
    const initialTabIndex = tabContentKeys.indexOf(initialTabKey)
    const [tabIndex, setTabIndex] = useState(initialTabIndex > -1 ? initialTabIndex : 0)
    const [mediaUri, setMediaUri] = useState(audioUri)
    const [media, toggleMedia] = useToggle(filter === 'audioguides') // auto play audio guide if filter is audio
    const [zoomedImageIndex, setZoomedImageIndex] = useState(-1)

    const audioAvailable = audioUri?.length > 0 //useIsAvailable(audioUri)
    const videoAvailable = videoUri?.length > 0 // useIsAvailable(videoUri)

    const topLimitRef = useRef()
    const [titlesLayoutRef, { height: titlesLayoutHeight }] = useMeasure()

    const playerRef = useRef()
    const [mediaLayoutStyle, mediaLayoutAnimate] = useSpring(() => ({ height: "0px", opacity: 0, config: config.default }), []);


    useEffect(() => {
        mediaLayoutAnimate({
            height: (media ? (playerRef.current?.inner?.offsetHeight ?? 0) : 0) + "px",
            opacity: media ? 1 : 0
        })
    }, [mediaLayoutAnimate, playerRef, media])


    const handleTabClick = (index) => () => {
        const tab = tabKeys[index]
        if (tab === 'locate') {
            tagApi.locate(locateTagKey)()
        }
        else if (tab === 'favorite') {
            toggleFavorite()
        } else {
            setTabIndex(index)
            const scrollTopLimit = topLimitRef.current.getBoundingClientRect().top

            if (ref?.current?.scrollTop > scrollTopLimit)
                ref.current.scrollTop = scrollTopLimit
        }
    }

    const handleMediaButtonClick = (uri) => (e) => {
        if (media) {
            toggleMedia(false)
            playerRef.current?.togglePlay(false)
        } else {
            if (mediaUri !== uri)
                setMediaUri(uri)

            toggleMedia(true)
            setTimeout(() => {
                playerRef.current?.seekTo(0)
                playerRef.current?.togglePlay(true)
            }, 100)

        }

        /*
        if (mediaUri === uri)
            toggleMedia()
        else {
            setMediaUri(uri)
            if (media) {
                toggleMedia(false)
            }
            setTimeout(() => {
                toggleMedia(true)
            }, 50)
        }
        playerRef.current?.seekTo(0)
        if (media)
            playerRef.current?.togglePlay(false)
        else setTimeout(() => {
            playerRef.current?.togglePlay(true)
        }, 300)*/
    }

    const handleZoomClick = (index) => {
        setZoomedImageIndex(index)
    }
    const zoomImageRef = useRef()
    const { style: zoomedImageStyle, reset: resetZoomedImage } = useZoomPan(zoomImageRef, 1, 3)

    const zoomedImageTransition = useTransition(zoomedImageIndex, {
        key: zoomedImageIndex,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 400 }
    })

    return (

        <Layout
            ref={ref}
            floating={floating}
            titlesLayoutHeight={titlesLayoutHeight}
            hasAudio={audioUri ? 1 : 0}
            hasZoomedImage={zoomedImageIndex > -1 ? 1 : 0}
            hasImage={images?.length > 0 || imageUri ? 1 : 0}>

            <TopLimit ref={topLimitRef} />

            {zoomedImageTransition((style, zoomedImageIndex) => {
                return zoomedImageIndex > -1 &&
                    <ZoomedImageLayout style={style}>
                        <TitlesLayout>
                            {title && <Title dangerouslySetInnerHTML={{ __html: title }}>
                            </Title>}

                            {subTitle && <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
                        </TitlesLayout>

                        <ZoomedImage style={zoomedImageStyle} ref={zoomImageRef} src={images?.[zoomedImageIndex]?.["@2x"]}>
                        </ZoomedImage>
                        <CloseZoomIconButton onClick={() => { resetZoomedImage(); setZoomedImageIndex(-1) }} />
                    </ZoomedImageLayout>
            })}

            {title && <TitlesLayout ref={titlesLayoutRef}>
                <Title dangerouslySetInnerHTML={{ __html: title }}>
                </Title>

                {subTitle && <SubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />}
            </TitlesLayout>
            }

            <MediasLayout>
                {images?.length > 0 && <Gallery images={images} onZoomClick={handleZoomClick} />}

                {videoAvailable && <Video muted autoPlay loop playsInline>
                    <source src={videoUri} type="video/mp4" />
                </Video>
                }
                {!videoAvailable && (!images?.length > 0 && imageUri) && <Image src={imageUri} />}

                {audioAvailable && sonnerieTime &&
                    <SonnerieIconButton audio={1} active={media ? 1 : 0} onClick={handleMediaButtonClick(audioUri)} time={sonnerieTime} />
                }
                {audioAvailable && !sonnerieTime &&
                    <AudioIconButton audio={1} active={media ? 1 : 0} onClick={handleMediaButtonClick(audioUri)} />
                }
                {
                    audioAvailable && (audioUri || videoUri) &&
                    <MediaContainer>
                        <MediaLayout style={mediaLayoutStyle} onEnded={() => setTimeout(() => toggleMedia(false), 400)}>
                            <MediaPlayer play={media} uri={mediaUri} ref={playerRef} />
                        </MediaLayout>
                    </MediaContainer>
                }
            </MediasLayout>

            {tabKeys?.length > 1 &&
                <Tabs slidesPerView={'auto'} centeredSlides={false} speed={150} disableNavigation={true} disablePagination={true}>
                    {tabKeys && tabKeys.map((tab, index) => {
                        let intlTabKey = 'BaseTag.tab.' + tab
                        if (tab === 'favorite')
                            intlTabKey += "." + (isFavorite ? 'true' : 'false')
                        return (
                            <Tab key={index} onClick={handleTabClick(index)}>
                                <TabContent active={(tab !== 'locate' && tab !== 'favorite') && (index === tabIndex || (tab === 'favorite' && isFavorite)) ? 1 : 0} >
                                    {{
                                        'default': <Icons.Infos />,
                                        'displays': <Icons.List />,
                                        'articles': <Icons.List />,
                                        'calibers': <Icons.List />,
                                        'locate': <Icons.Locate />,
                                        'favorite': <Icons.Favorite />,
                                    }[tab]}
                                    <span>{intl.get(intlTabKey).d(intlTabKey)}</span>
                                </TabContent>
                            </Tab>
                        )
                    })}

                </Tabs>
            }
            <TabsLayouts index={tabIndex} onIndexChange={(i) => setTabIndex(i)} disablePagination={true}>
                {tabContentKeys.map((k, index) => {
                    if (k === 'default') {
                        return (
                            <TabLayout active={index === tabIndex ? 1 : 0} key={0}>
                                {description && <Description dangerouslySetInnerHTML={{ __html: description }} />}
                                {children}
                            </TabLayout>
                        )
                    } else {
                        return (
                            <TabLayout active={index === tabIndex ? 1 : 0} key={k} >{tabs[k]}</TabLayout>
                        )
                    }
                })}

            </TabsLayouts>
        </Layout>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    height: '100%',
    position: 'relative',
    overflowY: 'overlay',
    overflowX: 'hidden',
    backgroundColor: 'primary.surface',
    ...(props.hasZoomedImage && {
        overflowY: 'hidden'
    }),
    [TitlesLayout]: {
        top: 0,
    },
    [Tabs]: {
        top: 'calc(' + props.titlesLayoutHeight + 'px + 1.95rem)'
    },
    ...(props.floating && {
        [TitlesLayout]: {
            top: 0,
            paddingTop: '2.688rem'
        },
        [Tabs]: {
            top: 'calc(' + props.titlesLayoutHeight + 'px + 3.70rem)'
        }
    }),
    ...(props.hasImage && {
        [MediasLayout]: {
            top: 'calc(' + props.titlesLayoutHeight + 'px - 8rem)'
        },
        [Tabs]: {
            top: 'calc(' + props.titlesLayoutHeight + 'px + 10.35rem)'
        },
        [TopLimit]: {
            top: '2.75rem',
        }
    }),
    ...(props.hasImage && props.floating && {
        [TopLimit]: {
            top: '5.25rem',
        }
    }),
    ...(!props.hasImage && props.hasAudio && {
        [MediasLayout]: {
            position: 'sticky',
            top: '100%',
            width: '100%',
            height: '0rem',
            overflow: 'visible',
        },
        [TabsLayouts]: {
            paddingBottom: '8rem'
        }
    })
}))

const TopLimit = styled('div')({
    position: 'sticky',
    // width: '100%',
    // border: 'solid 4px red',
    // zIndex: 30
})

const ZoomedImageLayout = styled(animated.div)(props => props.theme.sx({
    position: 'sticky',
    backgroundColor: 'primary.surface',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    zIndex: 10,
    [TitlesLayout]: {
        paddingTop: '2.688rem',
        backgroundColor: 'primary.surfaceLight'
    }
}))

const ZoomedImage = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    touchAction: 'none',
    width: '100%',
    height: '100%',
    left: '0%',
    top: '0%',
    backgroundImage: 'url(' + props.src + ')',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
}))

const CloseZoomIconButton = styled(IconButtons.Cross)({
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    width: '2.15rem',
    zIndex: 6
})

const MediasLayout = styled('div')(props => props.theme.sx({
    margin: '0 0',
    overflow: 'visible',
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    zIndex: 2,
    ...(props.hasAudio && {
        minHeight: '4rem'
    })
}))

const TitlesLayout = styled('div')(props => props.theme.sx({
    position: 'sticky',
    zIndex: 4,
    padding: '1rem 2rem 1rem 2rem',
    backgroundColor: 'primary.surface',
    [props.theme.breakpoints.up('tablet')]: {
        padding: '1rem  2.75rem 1rem 2.5rem',
    }
}))

const Title = styled('h2')(props => props.theme.sx({
    textAlign: 'center',
    fontSize: '1.375rem',
    fontWeight: 'bold',
    color: 'secondary.main',
    margin: 0,
    lineHeight: '1.525rem',

}))

const SubTitle = styled('h3')(props => props.theme.sx({
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: 'primary.main',
    zIndex: 4,
    margin: 0,
}))

const Video = styled('video')(props => props.theme.sx({
    height: '18.4375rem',
    width: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: 'primary.surface',
    [props.theme.breakpoints.up('tablet')]: {
        minWidth: '23.438rem',
    }
}))

const Image = styled('div')(props => props.theme.sx({
    backgroundImage: 'url(' + props.src + ')',
    backgroundSize: 'auto 100% ',
    backgroundPosition: 'center center',
    height: '18.4375rem',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    flexGrow: 0,
    flexShrink: 0,
    backgroundColor: 'primary.surface',
}))

const TabsLayouts = styled(Slider)(props => props.theme.sx({
    flexShrink: 1,
    flexGrow: 1,
    minWidth: '20rem',
    height: 'auto',
    zIndex: 0
}))

const TabLayout = styled(SliderSlide)(props => props.theme.sx({
    width: '100%',
    overflowX: 'hidden',
    flexDirection: 'column',
    alignItems: 'stretch',
    maxHeight: '1vh',
    overflowY: 'hidden',
    opacity: 0.8,
    backgroundColor: 'primary.surface',

    '&.swiper-slide-active': {
        opacity: 1,
        maxHeight: 'unset',
        overflowY: 'overlay',
    }
}))
TabLayout.displayName = SliderSlide.displayName

const Tabs = styled(Slider)(props => props.theme.sx({
    position: 'sticky',
    flexShrink: 0,
    flexGrow: 0,
    height: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '1.25rem 1.25rem',
    backgroundColor: 'primary.surface',
    zIndex: 4,
}))

const Tab = styled(SliderSlide)(props => props.theme.sx({
    width: 'auto',
    height: '2.325rem',
    margin: '0 0.15625rem',
    overflowY: 'overlay'
}))
Tab.displayName = SliderSlide.displayName


const TabContent = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== "active"
})(props => props.theme.sx({
    width: 'auto',
    display: 'flex',
    backgroundColor: 'primary.surface',
    alignItems: 'center',
    height: '2.125rem',
    transition: 'all 1s',
    fontSize: '0.8125rem',
    color: 'secondary.main',
    borderRadius: '1.25rem',
    '> span': {
        zIndex: 0,
        pointerEvents: 'none',
        paddingLeft: '0.4375rem'
    },
    'svg': {
        width: '1.125rem',
        fill: (theme) => theme.palette.secondary.main
    },
    border: 'solid 1px',
    borderColor: 'primary.main',
    ...(props.active && {
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        'svg': {
            width: '1.125rem',
            fill: (theme) => theme.palette.primary.surface
        }
    }),
    ...(props.audio && {
        flexGrow: 0,
        padding: '0 1.5rem',
        ...(props.active && {
            'svg': {
                fill: 'white'
            },
            backgroundColor: '#C1A3FF'

        })
    }),
    padding: '0 0.75rem'
}))


const Description = styled('div')(props => ({
    ...styledHelper.htmlContent(),
    overflow: 'auto',
    padding: '1rem 1.75rem',
}))

const MediaContainer = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    zIndex: 2,
    left: 0,
    bottom: '0rem',
    width: '100%',
    height: '0',
    overflow: 'visible'
}))

const MediaLayout = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    bottom: '0',
    height: '0',
    width: '100%'
    // overflow: 'hidden'
}))

const MediaPlayer = styled(Player)({
})

const SonnerieIconButton = styled(IconButtons.Sonnerie)(props => props.theme.sx({
    position: 'absolute',
    bottom: '3.25rem',
    right: '2.375rem',
    height: '5.6875rem',
    width: '5.6875rem',
    border: 'solid 1px',
    borderColor: 'primary.main',
    backgroundColor: 'primary.surfaceLight',
    padding: 0,
    zIndex: 3,
    '> svg': {
        fill: (theme) => theme.palette.primary.main,
        width: '9rem'
    },
    '&:hover, &:active': {
        backgroundColor: 'primary.contrastLight',
        '> svg': {
            fill: (theme) => theme.palette.primary.contrastText,
            width: '9rem'
        },
        '&:after': {
            color: 'primary.contrastText',
        }
    },

    ...(props.active && {
        backgroundColor: 'primary.contrastLight',
        '> svg': {
            fill: (theme) => theme.palette.primary.contrastText,
            width: '9rem'
        }
    }),
    ...(!props.active && {
        backgroundColor: (theme) => theme.palette.primary.surfaceLight,
        '> svg': {
            fill: (theme) => theme.palette.primary.main,
            width: '9rem'
        },
    }),
    '&:after': {
        position: 'absolute',
        top: '2.76rem',
        width: '100%',
        content: '"' + props.time + '"',
        fontSize: '0.8rem',
        color: props.active ? 'primary.contrastText' : 'primary.main'
    },

}))

const AudioIconButton = styled(IconButtons.Headphone)(props => props.theme.sx({
    position: 'absolute',
    bottom: '3.75rem',
    right: '1.125rem',
    height: '5.6875rem',
    width: '5.6875rem',
    border: 'solid 1px',
    borderColor: 'primary.main',
    backgroundColor: 'primary.surfaceLight',
    zIndex: 3,
    '> svg': {
        fill: (theme) => theme.palette.primary.main,
        width: '2.5rem'
    },
    '&:hover, &:active': {
        backgroundColor: 'primary.contrastLight',
        '> svg': {
            fill: (theme) => theme.palette.primary.contrastText,
            width: '2.5rem'
        },
    },
    ...(props.active && {
        backgroundColor: 'primary.contrastLight',
        '> svg': {
            fill: (theme) => theme.palette.primary.contrastText,
            width: '2.5rem'
        },
    }),
    ...(!props.active && {
        backgroundColor: (theme) => theme.palette.primary.surfaceLight,
        '> svg': {
            fill: (theme) => theme.palette.primary.main,
            width: '2.5rem'
        },
    })
}))

