import { Helmet as ReactHelmet } from 'react-helmet-async';


export default function Helmet({ children, breadcrumbsContainerId }) {
    const getNodeText = (node) => {
        let children = Array.isArray(node?.props?.children) ? node?.props?.children : [node?.props?.children]
        return children?.reduce((acc, c) => {
            if (typeof (c) === 'object') {
                acc += getNodeText(c)
            } else if (c)
                acc += ("" + c)
            return acc
        }, "")
    }

    const _children = children ? (Array.isArray(children) ? children : [children]).reduce((acc, c, index) => {
        if (c.type === 'header') {
            if (!acc['header'])
                acc['header'] = []
            const navList = getNodeText(c)
            acc['header'].push(<meta key={index} name="header" value={navList} />)
        } else if (c.type === 'title') {
            if (!acc['helmet'])
                acc['helmet'] = []
            acc['helmet'].push(c)
        }
        return acc
    }, {}) : {}


    return <>
        <ReactHelmet>
            {_children['helmet']}
            {_children['header']}
        </ReactHelmet>
    </>
}
