import styled from '@emotion/styled/macro';
import { forwardRef } from 'react';
import DisplayTag, { useDisplayStyle } from './display/DisplayTag';
import POITag, { usePOIStyle } from './poi/POITag';
import RoomTag, { useRoomStyle } from './room/RoomTag';

export default forwardRef(FloorTag)

export function useFloorTagStyles(scale, scaleThreshold) {
    const { styles: displayStyles, setScale: displaySetScale } = useDisplayStyle(scale, scaleThreshold)
    
    const { styles: POIStyles, setScale: POISetScale } = usePOIStyle(scale, scaleThreshold)

    const { styles: roomStyles, setScale: roomSetScale } = useRoomStyle(scale, scaleThreshold)

    const setScale = (scale) => {
        displaySetScale(scale)
         roomSetScale(scale)
    }

    return {
        setScale,
        tagsStyles: {
            display: displayStyles,
            POI: POIStyles,
            room: roomStyles
        }
    }
}

function FloorTag({ tag, tagKey, scale, viewScale, open, active, tagsStyles, ...rest }, ref) {
    
    return (
        <Layout open={open} ref={ref} {...rest} translate={{ tx: tag.Position[0] * viewScale.x, ty: tag.Position[1] * viewScale.y }} rotate={tag.Rotate ?? 0}>
            {{
                'Display': <DisplayTag active={active ? 1 : 0} tagKey={tagKey} scale={scale} tag={tag} tagStyles={tagsStyles.display} />,
                'Room': <RoomTag viewScale={viewScale} active={active ? 1 : 0} tagKey={tagKey} scale={scale} tag={tag} tagStyles={tagsStyles.room} />,
                '_POI': <POITag active={active ? 1 : 0} tagKey={tagKey} scale={scale} tag={tag} tagStyles={tagsStyles.POI} />
            }[tag.Type]}    
        </Layout>
    )
}

const Layout = styled('div')(props => ({
    transform: 'translate(calc(' + (0.4 + props.translate.tx )+ 'px - 100%), calc(' + (props.translate.ty + 1) + 'px - 100%)) rotate(' + props.rotate + 'deg)', 
    backfaceVisibility: 'hidden',
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: '1px',
    height: '1px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,    
    '> *': {
        pointerEvents: 'initial'
    },
    transformOrigin: 'center center'
}))
