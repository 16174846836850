import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import IntlPolyfill from "intl";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import { useMediaQueryObserver } from './hooks';
import RoutedApp from './RoutedApp';
import theme from './theme';

// For Node.js, common locales should be added in the application
global.Intl = IntlPolyfill;

export default function App() {

  return (
    <HelmetProvider>
      <RecoilRoot>
        <RecoilApp></RecoilApp>
      </RecoilRoot>
    </HelmetProvider>
  )
}

function RecoilApp() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Observers />
      <RoutedApp />
    </ThemeProvider>
  )
}


function Observers() {
  useMediaQueryObserver()
  return (<></>)
}
