import styled from '@emotion/styled/macro';
import BaseAltTag from '../BaseAltTag';
import BaseTag from '../BaseTag';

import { useArticles, useCalibers, useDisplays, useIntl, useMap, useTagsExtension } from '../hooks';


export default function Caliber({ floating, journeyKey, filter, altTag, tagApi, caliberKey, displayKey, onSelect, onSelectAudio, fromPath, ...rest }) {
    const { calibers } = useCalibers()
    const caliber = calibers?.[caliberKey]
    return altTag ? <Alt onSelect={onSelect} caliber={caliber} filter={filter} displayKey={displayKey} caliberKey={caliberKey} /> :
        <Main floating={floating} caliber={caliber} caliberKey={caliberKey} displayKey={displayKey} filter={filter} tagApi={tagApi} fromPath={fromPath} onSelect={onSelect} />
}


function Alt({ caliber, onSelect, caliberKey, displayKey, filter }, ref) {
    return <BaseAltTag filter={filter} onSelect={onSelect} tagType='Display' tagKey={displayKey} childType='Caliber' childKey={caliberKey} imageUri={caliber?.Images?.[0]?.["@1x"]} name={caliberKey} />
}

function Main({ floating, journeyKey, filter, displayKey, caliberKey, caliber, tagApi, fromPath, onSelect }) {
    const { locale, intl } = useIntl()

    let initialTabKey = null
    let imageUri = caliber?.Images?.[0]?.["@1x"]

    return (
        <BaseTag
            floating={floating}
            tagType={'Caliber'}
            tagKey={caliberKey}
            journeyKey={journeyKey}
            filter={filter}
            initialTabKey={initialTabKey}
            tagApi={tagApi}
            title={caliberKey}
            //subTitle={caliber.Type[locale]?.[0]}
            fromPath={fromPath}
            imgUri={imageUri}
            images={caliber.Images}
            audioUri={caliber.AudioUri?.[locale]}
            locateTagKey={displayKey}
            fromPathTagKey={displayKey}
            tabs={{
            }}
        >
            <Attributes>
                {caliber.Type?.[locale]?.length > 0 && <Attribute dangerouslySetInnerHTML={{ __html: caliber.Type?.[locale][0] }} type={1}></Attribute>}
                <Attribute>{caliber.Main[locale]}</Attribute>
                {caliber.Footer?.[locale]?.length > 0 && <Attribute>{caliber.Footer[locale].reduce((acc, att) => { return acc + intl.get('global.join', { text: att }) }, "")}</Attribute>}
            </Attributes>
        </BaseTag>
    )
}


const Attributes = styled('div')(props => props.theme.sx({
    padding: '1rem 2rem 1rem 2rem',

}))

const Attribute = styled('div')(props => props.theme.sx({
    display: 'block',
    marginBottom: '0.45rem',
    ...(props.title && {
        color: 'secondary.main'
    }),
    ...(props.type && {
        fontStyle: 'italic',
        fontWeight: 'bold'
    }),
    "sup": {
        fontSize: '0.6rem'
    }
}))

