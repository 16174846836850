import styled from '@emotion/styled/macro';
import { NavLink } from 'react-router-dom';
import Breadcrumb, { BreadcrumbItem } from './Breadcrumb';
import { HeaderBackground } from './Header';
import Helmet from './Helmet';
import { useIntl } from './hooks';

export default
    function Default({ title, header, children, searchActive }) {
    
        const { intl } = useIntl()

    return (
        <>
            <Helmet>
                <title>{title}</title>
                <header>{header}</header>
            </Helmet>
            <HeaderBackground opaque={true} />
            <Breadcrumb opaque={true}>
                <BreadcrumbItem>
                    <NavLink to="/">{intl.get('Menu.home')}</NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem disabled>
                    {title}
                </BreadcrumbItem>
            </Breadcrumb>
            <Layout searchActive={searchActive}>
                <ContentLayout>
                    {children}
                </ContentLayout>
            </Layout>
        </>

    )
}

const Layout = styled('div')(props => props.theme.sx({
    marginTop: '5.95rem',
    transition: 'all 0.5s',
    height: 'calc(100% - 5.95rem)',
    width: '100%',
    backgroundColor: 'secondary.main',
    color: 'secondary.contrastText',
    overflowY: 'auto',
    [props.theme.breakpoints.down('tablet')]: {
        ...(props.searchActive && {
            marginTop: '9.85rem',
            height: 'calc(100% - 9.85rem)'
        })
    }
}))


const ContentLayout = styled('div')(props => props.theme.sx({
    width: '100%',
    'div > p, p': {
        fontSize: '1rem',
        textAlign: 'center',
        color: 'primary.contrastText',
        marginBottom: '0',
        padding: '0',
    },
    '.strong': {
        fontSize: '1.25rem',
        lineHeight: '1.625rem',
        margin: '0'
    },
    '.weak': {
        fontSize: '0.75rem',
    },
    '.primary': {
        color: 'primary.main'
    },
    '.marginTop2500': {
        marginTop: '2.5rem'
    },
    '.marginTop1875': {
        marginTop: '1.875rem'
    },
    '.marginTop0750': {
        marginTop: '0.75rem'
    },
    '.marginTop0375': {
        marginTop: '0.375rem'
    },
    '.marginTop0175': {
        marginTop: '0.175rem'
    },
    padding: '3rem',
    margin: 0,

}))

