import { alpha, createTheme } from '@mui/material/styles';
import { css } from '@mui/styled-engine';


const colors = {
    white: "#ffffff",
    cream: "#E6E1DB",
    marron: "#463527",
    orange: "#F95112",
    grey: "#707070",
    grey2: "#C5C5C5",
    camel: "#9A7A69",
    red: "#D71120",
    purple: "#472682",
    lightCamel: "#9A7A69BF",
    lightPurple: "#4726824D",
}

const theme = createTheme({
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 640,
            laptop: 1024,
            desktop: 1200,
            largeDesktop: 1900
        },
    },
    palette: {
        type: 'dark',
        primary: {
            main: colors.camel,
            mainLight: colors.lightCamel,
            contrastText: colors.white,
            surface: colors.white,
            surfaceLight: alpha(colors.white, 0.75),
            surfaceSemiLight: alpha(colors.white, 0.95),
            surfaceDark: alpha("#000000", 0.68),
            contrastLight: colors.lightPurple,
            test: alpha(colors.purple, 0.2)
        },
        secondary: {
            main: colors.purple,
            contrastText: colors.white,
            surfaceLight: alpha(colors.white, 0.35),
            constrastLight: alpha(colors.white, 0.35),
            surfaceContrastLight: alpha(colors.purple, 0.03),
        }
    },
    typography: {
        allVariants: {
            // fontFamily: [
            //     '"Open Sans"'
            // ].join(', "Arial"'),
        },
        mainTitle: {
            fontSize: 30,
            color: colors.white,
            fontWeight: 'normal'
        },
        title: {
            lineHeight: 1.6,
            fontSize: 20,
            color: colors.white,
        },
        subTitle: {
            lineHeight: '17px',
            fontSize: 13,
            color: colors.white,
        },
        titleSmall: {
            lineHeight: 1.6,
            fontSize: 10,
            color: colors.white,
        },
        caption: {
            lineHeight: 1.6,
            fontSize: 15,
            color: colors.white,
            fontStyle: 'italic'
        }

    },
    layout: {
        main: {
            background: 'radial-gradient(120% 20px at 50% -7px, ' + alpha(colors.camel, 1) + ', transparent), radial-gradient(120% 70px at center calc(100% + 7px), ' + alpha(colors.camel, 0.1) + ', transparent)', //, radial-gradient(ellipse at bottom, #333333, transparent)',
        },
        global: {
            separatorShadow: 'radial-gradient(100% 30px at 50% 0%, ' + alpha(colors.camel, 0.07) + ', transparent)',
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': css`
                    font-family: 'Arial';
                    font-style: normal;
                    font-weight: 400;
                `,

                html: {
                    background: '#472682',
                    height: '100%',    
                    overflowBehavior: 'none'                
                  //  fontSize: '16px', // 16pt   
                },
                body: {
                    overflowBehavior: 'none',
                    // position: 'fixed',
                    // overflow: 'hidden',
                    background: "#472682",
                    fontFamily: [
                        'Arial'
                    ].join(', "Arial"'),
                    width: '100%',
                    height: '100%',
                    color: colors.camel,
                    // overflow: 'hidden',
                    //position: 'fixed',
                    // '@supports (-webkit-touch-callout: none)': {
                    //     height: '-webkit-fill-available'
                    // },
                    '#root': {
                        height: '100%',
                        width: '100%',
                    },
                    "*": {
                        // '-webkit-tap-highlight-color': 'transparent'
                        WebkitTapHighlightColor: 'transparent'
                    }
                }
            }
        }
    }
})

//console.info(theme)
theme.sx = theme.unstable_sx
export default theme