import styled from '@emotion/styled/macro';
import { animated } from '@react-spring/web';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { act } from 'react-dom/test-utils';
import { ReactSVG } from 'react-svg';
import { useMeasure } from 'react-use';
import EventComponents from '../event/Components';
import FloorTag, { useFloorTagStyles } from './FloorTag';
import { useInteractiveFloor, useMediaQuery } from './hooks';

const ForwardedBaseFloor = forwardRef(BaseFloor)
export default ForwardedBaseFloor

function BaseFloor({ floor, handlers, cursor, journeyPath }, ref) {
    const [floorLayoutRef, { width: floorLayoutWidth, height: floorLayoutHeight }] = useMeasure()
    const [floorLayersViewScale, setFloorLayersViewScale] = useState({ x: 0, y: 0 })
    const floorLayersRef = useRef()
    const pathRef = useRef()
    const fromPathRef = useRef()
    const journeyPathRef = useRef()
    const centerRef = useRef()

    const [fromPath, setFromPath] = useState([])
    const [path, setPath] = useState([])

    const layoutRef = useRef()

    const [floorLayerChildScale, setFloorLayerChildScale] = useState(5)

    const scaleRef = useRef(1)
    const tagsRefs = useRef({})
    const [activeTag, setActiveTag] = useState(null)
    const tagsLayerRef = useRef()
    const mapSvgRef = useRef()

    const onInteractionStart = (c) => {
        floorLayersRef.current.style.cursor = 'grabbing'
        handlers?.onInteractionStart && handlers.onInteractionStart(c)
    }


    const onInteractionChange = (c) => {
        const scale = Math.round(c.scale * floorLayerChildScale * 10) / 10 //Math.floor(c.scale * floorLayerChildScale)
        if (scale !== scaleRef.current) {
            scaleRef.current = scale
            mapSvgRef.current?.setScale(scale)
            tagsLayerRef.current?.setScale(scale)
        }
    }

    const onInteractionEnd = (c) => {
        floorLayersRef.current.style.cursor = 'initial'
        handlers?.onInteractionEnd && handlers.onInteractionEnd(c)
    }

    let initialScale = 1.4 / floorLayerChildScale
    let minScale = 0.6 * initialScale
    let maxScale = 9*initialScale

    if (window.innerWidth >= 1200) {
        initialScale = 0.8 / floorLayerChildScale
        minScale = 0.8*initialScale
        maxScale = 5*initialScale
    } else if (window.innerWidth >= 1000) {
        initialScale = 0.9 / floorLayerChildScale
        minScale = 0.8*initialScale
        maxScale = 5*initialScale
    } else if (window.innerWidth >= 800) {
        initialScale = 1 / floorLayerChildScale
        minScale = 0.7*initialScale
        maxScale = 6*initialScale
    } else if (window.innerWidth >= 640) {
        initialScale = 1.1 / floorLayerChildScale
        minScale = 0.7*initialScale
        maxScale = 7*initialScale
    }

    // * (1400/ window.innerWidth) / floorLayerChildScale
    const { layersStyle: floorLayersStyle, layerStyle: floorLayerStyle, api: floorApi } =
        useInteractiveFloor(floorLayersRef, initialScale, minScale, maxScale, onInteractionStart, onInteractionChange, onInteractionEnd)

    useEffect(() => {
        if (floorLayoutWidth > 0) {
            const isVerticalFormat = floorLayoutWidth / floorLayoutHeight >= floor.Dimensions[0] / floor.Dimensions[1]
            const x = isVerticalFormat ? floorLayoutHeight / floor.Dimensions[1] : floorLayoutWidth / floor.Dimensions[0]
            const y = isVerticalFormat ? floorLayoutHeight / floor.Dimensions[1] : floorLayoutWidth / floor.Dimensions[0]
            //    console.info(isVerticalFormat, floorLayoutWidth, floorLayoutHeight,  floor.Dimensions, x, y)
            setFloorLayersViewScale({ x: x, y: y })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorLayoutWidth, floorLayoutHeight])

    const handleTagClick = (type, key) => (e) => {
        handlers?.onTagClick(type, key, e)
    }

    const handleLayersClick = (e) => {
        handlers?.onLayersClick(e)
    }

    const pathCenter = (path) => {
        if (path?.length > 0) {
            let refCount = 0

            let pathCenter = path.reduce((acc, k) => {
                const tagKey = Object.keys(floor.Tags).find(t => floor.Tags[t].Node === k)
                if (tagsRefs.current[tagKey]) {
                    const { width, height, x, y } = tagsRefs.current[tagKey].getBoundingClientRect()
                    acc[0] += x + width / 2
                    acc[1] += y + height / 2
                    refCount++
                }
                return acc
            }, [0, 0])

            pathCenter[0] /= refCount
            pathCenter[1] /= refCount

            return pathCenter
        }
        return null
    }

    useImperativeHandle(ref, () => ({
        setPath(from, path) {
            if (from)
                setFromPath(path)
            else
                setPath(path)
        },
        animatePath(from) {
            (from ? fromPathRef : pathRef).current.animatePath()
        },
        setStepNode(from, node, { splitDone = false }) {
            (from ? fromPathRef : pathRef).current.setStepNode(node, splitDone)
        },
        setJourneyStepNode(node) {
            journeyPathRef.current?.setStepNode(node)
        },
        testAnimatedPath(from) {
            (from ? fromPathRef : pathRef).current.testAnimated()
        },
        ensureVisibility(key, element, { scale = null }) {
            if (element && tagsRefs.current[key]) {
                floorApi.ensureVisibilityElement(tagsRefs.current[key], element, scale > 0 ? scale / floorLayerChildScale : null)

                return true
            }
            return false
        },
        focusTag(key, { target = null, center = false, scale = 1.5 }) {
            if (!tagsRefs.current[key])
                return false
            if (center || target) {
                const scaleThreshold = getScaleThreshold(floorLayersViewScale) * scale * 1.3
                setTimeout(() => {
                    floorApi.centerElement(tagsRefs.current[key], target, scaleThreshold / floorLayerChildScale)
                }, 100)
            } else {
                setTimeout(() => floorApi.scaleElement(tagsRefs.current[key], scale / floorLayerChildScale), 0)
            }
            return true
        },
        centerAt(a, b, scale) {
            floorApi.centerAt(a, b, scale / floorLayerChildScale)
        },
        center(scale = 1.3) {
            floorApi.center(scale / floorLayerChildScale)
        },
        findTagAt(a, b) {
            let tagKey = null
            let distance = -1

            Object.keys(tagsRefs.current).forEach(k => {
                if (floor.Tags?.[k].Node && floor.Graph.Nodes[floor.Tags[k].Node]) {
                    const { width, height, x, y } = tagsRefs.current[k].getBoundingClientRect()

                    let nodeDistance = Math.sqrt(Math.pow(width / 2 + x - a, 2) + Math.pow(height / 2 + y - b, 2))
                    if (distance === -1 || nodeDistance < distance) {
                        distance = nodeDistance
                        tagKey = k
                    }
                }
            })

            return tagKey
        },
        centerPath(path, scale) {
            const _pathCenter = pathCenter(path)
            if (_pathCenter)
                floorApi.centerAt(_pathCenter[0], _pathCenter[1], scale / floorLayerChildScale)
        },
        ensurePathVisibility(element, path, { scale } = { scale: null }) {
            const _pathCenter = pathCenter(path)
            if (element && _pathCenter) {
                floorApi.ensureVisiblityAt(_pathCenter[0], _pathCenter[1], element, scale > 0 ? scale / floorLayerChildScale : null)
                return true
            }
            return false
        },
        setActiveTag(key) {
            setActiveTag(key)
        },
        inner: layoutRef.current // example, by convention 

    }))
    return (
        <Layout ref={layoutRef}>
            <Infos>{activeTag} {floorLayerChildScale}
            </Infos>
            {floor &&
                <FloorLayout ref={floorLayoutRef}>
                    <FloorLayers cursor={cursor} style={floorLayersStyle} onClick={handleLayersClick}
                        ref={floorLayersRef} width={floorLayersViewScale.x * floor.Dimensions[0]} height={floorLayersViewScale.y * floor.Dimensions[1]}>
                        <FloorLayer uri={floor.Uri} style={floorLayerStyle}>
                            <ForwardedMapSvg id="map-svg" activeTag={activeTag} ref={mapSvgRef} floorLayersViewScale={floorLayersViewScale} floorLayerChildScale={floorLayerChildScale} floor={floor} src={floor.SvgUri} />
                            <ForwardedTagsLayer ref={tagsLayerRef} floorLayersViewScale={floorLayersViewScale}
                                floorLayerChildScale={floorLayerChildScale} floor={floor}
                                activeTag={activeTag} handleTagClick={handleTagClick}
                                tagsRefs={tagsRefs} />
                            {floorLayersViewScale.x > 0 && Object.keys(floor.Components)?.length > 0 &&
                                <FloorLayerEventComponents scale={floorLayerChildScale}>
                                    {Object.keys(floor.Components).map((fc, i) => {
                                        const EventComponent = EventComponents[fc]
                                        if (EventComponent)
                                            return <EventComponent key={i} scale={floorLayerChildScale} viewScale={{ x: floorLayersViewScale.x * floorLayerChildScale, y: floorLayersViewScale.y * floorLayerChildScale }}  {...floor.Components[fc]} />
                                        return <></>
                                    })}
                                </FloorLayerEventComponents>
                            }
                        </FloorLayer>
                    </FloorLayers>
                </FloorLayout>
            }
        </Layout>
    )
}

const ForwardedMapSvg = forwardRef(MapSvg)

const getScaleThreshold = (floorLayersViewScale) => {
    let coeff = Math.max(1.2, 0.8 * 2000 / window.innerWidth)
    // if (window.innerWidth < 640)
    //     coeff = 1.8
    // else if (window.innerWidth < 1024)
    //     coeff = 1.7
    return coeff  /// Math.pow(floorLayersViewScale.x, 1)
}


const getScaleThresholdOLD = (floorLayersViewScale) => {
    let coeff = 1.9
    if (window.innerWidth < 640)
        coeff = 1.8
    else if (window.innerWidth < 1024)
        coeff = 1.7
    let c = coeff * Math.pow(floorLayersViewScale.x, 1)
    return c //coeff  /// Math.pow(floorLayersViewScale.x, 1)
}

function MapSvg({ className, floorLayerChildScale, floorLayersViewScale, floor, activeTag, src }, ref) {
    const scaleRef = useRef()
    const scaleThreshold = getScaleThreshold(floorLayersViewScale)

    const updateMapToScale = () => {
        const roomNodes = document.querySelectorAll("#map-svg #Rooms > g")
        roomNodes.forEach(rn => rn.style.opacity = (scaleRef.current > scaleThreshold || floor?.Tags[activeTag]?.SvgId === rn.id) ? 1 : 0)
    }
    useImperativeHandle(ref, () => ({
        setScale(scale) {
            scaleRef.current = scale
            updateMapToScale()
        }
    }))


    useEffect(() => {
        updateMapToScale()
    }, [activeTag])


    return (
        <ReactSvgMap id="map-svg" className={className} scale={floorLayerChildScale} src={src} />
    )
}

const ForwardedTagsLayer = forwardRef(TagsLayer)

function TagsLayer({ floorLayersViewScale, floorLayerChildScale, floor, activeTag, handleTagClick, tagsRefs, theme }, ref) {
    const scaleThreshold = getScaleThreshold(floorLayersViewScale)
    const { setScale, tagsStyles } = useFloorTagStyles(1, scaleThreshold)
    const scaleRef = useRef(-1)

    useImperativeHandle(ref, () => ({
        setScale(scale) {
            setScale(scale)

            // if (scaleRef.current === -1) {
            //     setScale(scale)
            // } else if (scaleRef.current >= scaleThreshold && scale < scaleThreshold) {
            //     scaleRef.current = scale
            //     setScale(scale)
            // } else if (scaleRef.current < scaleThreshold && scale >= scaleThreshold) {
            //     scaleRef.current = scale
            //     setScale(scale)
            // }
            scaleRef.current = scale
        }
    }))
    return (
        <>
            {floorLayersViewScale.x > 0 && <FloorLayerTags scale={floorLayerChildScale}>
                {Object.keys(floor.Tags).map(k => {
                    return (
                        <FloorTag tagsStyles={tagsStyles} active={activeTag === k || k === floor.Tags[activeTag]?.Parent ? 1 : 0} key={k}
                            onClick={handleTagClick(floor.Tags[k].Type, k)} ref={el => tagsRefs.current[k] = el}
                            viewScale={{ x: floorLayersViewScale.x * floorLayerChildScale, y: floorLayersViewScale.y * floorLayerChildScale }} tagKey={k} tag={floor.Tags[k]} />
                    )
                })}
            </FloorLayerTags>
            }
        </>
    )
}

const Layout = styled(animated.div)(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'relative',
    backfaceVisibility: 'hidden',

}))

const Infos = styled('div')({
    display: 'none',
    position: 'fixed',
    bottom: 0,
    left: 0,
    color: 'red',
    fontSize: '20px',
    zIndex: 100
})

const FloorLayout = styled('div')(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '-0%',
    left: '-0%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4D008C26'
}))

const FloorLayers = styled(animated.div)(props => props.theme.sx({
    touchAction: 'none',
    width: props.width + 'px',
    height: props.height + 'px',
    position: 'relative',
    perspective: '150cm',
    '&:before': {
        content: '""',
        position: 'fixed',
        top: '-2000%',
        left: '-2000%',
        height: '4000%',
        width: '4000%',
    }
}))

const FloorLayer = styled(animated.div)(props => props.theme.sx({
    touchAction: 'none',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    // perspective: '30rem',
    // transform: 'rotateX(0.0000000000001deg)',
    opacity: '1',
    backfaceVisibility: 'hidden'
}))

const _floorLayerChild = (props) => {
    return {
        position: 'absolute',
        top: (-50 * props.scale + 50) + '%',
        left: (-50 * props.scale + 50) + '%',
        width: props.scale * 100 + '%',
        height: props.scale * 100 + '%',
    }
}

const ReactSvgMap = styled(ReactSVG)(props => ({
    zIndex: 1,
    ..._floorLayerChild(props),
    '#Rooms > g': {
        opacity: 0,
        transition: 'all 1s'
    }
}))


const FloorLayerTags = styled('div')(props => props.theme.sx({
    zIndex: 3,
    ..._floorLayerChild(props),
    // transform: 'translateZ(300px)'
}))


const FloorLayerEventComponents = styled('div')(props => props.theme.sx({
    zIndex: 1,
    ..._floorLayerChild(props),
    opacity: 1
}))

