import { useNavigate, useSearchParams } from 'react-router-dom';
import BaseAltTag from '../BaseAltTag';
import BaseTag from '../BaseTag';

import { useEffect, useRef } from 'react';
import { useDisplays, useIntl, useMap, useRooms, useTagsExtension } from '../hooks';
import Tags from '../Tags';

export default function Room({ floating, journeyKey, filter, altTag, tagApi, roomKey, onSelect, onSelectAudio, fromPath, ...rest }) {
    const { rooms } = useRooms()
    const room = rooms?.[roomKey]
    const navigate = useNavigate()

    useEffect(() => {
        if (!room) {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room])

    return altTag ? <Alt onSelect={onSelect} onSelectAudio={onSelectAudio} filter={filter} room={room} roomKey={roomKey}  /> :
        <Main floating={floating} onSelect={onSelect} journeyKey={journeyKey} filter={filter} room={room} roomKey={roomKey} tagApi={tagApi} fromPath={fromPath} />
}

function Alt({ room, roomKey, onSelect, onSelectAudio, filter }) {
    const { locale } = useIntl()
    return <BaseAltTag onSelect={onSelect} onSelectAudio={onSelectAudio} filter={filter} audioUri={room?.AudioUri?.[locale]} tagKey={roomKey} tagType="Room" displayTagKey={room?.DisplayTagKey??roomKey} name={room?.Name[locale]} />
}

function Main({ floating, journeyKey, filter, roomKey, room, tagApi, fromPath, onSelect }) {
    const { locale } = useIntl()
    const { map } = useMap()
    const baseTagRef = useRef()
    const { tagsFromKeys } = useTagsExtension()
    const [searchParams] = useSearchParams()
    const displayKey = searchParams.get('d')
    const floor = Object.values(map?.Floors ?? {})?.[0]

    const { displays } = useDisplays()
    const displayKeys = Object.keys(displays).filter(k => displays[k].Room === roomKey).map(k => k)
    const { keys: childKeys, tags: childTags } = tagsFromKeys(floor, displayKeys)
    return (
        <>
            {room &&
                <BaseTag
                    floating={floating}
                    ref={baseTagRef}
                    tagType={'Room'}
                    tagKey={roomKey}
                    journeyKey={journeyKey}
                    filter={filter}
                    tagApi={tagApi}
                    title={room?.Name[locale]}
                    description={room?.Description[locale]}
                    fromPath={fromPath}
                    imageUri={room.ImageUri}
                    locateTagKey={roomKey} fromPathTagKey={roomKey}
                    audioUri={room.AudioUri?.[locale]}
                    videoUri={room.VideoUri?.[locale]}
                    initialTabKey={displayKey ? 'displays' : null}
                    tabs={displayKeys.length > 0 ? {
                        'displays': (
                            <Tags inline scrollRef={baseTagRef} keys={childKeys} tags={childTags} onSelect={onSelect} focusedTagKey={displayKey} />
                        )
                    } : null}
                />
            }
        </>

    )
}
