import axios from 'axios';
import { useState } from 'react';
import { useEffectOnce } from 'react-use';

class _axiosApi {

    fetch = (url, query = {}, body = {}, header = {}, method = 'get', onProgress = null, responseType = null, propertyMap = {}, id = null) => {
        // set url according to base path
        if (url.startsWith('/') && !url.startsWith(process.env.PUBLIC_URL))
            url = process.env.PUBLIC_URL + url
        if (id)
            url += "/" + id
        return new Promise((resolve, reject) => {

            const queryString = typeof query === 'object' ? Object.keys(query).map(function (key) {
                return key + '=' + encodeURIComponent(query[key]);
            }).join('&') : query;

            if (queryString.length > 0)
                url += (typeof query === 'object' ? "?" + queryString : ('/' + queryString))

            if (body && body !== Object(body))
                body = "=" + body

            axios.request({
                method: method,
                url: url,
                data: body,
                headers: header, //{...header, 'Accept-Encoding': 'utf-8'},
                responseType: responseType,
                onDownloadProgress: (p) => {
                    let percentCompleted = p.total > 0 ? Math.round(p.loaded * 100 / p.total) : 100
                    if (onProgress)
                        onProgress(percentCompleted)
                },
                onUploadProgress: (p) => {
                    let percentCompleted = p.total > 0 ? Math.round(p.loaded * 100 / p.total) : 100
                    if (onProgress)
                        onProgress(percentCompleted)
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                if (error && error.response) {

                    const { response } = error
                    if (response.data) {
                        const apiErrors = this.getApiErrors(response.data, propertyMap)
                        if (apiErrors != null)
                            reject({ hasApiError: true, apiErrors: apiErrors, error: null, })
                    }
                }
                reject({ hasApiError: false, error: error })

            })
        })
    }

    getApiErrors = (jsonApi, propertyMap) => {
        let apiErrors = null
        if (jsonApi.ExceptionMessage) {
            apiErrors = {}
            let match = jsonApi.ExceptionMessage.match(/(.*)\r\nNom du paramètre\s:\s(.*)/, "mi");
            if (match == null)
                match = jsonApi.ExceptionMessage.match(/(.*)\r\n\Parameter name:\s(.*)/, "mi");
            if (match != null && match.length > 2) {
                let property = match[2];
                const propertyError = match[1];

                if (propertyMap && propertyMap[property])
                    property = propertyMap[property]

                apiErrors[property] = propertyError
            } else {
                apiErrors.globalError = jsonApi.ExceptionMessage //<FormattedMessage id={jsonApi.ExceptionMessage} defaultMessage={jsonApi.ExceptionMessage} />
            }
        }

        return apiErrors
    }
}

const axiosApi = new _axiosApi()


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function useApi(url, method = 'get', successCallback = null, errorCallback = null, responseType = null) {

    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [data, setData] = useState(null);

    const onProgress = (e) => {
        setProgress(e)
    }
    //const call = async (query = {}, body = {}, header = {}) => {
    const call = async (params) => {
        try {
            setError(null);
            setSuccess(false)
            setIsLoading(true);
            let data = {}
            //if (!url.includes("synch"))
            data = await axiosApi.fetch(url, params?.query, params?.body, params?.header, method, onProgress, responseType)

            // //await api.fetch(url, query, body, method)            
            if (successCallback)
                successCallback(data)
            setSuccess(true)
            setData(data)
        } catch (e) {
            if (errorCallback)
                errorCallback(e)
            setSuccess(false)
            setError(e)
        } finally {
            setIsLoading(false);
        }
    };

    const swCallback = async (event) => {
        if (successCallback && event.data.meta === 'workbox-broadcast-update') {
            const { cacheName, updatedURL } = event.data.payload;
            if (updatedURL.toLowerCase().endsWith(url.toLowerCase())) {
                const cache = await caches.open(cacheName);
                const updatedResponse = await cache.match(updatedURL);
                const updatedText = await updatedResponse.json();
                successCallback(updatedText)
            }
        }
    }


    useEffectOnce(() => {
        if (successCallback && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', swCallback)
        }

        return () => {
            if (navigator.serviceWorker) {
                navigator.serviceWorker.removeEventListener('message', swCallback)
            }
        }
    }, [url])

    return { isLoading, data, error, success, progress, call }
}

//export default {}// api
