import styled from '@emotion/styled/macro';
import { Portal } from '@mui/material';
import Icons from './components/Icons';

export function BreadcrumbItem({ className, children, disabled, ...rest }) {

    return (
        <>
            <LayoutItem disabled={disabled ? 1 : 0} {...rest} className={className}>{children}</LayoutItem>
            <Separator />
        </>
    )
}

export default
    function Breadcrumb({ className, opaque, children, ...rest }) {
    return (
        <Layout {...rest} opaque={opaque} className={className}>{children}</Layout>
    )
}

const LayoutItem = styled('div')(props => props.theme.sx({
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    display: 'inline',
    opacity: 0.50,   
    flexShrink: 1,
    flexGrow: 0,
    '> *': {
        fontSize: '0.625rem',
        textDecoration: 'none',
        color: 'primary.main',
    },
    ...(!props.disabled && {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    }),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:nth-of-type(1), &:nth-of-type(2)': {
        flexShrink: 0
    },
    'br': {
        content: '""',
        '&:after': {
            content: '" - "'
        }
    },
    textOverflow: "ellipsis"
}))

const Separator = styled(Icons.Next)(props => props.theme.sx({
    width: '0.625rem',
    margin: '0 0.125rem',
    marginTop: '0px',
    padding: '0 0.055rem',
    '&:last-of-type': {
            display: 'none'
    },
}))

const Layout = styled('div')(props => props.theme.sx({
    position: 'fixed',
    overflow: 'hidden',
    zIndex: 0,
    top: '3.75rem',
    minWidth: '100%',
    height: '2.188rem',
    backgroundColor: 'primary.surfaceLight',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0.938rem',
    flexWrap: 'nowrap',
    ...(props.opaque && {
        backgroundColor: 'primary.surface',
    }),
    transition: 'background 0.4s',
}))
