import styled from '@emotion/styled/macro';
import { ButtonBase } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { useInView } from "react-cool-inview";
import Tag from './Tag';

/**
 * Tags vertical list component   
 * Use observer to easy load end of list
 */
export default function Tags({ scrollRef, onSelect, onSelectAudio, journeyKey, filter, keys, tags, tagType, focusedTagKey, inline, ...rest }, ref) {
    const layoutRef = useRef()
    const focusedTagKeyRef = useRef()
    const focusedTagIndex = focusedTagKey ? Object.keys(tags).indexOf(focusedTagKey) : -1
    const pageSize = 20
    const [last, setLast] = useState(focusedTagIndex + pageSize)

    useEffect(() => {
        setTimeout(() => {
            if (scrollRef && focusedTagKeyRef.current) {
                const scrollRefRect = scrollRef.current?.getBoundingClientRect()
                const focusedTagKeyRefRect = focusedTagKeyRef.current?.getBoundingClientRect()

                if (focusedTagKeyRefRect.top >= scrollRefRect.top && (focusedTagKeyRefRect.top + focusedTagKeyRefRect.height < scrollRefRect.top + scrollRefRect.height)) {
                } else {
                    scrollRef.current.scrollTop = focusedTagKeyRef.current?.offsetTop
                }
            }
        }, 100)
    }, [focusedTagKey, scrollRef, focusedTagKeyRef])

    const { observe } = useInView({
        rootMargin: "100% 0px",
        onEnter: ({ unobserve }) => {
            unobserve()
            setLast(last + pageSize)
        }
    })
    return (
        <>
            <Layout ref={layoutRef}>
                {keys?.slice(0, last).map((k, i) => {
                    return (
                        <TagLayout focused={k === focusedTagKey ? 1 : 0} key={i} ref={el => {
                            if (k === focusedTagKey)
                                focusedTagKeyRef.current = el
                            if (i === last - 1) {
                                observe(el)
                            }
                        }} indent={ tags[k].Type !== 'Room' ? 1 : 0 } inline={inline ? 1 : 0}>
                           
                            <Tag altTag tagType={tags[k].Type} tagKey={tags[k].Key} childType={tags[k].ChildType} childKey={tags[k].ChildKey} onSelectAudio={onSelectAudio} filter={filter} onSelect={onSelect} />
                        </TagLayout>
                    )
                }
                )}
            </Layout>
        </>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    maxHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    overflow: 'visible',        
}))

const TagLayout = styled(ButtonBase)(props => props.theme.sx({
    color: 'secondary.main',
    position: 'relative',
    paddingLeft: '0.75rem',
    ...(props.indent && {
        paddingLeft: '3.75rem'
    }),
    [props.theme.breakpoints.down('tablet')]: {
        '&:after': {
            zIndex: 10,
            content: '""',
            position: 'absolute',
            right: '0rem',
            left: '4.25rem',
            bottom: '0',
            borderBottom: 'solid 1px',
            borderColor: 'primary.contrastLight'
        },
        ...(props.inline && {
            paddingLeft: '5.25rem',
            '&:after': {
                content: '""',
                position: 'absolute',
                right: '2rem',
                left: '2rem',
                bottom: '0',
                borderBottom: 'solid 1px',
                borderColor: 'secondary.contrastLight'
            }
        })
    },
    [props.theme.breakpoints.up('tablet')]: {
        ...(props.inline && {
            paddingLeft: '1.75rem',
        }),
        '&:after': {
            content: '""',
            position: 'absolute',
            right: '0',
            left: '0',
            bottom: '0rem',
            borderBottom: 'solid 1px',
            borderColor: 'secondary.contrastLight'
        }
    },
    ...(props.focused && {
         '&:before': {
            borderLeft: 'solid 0.6rem',
            borderLeftColor: 'primary.contrastLight',
            content: '""',
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            width: '100%',
            // background: (theme) => 'linear-gradient(to right,  ' + theme.palette.primary.contrastLight + ', transparent 6rem, transparent calc(100% - 6rem), ' + theme.palette.primary.contrastLight + ' 100%)'
         }
    })
}))
