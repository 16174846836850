import { createRoot } from 'react-dom/client';

import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>,
)

// ReactDOM.render(
//   <React.StrictMode>
//     <App  />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

if (module.hot) {
  module.hot.accept() // already had this init code 

  module.hot.addStatusHandler(status => {
    // if (status === 'prepare') 
      //console.clear()
  })
}

// ignore in-browser next/js recoil warnings until its fixed.
const mutedConsole = (console) => ({
  ...console, 
  log: (...args) => {
    return  args && args.length > 0 && args[0].includes('Warning: Unimplemented')
    ?   null
    : console.log(...args)
  }
  ,
  warn: (...args) => {
    return  args && args.length > 0 && (args[0].includes('Duplicate atom key')  || args[0].includes('react-intl-universal key'))
    ?   null
    : console.warn(...args)
  }
  ,
  error: (...args) =>
  {
    return args && args.length > 0 && args[0].includes('Warning: %s is deprecated in StrictMode') // || args[0].includes('react-intl-universal')
    ? null
    : console.error(...args)
  },
})
global.console = mutedConsole(global.console)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a funcation
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
