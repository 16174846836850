import styled from '@emotion/styled/macro';
import BaseAltTag from '../BaseAltTag';
import BaseTag from '../BaseTag';

import { useArticles,  useIntl } from '../hooks';


export default function Article({ floating, journeyKey, filter, altTag, tagApi, articleKey, displayKey, onSelect, onSelectAudio, fromPath, ...rest }) {
    const { articles } = useArticles()
    const article = articles?.[articleKey]
    
    return altTag ? <Alt onSelect={onSelect} article={article} filter={filter} displayKey={displayKey} articleKey={articleKey} /> :
        <Main floating={floating} article={article} articleKey={articleKey} displayKey={displayKey} filter={filter} tagApi={tagApi} fromPath={fromPath} onSelect={onSelect} />
}


function Alt({ article, onSelect, articleKey, displayKey, filter }, ref) {
    const { locale, intl } = useIntl()
    
    return <BaseAltTag filter={filter} onSelect={onSelect} tagType='Display' tagKey={displayKey} childType='Article' childKey={articleKey} imageUri={article?.Images?.[0]?.["@1x"]} name={article?.Name?.[locale]??articleKey} description={article.Collection[locale]} />
}

function Main({ floating, journeyKey, filter, displayKey, articleKey, article, tagApi, fromPath, onSelect }) {
    const { locale, intl } = useIntl()

    let initialTabKey = null
    let imageUri = article?.Images?.[0]?.["@1x"]
    return (

        <BaseTag
            floating={floating}
            tagType={'Article'}
            tagKey={articleKey}
            journeyKey={journeyKey}
            filter={filter}
            initialTabKey={initialTabKey}
            tagApi={tagApi}
            title={article?.Name?.[locale]??articleKey}
            subTitle={article.Collection[locale]}
            fromPath={fromPath}
            imgUri={imageUri}
            images={article.Images}
            audioUri={article.AudioUri}
            sonnerieTime={article.SonnerieTime}
            locateTagKey={displayKey}
            fromPathTagKey={displayKey}            
        >
            <Attributes>
                {article.Title?.[locale]?.length > 0 && <Attribute dangerouslySetInnerHTML={{__html: article.Title?.[locale][0]}} title={1}></Attribute>}
                {article.Type?.[locale]?.length > 0 && <Attribute dangerouslySetInnerHTML={{__html: article.Type?.[locale][0]}} type={1}></Attribute>}
                {article.Family?.[locale]?.length > 0 && <Attribute>{article.Family[locale].reduce((acc, att) => { return acc + intl.get('global.join', { text: att }) }, "")}</Attribute>}
                <Attribute>{article.Main[locale]}</Attribute>
                {article.Footer?.[locale]?.length > 0 && <Attribute>{article.Footer[locale].reduce((acc, att) => { return acc + intl.get('global.join', { text: att }) }, "")}</Attribute>}
            </Attributes>
        </BaseTag>
    )
}


const Attributes = styled('div')(props => props.theme.sx({
    padding: '1rem 2rem 1rem 2rem',

}))

const Attribute = styled('div')(props => props.theme.sx({
    display: 'block',
    marginBottom: '0.45rem',
    ...(props.title && {
        color: 'secondary.main'
    }),
    ...(props.type && {
        fontStyle: 'italic',
        fontWeight: 'bold'
    }),
    "sup": {
        fontSize: '0.6rem'
    }
}))

