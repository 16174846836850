import styled from '@emotion/styled/macro';
import { Portal } from '@mui/material';
import { animated, config, useSpring, useTransition } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import { unstable_useBlocker as useBlocker, useLocation, useParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { IconButtons } from './components/components';
import Search from './filter/Search';
import { useHelmet, useIntl } from './hooks';
import Menu from './Menu';


const HeaderBackground = styled('div')(props => props.theme.sx({
    position: 'fixed',
    width: '100vw',
    top: '0',
    left: '0',
    height: '3.75rem',
    zIndex: 4,
    backgroundColor: 'primary.surfaceSemiLight',
    ...(props.opaque && {
        backgroundColor: 'primary.surface',
    }),
    transition: 'background 0.4s',
}))
export { HeaderBackground }

/**
 * Header component
 * TODO: ---
 */
export default
    function Header({ reduced, preloaded, onUpdateSearchActive, searchActive }) {
    const {locale} = useIntl()
    const {...rest} = useParams()
    const menuRef = useRef()
    const location = useLocation()
    const [menuOpen, toggleMenuOpen] = useToggle(false)
    const [menuLayoutStyle, menuLayoutAnimate] = useSpring(() => ({ translateX: '-100%', opacity: 0, config: config.default }), []);
    const { values: headerValues } = useHelmet('header')
    const isSearch = rest?.['*']?.toLowerCase()?.startsWith('visit/search')

    useEffect(() => {
        toggleMenuOpen(false)
    }, [location, toggleMenuOpen])

    useEffect(() => {
        menuLayoutAnimate({
            translateX: (menuOpen ? '0' : '-100%'),
            opacity: menuOpen ? 1 : 0
        })
    }, [menuLayoutAnimate, menuRef, menuOpen]);

    const topBackgroundTransition = useTransition(!reduced || !preloaded, {
        from: { translateY: '-100%' },
        enter: { translateY: '0' },
        leave: { translateY: '-100%' },
        initial: { translateY: '0' },
        config: { duration: 400 }// config.molasses,
    })

    const bottomBackgroundTransition = useTransition(!reduced || !preloaded, {
        from: { translateY: '100%' },
        enter: { translateY: '0' },
        leave: { translateY: '100%' },
        initial: { translateY: '0' },
        config: { duration: 400 }// config.molasses,
    })

    const headerTransition = useTransition(reduced, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        initial: { opacity: 0 },
        config: { duration: 400}
    })

    const headerBarTitleTransition = useTransition(headerValues?.[0] ?? '', {
        from: { opacity: 0, translateX: '0' },
        enter: { opacity: 1, translateX: '0' },
        leave: { opacity: 0, translateX: '-0' },
        config: { duration: 200 }
    })

    const handleToggleSearch = () => {
        onUpdateSearchActive(!searchActive)
    }

    useBlocker((e) => {
        if (menuOpen && e.historyAction === 'POP') {
            toggleMenuOpen(false)
            return true
        }
        return false
    })

    return (
        <>
            <Layout>
                {headerTransition((style, item) => item &&
                    <MenuIconLayout style={style} onClick={() => toggleMenuOpen()}>
                        <MenuIconButton open={menuOpen} />
                    </MenuIconLayout>
                )}
                {reduced && <>
                    {headerTransition((style, item) => item &&
                        <HeaderBar style={style} locale={locale}>
                            {headerBarTitleTransition((style, item) =>
                                <HeaderBarTitle style={style}>{item}</HeaderBarTitle>
                            )}
                            {(true || !isSearch) && <SearchIconButton onClick={handleToggleSearch} />}
                        </HeaderBar>
                    )}
                    <Portal><Search active={searchActive} onUpdateSearchActive={onUpdateSearchActive} /></Portal>
                    {menuOpen && <CloseMenuLayout onClick={(e) => toggleMenuOpen()} />}
                    <Portal>
                        <MenuLayout style={menuLayoutStyle}>
                            <Menu ref={menuRef} onClose={() => toggleMenuOpen(false)} />
                        </MenuLayout>
                    </Portal>
                </>
                }

                {bottomBackgroundTransition((style, item) => item &&
                    <BottomBackground style={style} />
                )}
                { bottomBackgroundTransition((style, item) => item &&
                    <HomeMenuLayout style={style} id="homeMenuLayout" />
                )}

                {topBackgroundTransition((style, item) => item &&
                    <TopBackground style={style}>
                        <TopBackgroundImage style={style} full={preloaded !== true ? "true" : undefined} />
                    </TopBackground>
                )}
            </Layout>
        </>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    height: '0px',
    overflow: 'visible'
}))

const TopBackground = styled(animated.div)(props => props.theme.sx({
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    zIndex: 11
}))

const TopBackgroundImage = styled('div')(props => props.theme.sx({
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    zIndex: 0,
    backgroundImage: 'url("' + process.env.PUBLIC_URL + '/WatchArt_arriere_plan_mobile.jpg")',
    backgroundSize: '100% auto',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'secondary.main',
    '&:after': {
        content: '""',
        position: 'absolute',
        top: '1rem',
        width: '100%',
        height: '39%',
        left: 0,
        right: 0,
        margin: '0 auto',
        maxWidth: '19rem',
        backgroundImage: 'url("' + process.env.PUBLIC_URL + '/logotype.svg")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto auto',
        backgroundPosition: 'center center',
        [props.theme.breakpoints.up('tablet')]: {
            top: 0,
            height: '100%',
            backgroundSize: '15.625rem auto',
            backgroundPosition: 'center calc(50vh - 20.5rem)',
        }
    },
    ...(props.full && {
        // height: '100vh !important',
        // backgroundSize: '170% auto',
        // transform: 'translateY(-30vh)',
    }),
    transition: 'all 1s',
    [props.theme.breakpoints.up('tablet')]: {
        backgroundImage: 'url("' + process.env.PUBLIC_URL + '/WatchArt_arriere_plan.jpg")',
    },
}))


const BottomBackground = styled(animated.div)(props => props.theme.sx({
    width: '100vw',
    height: '60vh',
    position: 'absolute',
    top: '40vh',
    zIndex: 10,
    background: (theme) => 'linear-gradient(to bottom, transparent 0, ' + theme.palette.secondary.main + ' 1px)'
}))

const HomeMenuLayout = styled(animated.div)(props => props.theme.sx({
    width: '100vw',
    height: '60vh',
    position: 'absolute',
    top: '40vh',
    zIndex: 13,
    [props.theme.breakpoints.up('tablet')]: {
        height: '58vh',
        top: '42vh',
    },
}))

const HeaderBar = styled(animated.header)(props => props.theme.sx({
    fontSize: '1rem',    
    backgroundColor: 'transparent',
    width: '100vw',
    height: '3.75rem',
    position: 'fixed',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex:8 ,
    boxShadow: (theme) => '0 3px 6px -1px ' + theme.palette.primary.main,
    
    ...(props.locale === "ja" && {
        [props.theme.breakpoints.down('tablet')]: {
            fontSize: '0.8rem',
        },    
    })
}))

const HeaderBarTitle = styled(animated.span)({
    position: 'absolute',
    textTransform: 'uppercase'
})

const CloseMenuLayout = styled('div')({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 2,
})

const MenuLayout = styled(animated.div)(props => props.theme.sx({
    position: 'fixed',
    top: '0',
    left: '0',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    zIndex: 50,
    pointerEvents: 'none'
}))

const MenuIconLayout = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    top: '0.2rem',
    left: '0.2rem',
    width: 'auto',
    padding: '0.1rem',
    zIndex: 10
}))

const MenuIconButton = styled(IconButtons.Menu)(props => props.theme.sx({

    '> svg': {
        width: '1.81rem',
        transition: 'fill 1s'
    },
    ...(props.open && {
        '> svg': {
            width: '1.81rem',
            fill: (theme) => theme.palette.primary.contrastText,
        }
    }),
}))

const SearchIconButton = styled(IconButtons.Search)(props => props.theme.sx({

    '> svg': {
        width: '1.375rem',
        fill: (theme) => theme.palette.primary.mainLight,

    },
    position: 'absolute',
    right: '1.375rem',
    top: '0.48rem'
}))
SearchIconButton.defaultProps = { accent: 1 }