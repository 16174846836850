import styled from '@emotion/styled/macro';
import { animated, useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';

const Hours = ({ className, ...props }) => {
    const [rotate, setRotate] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date()
            const hours = (now.getHours() % 12 || 12) + now.getMinutes() / 60 + now.getSeconds() / 3600
            const bias = 45
            setRotate((r) => bias + (hours / 12) * 360)

        }, 1000)

        return () => clearInterval(interval);
    }, [])

    const styles = useSpring({
        rotate: (rotate) + 'deg',
        config: { duration: 10 }
    })


    return (
        <HoursSvgElement style={styles} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 28.43373 15.208922">
            <g>
                <HoursPath d="m -99.878708,-96.351078 8.68,10.22 2.519997,1 -0.87,-2.22 z" />
                <HoursPath shape={1} d="m -88.678711,-85.011078 h -0.05 l -2.519997,-1 v 0 l -8.73,-10.28 a 0.13,0.13 0 0 1 0.18,-0.18 l 10.329997,9 v 0 l 0.86,2.22 a 0.14,0.14 0 0 1 0,0.14 z m -2.45,-1.23 2.23,0.88 -0.75,-1.92 -9.059997,-7.89 z" />
            </g>
        </HoursSvgElement>
    )
}

const Minutes = ({ className, ...props }) => {
    const [rotate, setRotate] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date()
            const minutes = now.getMinutes() + now.getSeconds() / 60
            const bias = -45.4
            setRotate((r) => bias + (minutes / 60) * 360)

        }, 1000)

        return () => clearInterval(interval);
    }, [])

    const styles = useSpring({
        rotate: rotate + 'deg',
        config: { duration: 10 }
    })


    return (
        <MinutesSvgElement style={styles} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 28.43373 15.208922">
            <g>
                <MinutesPath d="m -92.758708,-84.951078 1.32,-2.76 13.799997,-12.24 -12.24,13.8 z" />
                <MinutesPath shape={1} d="m -92.758708,-84.821078 a 0.18,0.18 0 0 1 -0.09,0 0.14,0.14 0 0 1 0,-0.14 l 1.32,-2.76 v 0 l 13.799997,-12.24 c 0.047,-0.05 0.125,-0.052 0.175,-0.005 0.05,0.047 0.052,0.125 0.005,0.175 l -12.24,13.8 h -0.05 l -2.879997,1.2 z m 1.42,-2.81 -1.17,2.44 2.549997,-1.06 10.83,-12.22 z" />
            </g>
        </MinutesSvgElement>
    )
}

const Seconds = ({ className, ...props }) => {
    const [rotate, setRotate] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date()
            const seconds = now.getSeconds()
            const bias = -58.5
            setRotate((r) => bias + (seconds / 60) * 360)

        }, 1000)

        return () => clearInterval(interval);
    }, [])

    const styles = useSpring({
        rotate: rotate + 'deg',
        config: { duration: 10 }
    })


    return (
        <SecondsSvgElement style={styles} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="-100 -100 28.43373 15.208922">
            <g>
                <SecondsPath shape={1} d="m -93.508708,-85.441078 a 0.14,0.14 0 0 1 -0.11,-0.06 l -0.12,-0.26 a 0.14,0.14 0 0 1 0,-0.09 0.15,0.15 0 0 1 0,-0.06 l 21.879997,-10.61 a 0.1,0.1 0 0 1 0.09,0 c 0,0 0.05,0 0.07,0.05 l 0.12,0.26 a 0.11,0.11 0 0 1 -0.05,0.15 l -21.879997,10.61 z m 0,-0.31 v 0 l 21.669997,-10.51 v 0 z" /> */}
                <SecondsPath d="m -90.408708,-86.541078 a.65.65 0 0 1-.473-1.106.65.65 0 0 1 1.113.456.65.65 0 0 1-.64.65zm0-1a.39.39 0 0 0-.4.39.4.4 0 0 0 .613.398.4.4 0 0 0 .177-.398.39.39 0 0 0-.39-.43z" />
                <circle style={{ fill: '#4d008c' }} cx="-30" cy="-30" r="13.52" />
            </g>
        </SecondsSvgElement>
    )
}


 export default
    function FloorClock({ className, viewScale, ...props }) {
    return (
        <Layout className={className}
            translate={{ tx: (-props.Width / 2 + props.Position?.[0]) * viewScale.x, ty: props.Position?.[1] * viewScale.y }}
            width={props.Width * viewScale.x}
        >
            <Hours />
            <Minutes />
            <Seconds />

        </Layout>
    )
}

const Layout = styled('div')(props => props.theme.sx({
    transform: 'translate(' + props.translate.tx + 'px, ' + props.translate.ty + 'px)', 
    backfaceVisibility: 'hidden',
    position: 'absolute',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    width: props.width + 'px',
    height: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const ClockSvgElement = styled(animated.svg)({
    position: 'absolute',
    transformOrigin: '32.91731% 84.662576687%',
})

const HoursSvgElement = styled(ClockSvgElement)({

})

const MinutesSvgElement = styled(ClockSvgElement)({

})

const SecondsSvgElement = styled(ClockSvgElement)({

})

const ClockPath = styled('path')(props => ({
    fill: '#4d008c',
    ...(props.shape && {
        fill: '#9a7a69'
    })
}))


const HoursPath = styled(ClockPath)(props => ({
    //    transform: 'rotate(20deg)'
}))

const MinutesPath = styled(ClockPath)(props => ({
}))


const SecondsPath = styled(ClockPath)(props => ({
}))
