import { useLocation, useNavigate } from 'react-router-dom';
import BaseAltTag from '../BaseAltTag';
import BaseTag from '../BaseTag';

import { useEffect } from 'react';
import { useDisplays, useMap, usePOIs, useTagsExtension } from '../hooks';
import Tags from '../Tags';

export default function POI({ journeyKey, onSelectAudio, filter, altTag, tagApi, POIKey, onSelect, fromPath, ...rest }) {
    const { POIs, isLoading } = usePOIs()
    const POI = POIs?.[POIKey]

    return altTag ? <Alt onSelect={onSelect} onSelectAudio={onSelectAudio} POI={POI} POIKey={POIKey} filter={filter} /> : 
        <Main journeyKey={journeyKey} filter={filter} POI={POI} POIKey={POIKey} tagApi={tagApi} fromPath={fromPath} />
}

function Alt({ POI, POIKey, onSelect, onSelectAudio }) {

    return <BaseAltTag onSelect={onSelect} onSelectAudio={onSelectAudio} imageUri={POI.ImageUri} 
        tagKey={POIKey} 
        name={POI.Name?.["en"]} description={POI.Description?.["en"]} />
}

function Main({ journeyKey, filter, POI, POIKey, tagApi, fromPath }) {
    const location = useLocation()
    const navigate = useNavigate()
    const currentTagKey = location.state?.tagKey

    useEffect(() => {
        if (!POI) {
            navigate('/')
        }
    }, [POI])
    return (
        <>
            {POI &&
                <BaseTag
                    tagType={'POI'}
                    tagKey={POIKey}
                    journeyKey={journeyKey}
                    filter={filter}
                    tagApi={tagApi}
                    name={POI.Name?.["en"]}
                    description={POI.Description?.["en"]}
                    fromPath={fromPath}
                    imgUri={POI.ImageUri}
                    videoUri={POI.VideoUri?.["en"]}
                    locateTagKey={POIKey} fromPathTagKey={POIKey}
                    audioUri={POI.AudioUri?.["en"]}                    
                />
            }
        </>

    )
}
