import styled from '@emotion/styled/macro';
import { animated, useTransition } from '@react-spring/web';
import { useRef } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import Floor from './Floor';
import { useMap, useMediaQuery, useNavigationType } from './hooks';

/**
 * Main component: contains outlet for router 
 * TODO: ---
 */
export default function Main(props) {
    const { map } = useMap()
    const location = useLocation()
    const navigationType = useNavigationType()
    const pathnames = location.pathname.split("/").filter(i => i)
    const { is: isMediaQuery } = useMediaQuery()
    const layoutRef = useRef()
    // useDisableIosSwipe(layoutRef)

    const transitionKey = pathnames?.length > 0 ? pathnames[0] : 'home'

    const fromBottomTransition = {
        from: { opacity: 0.3, translateY: '100%', translateX: '0' },
        enter: { opacity: 0.99, translateY: '0%', translateX: '0' },
        leave: { opacity: 0, translateY: '100%', translateX: '0' },
    }

    const fromTopTransition = {
        from: { opacity: 0, translateY: '-100%', translateX: '0' },
        enter: { opacity: 0.99, translateY: '0%', translateX: '0' },
        leave: { opacity: 0, translateY: '100%', translateX: '0' },
    }

    const fromRightTransition = {
        from: { opacity: 0.3, translateX: '100%', translateY: '0' },
        enter: { opacity: 0.99, translateX: '0%', translateY: '0' },
        leave: { opacity: 0, translateX: '-100%', translateY: '0' },
    }

    const fromLeftTransition = {
        from: { opacity: 0.3, translateX: '-100%', translateY: '0' },
        enter: { opacity: 0.99, translateX: '0%', translateY: '0' },
        leave: { opacity: 0, translateX: '100%', translateY: '0' }
    }

    const isMobile = isMediaQuery.only('mobile')

    const floorRef = useRef()
    const main = useOutlet({ floorRef: floorRef })

    const mainTransition = useTransition(main,
        {
            key: transitionKey,
            delay: 0,
            ...(isMobile || transitionKey === 'home' ? (navigationType === 'PUSH' ? fromBottomTransition : fromTopTransition)
                : (navigationType === 'PUSH' ? fromRightTransition : fromLeftTransition)),
            config: { duration: 200 }
        });

    const hideFloor = false

    const floor = Object.values(map?.Floors ?? {})?.[0]

    return (
        <Layout ref={layoutRef}>
            <FloorLayout hidden={hideFloor}>
                {floor &&
                    <Floor floor={floor} ref={floorRef} />
                }
            </FloorLayout>

            {mainTransition((styles, main) =>
                <MainLayout style={styles}>
                    {main}
                </MainLayout>
            )}
        </Layout>
    )
}

const Layout = styled(animated.div)(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'relative',
}))


const MainLayout = styled(animated.div)(props => props.theme.sx({
    position: 'absolute',
    overflow: 'hidden',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    zIndex: 3,
    opacity: 0.3,
    pointerEvents: 'none',
    '> *': {
        pointerEvents: 'initial',
    }
}))


const FloorLayout = styled('div')(props => props.theme.sx({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'block',
    ...(props.hidden && {
        opacity: 0.3
    }),
}))